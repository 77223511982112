import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './index.scss';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './use-auth';
import configureStore from "./store/configureStore";
import Axios from 'axios';
import * as Sentry from '@sentry/browser';
import settings from './settings';

let theme = createMuiTheme({
    palette: {
        primary: { main: '#e94b94' },
        secondary: { main: '#414146' },
        error: red,
    },
    typography: {
        htmlFontSize: 18,
    },
});

theme = responsiveFontSizes(theme);

const store = configureStore();

Axios.defaults.baseURL = settings.base_url;

const env = settings.base_url.includes("localhost") ? "localhost" : (settings.base_url.includes("test") ? "test" : "prod");

Sentry.init({dsn: "https://79fb97e38a39459080b38c1e0d012e2b@sentry.io/1814966",
    environment: env});

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <AuthProvider>
                    <App isDev={env === "localhost"}
                         isTest={env === 'test'}
                    />
                </AuthProvider>
            </MuiPickersUtilsProvider>
        </Provider>
    </ThemeProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
