import React, {useContext, useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {connect} from "react-redux";
import {getAllFarmGroups, setSelectedFarmGroup} from "../../store/FarmGroupsStore";
import {AuthContext} from "../../use-auth";

function FarmGroupsAutoComplete({isLoading, getAllFarmGroups, setSelectedFarmGroup, farmGroups, selectedFarmGroup, onFarmGroupSelect}) {
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        getAllFarmGroups(currentUser);
    }, [getAllFarmGroups, currentUser]);


    return (
        <div>
            <Autocomplete
                id="farm-group-select"
                style={{ width: 210 }}
                options={farmGroups}
                autoHighlight
                getOptionLabel={option => option.farm_group_name}
                renderOption={option => (
                    <React.Fragment>
                        {option.farm_group_name}
                    </React.Fragment>
                )}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Farm Group"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'disabled', // disable autocomplete and autofill
                        }}
                    />
                )}
                value={selectedFarmGroup}
                onChange={(event, newValue) => {
                    setSelectedFarmGroup(newValue);
                    onFarmGroupSelect(newValue);
                }}
                disabled={isLoading}
            />
        </div>
    );
}

export default connect (state => ({
    selectedFarmGroup: state.farmGroupsStore.selectedFarmGroup,
    farmGroups: state.farmGroupsStore.farmGroups,
    isLoading: state.farmGroupsStore.isLoading,
    isSuccessful: state.farmGroupsStore.isSuccessful,
}), {
    getAllFarmGroups,
    setSelectedFarmGroup,
}) (FarmGroupsAutoComplete);
