import React, {useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FarmsAutoComplete from "../../farms/FarmsAutoComplete";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 20,
            alignItems: 'center',
            padding: 10,
        },
        item: {
            flexGrow: 1,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        textField: {
          width: '30ch',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
        chip: {
            margin: 2,
        },
    }),
);

const rejectReasons = [
    'Partial Image',
    'Cloud',
    'Partial Cloud',
    'Shadow',
    'Haze',
    'Light Haze',
    'Test',
    'Blur',
    'Other',
];

const initialScene = {
    datetimeImage: '',
    sceneId: '',
    farm: null,
    rejectReasons: [],
};

export default function SceneAdd({onAdd}) {
    const classes = useStyles();

    const [scene, setScene] = useState(initialScene);

    const handleSceneIdChange = (value) => setScene({...scene, sceneId: value});
    const handleFarmChange = (value) => setScene({...scene, farm: value});
    const handleRejectReasonsChange = (event) => {
        setScene({...scene, rejectReasons: event.target.value});
    };

    const handleAdd = () => {
        onAdd(scene);
        setScene(initialScene);
    };

    return (
        <Paper elevation={3}>
            <form className={classes.root} autoComplete="off">
                <div className={classes.item}>
                    <TextField
                        className={classes.textField}
                        required
                        onChange={(e) => handleSceneIdChange(e.target.value)}
                        value={scene.sceneId}
                        id="scene-id"
                        label="Scene"
                        variant="outlined"
                        margin="dense"
                        inputProps={{style: {fontSize: 14}}}
                        InputLabelProps={{style: {fontSize: 14}}}/>
                </div>
                <div className={classes.item}>
                    <FarmsAutoComplete onSelect={handleFarmChange} />
                </div>
                <div className={classes.item}>
                    <TextField
                        className={classes.textField}
                        select
                        onChange={handleRejectReasonsChange}
                        id="reject-reasons"
                        label="Reject Reasons"
                        variant="outlined"
                        margin="dense"
                        inputProps={{style: {fontSize: 14}}}
                        InputLabelProps={{style: {fontSize: 14}}}
                        SelectProps={{
                            multiple: true,
                            value: scene.rejectReasons,
                            renderValue: (value) =>
                                <div className={classes.chips}>
                                    {(value).map((v) => (
                                        <Chip key={v} label={v} className={classes.chip} />
                                    ))}
                                </div>
                        }}
                    >
                        {rejectReasons.map((reason) => (
                            <MenuItem key={reason} value={reason} style={{ fontSize: 14 }}>
                                {reason}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className={classes.item}>
                    <Button variant="contained" color="secondary" onClick={handleAdd} style={{ width: 100 }} disabled={scene.sceneId.trim() === '' || !scene.farm}>
                        Add
                    </Button>
                </div>
            </form>
        </Paper>
    );
}
