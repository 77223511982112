import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import {DateTime} from "luxon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import {downloadMoteHapgrs, getMoteHapgrs} from "../../../store/DashboardStore";
import {AuthContext} from "../../../use-auth";
import NoDataMessage from '../../common/NoDataMessage';

const useStyles = makeStyles( theme => ({
    root: {
        display: 'table',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        margin: 20,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    table: {
        minWidth: 200,
    },
    manualDawn: {
        fontStyle: 'italic'
    }
}));

function Motes(props) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const { getMoteHapgrs, filters, motes, isLoading, isSuccessful } = props;
    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getMoteHapgrs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
        };
        if (filters && filters.farmId) {
            callApi();
        }
    }, [filters, getMoteHapgrs, currentUser]);

    async function handleDownload() {
        const token = await currentUser.getIdToken(true);
        downloadMoteHapgrs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
    }

    return  (
        <>
            {motes && motes.length > 0 &&
            <>
                <div>
                    <IconButton aria-label="download" className={classes.button} onClick={handleDownload}>
                        <DownloadIcon fontSize="inherit" />
                    </IconButton>
                    Download CSV
                </div>
                <div>
                    <GridList className={classes.gridList} cols={2.5}>
                        {motes.map(m => (
                            <Paper className={classes.root} key={m.date}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={13}>
                                                <Typography variant='h6' align='center'>
                                                    {DateTime.fromISO(m.date).setZone('utc').setZone(filters.timeZone).toLocaleString(DateTime.DATE_HUGE)}
                                                </Typography>
                                                <Typography variant='subtitle2' align='center'>
                                                    Average Growth (Farm) min/max: {m.average_growth_min}/{m.average_growth_max}
                                                </Typography>
                                                <Typography variant='subtitle2' align='center'>
                                                    Fitted Avg Growth (Farm): {m.fitted_average_growth}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Mote</TableCell>
                                            <TableCell align="right">PPH Datetime</TableCell>
                                            <TableCell align="right">Fitted Growth min/max</TableCell>
                                            <TableCell align="right">Filtered</TableCell>
                                            <TableCell align="right">Net min/max</TableCell>
                                            <TableCell align="right">Gross min/max</TableCell>
                                            <TableCell align="right">24hr Growth</TableCell>
                                            <TableCell align="right">48hr Growth</TableCell>
                                            <TableCell align="right">Pasture Event</TableCell>
                                            <TableCell align="right">Last Grazing Datetime</TableCell>
                                            <TableCell align="right">Current Mapping Start Datetime</TableCell>
                                            <TableCell align="right">Zone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {m.growths.map((g,index) => (
                                            <TableRow key={g.mote_id} className={ g.is_manual_dawn ? classes.manualDawn : '' }>
                                                <TableCell align="right">{index + 1}</TableCell>
                                                <TableCell align="right">{g.mote_name}</TableCell>
                                                <TableCell align="right">{g.date_motehapgr == null ? '-' : DateTime.fromISO(g.date_motehapgr).setZone(filters.timeZone).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                <TableCell align="right">{g.fitted_growth_max != null ? `${g.fitted_growth_min}/${g.fitted_growth_max}` : '-'}</TableCell>
                                                <TableCell align="right">{g.filtered_pph != null ? g.filtered_pph : '-'}</TableCell>
                                                <TableCell align="right">{g.net_max != null ? `${g.net_min}/${g.net_max}` : '-'}</TableCell>
                                                <TableCell align="right">{g.gross_max != null ? `${g.gross_min}/${g.gross_max}` : '-'}</TableCell>
                                                <TableCell align="right">{g.growth_24hr != null ? g.growth_24hr : '-'}</TableCell>
                                                <TableCell align="right">{g.growth_48hr != null ? g.growth_48hr : '-'}</TableCell>
                                                <TableCell align="right">{g.pasture_event != null ? g.pasture_event : '-'}</TableCell>
                                                <TableCell align="right">{g.last_grazing_date == null ? '-' : DateTime.fromISO(g.last_grazing_date).setZone(filters.timeZone).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                <TableCell align="right">{g.mapping_start_date == null  ? '-' : DateTime.fromISO(g.mapping_start_date).setZone(filters.timeZone).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                <TableCell align="right">{g.zone_name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        ))}
                    </GridList>
                </div>
            </>
            }
            {!isLoading && (!motes || motes.length === 0) && <NoDataMessage isSuccessful={isSuccessful} requiredField="Farm" />}
        </>
    )
}

export default connect (state => ({
    motes: state.dashboardStore.motes,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getMoteHapgrs,
}) (Motes);
