export const transformToNdviProcesses = (raw) => {
    return Object.entries(raw).map(([_, value]) => {
        return transformToNdviProcess(value);
    });
}

const fileNamesOnly = (status) => Object.entries(status).sort().map(([fileName, _]) => fileName.split('/').pop());

export const transformToNdviProcess = (raw) => {
    return {
        processId: raw.id,
        dateReported: raw.date_reported_str,
        scene: {
            sceneId: raw.scene_id,
            datetimeImage: raw.datetime_image_str,
            farm: null,
            rejectReasons: [],
        },
        orderId: raw.order_id,
        fileNames: raw.status ? fileNamesOnly(raw.status) : [],
        farmName: raw.farm_name,
    }
}
