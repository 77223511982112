import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';
const DOWNLOAD_SCENES_FAIL = 'DOWNLOAD_SCENES_FAIL';

const showLoader = createAction(SHOW_LOADER);
const hideLoader = createAction(HIDE_LOADER);

const downloadScenesFail = createAction(DOWNLOAD_SCENES_FAIL);

// actions
export const downloadScenes = (token, scenes) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/ndvi-process-tracking`,
            scenes,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(() => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            dispatch(downloadScenesFail(err.response.data))
            setTimeout(() =>  dispatch(downloadScenesFail("")), 3000);
        })
    };
};

export const batchProcesses = (token, processIds) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/ndvi-process-batch`,
            processIds,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(() => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


// reducer
export default handleActions({
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    DOWNLOAD_SCENES_FAIL: (state, action) => {
            return {
                ...state,
                errors: action.payload
            }
    }
    },[],
);
