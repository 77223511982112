import React from 'react';
import {useParams} from 'react-router-dom';

export default function ScanEui() {
    const {devEui} = useParams();

    return (
        <div>
            {devEui}
        </div>
    );
}
