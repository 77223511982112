import React from 'react';
// import MaterialTable from 'material-table';
// import {tableIcons} from "../common/TableIcons";
// import {makeStyles} from "@material-ui/core";
// import TableRow from "@material-ui/core/TableRow";
// import TableCell from "@material-ui/core/TableCell";
// import TableBody from "@material-ui/core/TableBody";
// import Table from "@material-ui/core/Table";

// const cellWidth = 150;

// const useStyles = makeStyles( {
//     cell: {
//         paddingLeft: 5,
//         paddingRight: 5,
//         paddingTop: 0,
//         paddingBottom: 0,
//         border: 'dotted 0.001rem',
//     },
// });

function FarmStatusTable() {
    // const classes = useStyles();
    // const buildTable = (keyValuePairs) => {
    //     return (<Table>
    //         <TableBody>
    //             {Object.keys(keyValuePairs).map((key) => (
    //                 <TableRow>
    //                     <TableCell className={classes.cell}>{key}</TableCell>
    //                     <TableCell className={classes.cell}>{keyValuePairs[key]}</TableCell>
    //                 </TableRow>
    //             ))}
    //         </TableBody>
    //     </Table>);
    // };
    //
    // const columns = [
    //     { title: 'Mote Name', field: 'mappings.mote.name' },
    //     { title: 'Firmware', cellStyle: { minWidth: cellWidth },render: rowData => buildTable({
    //             Head: rowData.firmwareRev.head,
    //             Body: rowData.firmwareRev.head,
    //             "Head BL": rowData.firmwareRev.headBL,
    //             "Body BL": rowData.firmwareRev.bodyBL
    //         })},
    //     { title: 'Hardware', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Head: rowData.hardwareRev.headHW,
    //             Body: rowData.hardwareRev.bodyHW,
    //             Pole: rowData.hardwareRev.pole,
    //         })},
    //     { title: 'Coordinates', render: rowData =>
    //             <div>
    //                 <div>{rowData.latitude}, {rowData.longitude}</div>
    //             </div>},
    //     { title: 'Zone', field: 'mappings.zone.name' },
    //     { title: 'State', cellStyle: { minWidth: cellWidth }, render: rowData =>
    //             <div>
    //                 <div>Latest: {rowData.state.latest}</div>
    //                 <div>{rowData.state.all["Enter failed"]} x Enter Failed</div>
    //                 <div>{rowData.state.all["Charge complete"]} x Charge Complete</div>
    //                 <div>{rowData.state.all["Wake"]} x Wake</div>
    //                 <div>{rowData.state.all["Off"]} x Off</div>
    //                 <div>{rowData.state.all["On"]} x On</div>
    //                 <div>{rowData.state.all["Exit failed"]} x Exit Failed</div>
    //                 <div>{rowData.state.all["Charge"]} x Charge</div>
    //                 <div>{rowData.state.all["Sleep"]} x Sleep</div>
    //             </div>},
    //     { title: 'Tilt', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //                 Latest: rowData.state.latest,
    //                 Max: rowData.state.max})},
    //     { title: 'No. of Events', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //                 Avg: rowData.events.total.average,
    //                 Max: rowData.events.total.max,
    //                 Min: rowData.events.total.min })},
    //     { title: 'Repeated Events', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: rowData.events.repeated.average,
    //             Max: rowData.events.repeated.max,
    //             Min: rowData.events.repeated.min })},
    //     { title: 'Missing Events', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: rowData.events.missing.average,
    //             Max: rowData.events.missing.max,
    //             Min: rowData.events.missing.min })},
    //     { title: 'Latency', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: rowData.latency.average,
    //             Max: rowData.latency.max,
    //             Min: rowData.latency.min })},
    //     { title: 'Battery', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: rowData.battery.average,
    //             Max: rowData.battery.max,
    //             Min: rowData.battery.min })},
    //     { title: 'Sunrise', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Median: rowData.sunrise.median,
    //             Earliest: rowData.sunrise.earliest,
    //             Latest: rowData.sunrise.latest })},
    //     { title: 'Solar Panel', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Latest: `${rowData.solarPanel.latest.irradiance} - ${rowData.solarPanel.latest.chargeCircut}`,
    //             Max: `${rowData.solarPanel.max.irradiance} - ${rowData.solarPanel.max.chargeCircut}` })},
    //     { title: 'Energy Produced', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: `${rowData.energy.produced.average.produced} - ${rowData.energy.produced.average.sunshine}`,
    //             Max: `${rowData.energy.produced.max.produced} - ${rowData.energy.produced.max.sunshine}` })},
    //     { title: 'Energy Consumed', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: rowData.energy.consumed.average,
    //             Max: rowData.energy.consumed.max })},
    //     { title: 'Soil Moisture', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: `${rowData.soilMoisture.average.top}`/`${rowData.soilMoisture.average.bottom}`,
    //                 Max: `${rowData.soilMoisture.max.top}`/`${rowData.soilMoisture.max.bottom}`,
    //                 Min: `${rowData.soilMoisture.min.top}`/`${rowData.soilMoisture.min.bottom}` })},
    //     { title: 'Soil Temp', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Avg: `${rowData.soilTemperature.average.top}`/`${rowData.soilTemperature.average.bottom}`,
    //             Max: `${rowData.soilTemperature.max.top}`/`${rowData.soilTemperature.max.bottom}`,
    //             Min: `${rowData.soilTemperature.min.top}`/`${rowData.soilTemperature.min.bottom}` })},
    //     { title: 'Missing Heights', cellStyle: { minWidth: cellWidth }, render: rowData =>
    //             <div>
    //                 {rowData.events.sensorsMissingHeight.map(s => <div>Sensor {s}</div>)}
    //             </div>},
    //     { title: 'Resets', cellStyle: { minWidth: cellWidth }, render: rowData => buildTable({
    //             Head: rowData.resets.head,
    //             Body: rowData.resets.body })},
    //     { title: 'Diagnostic Codes', cellStyle: { minWidth: cellWidth }, render: rowData => <div>
    //             <div>Head</div>
    //             {buildTable(rowData.diagnostics.head)}
    //             <br/>
    //             <div>Body</div>
    //             {buildTable(rowData.diagnostics.body)}
    //         </div> },
    //     { title: 'Height Alerts', cellStyle: { minWidth: cellWidth }, render: rowData =>
    //             <div>
    //                 {rowData.sensorHeightAlerts[1] && <div>Sensor 1 {buildTable(rowData.sensorHeightAlerts[1])}</div>}
    //                 {rowData.sensorHeightAlerts[2] && <div>Sensor 2 {buildTable(rowData.sensorHeightAlerts[2])}</div>}
    //                 {rowData.sensorHeightAlerts[3] && <div>Sensor 3 {buildTable(rowData.sensorHeightAlerts[3])}</div>}
    //                 {rowData.sensorHeightAlerts[4] && <div>Sensor 4 {buildTable(rowData.sensorHeightAlerts[4])}</div>}
    //                 {rowData.sensorHeightAlerts[5] && <div>Sensor 5 {buildTable(rowData.sensorHeightAlerts[5])}</div>}
    //                 {rowData.sensorHeightAlerts[6] && <div>Sensor 6 {buildTable(rowData.sensorHeightAlerts[6])}</div>}
    //             </div>},
    //     { title: 'Alerts', cellStyle: { minWidth: cellWidth }, render: rowData =>
    //             <div>
    //                 {buildTable(rowData.alerts)}
    //             </div>},
    // ];

    return ( <></> );
}

export default FarmStatusTable;
