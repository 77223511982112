import React, {useState} from 'react';
import SceneAdd from "../components/ndvi/download/SceneAdd";
import ScenesToDownload from "../components/ndvi/download/ScenesToDownload";
import ScenesToBatch from "../components/ndvi/batch/ScenesToBatch";
import Batches from "../components/ndvi/batch/Batches";
import Box from "@material-ui/core/Box";

export default function NdviTracking() {
    const [scenes, setScenes] = useState([]);

    const handleAdd = (scene) => {
        setScenes([...scenes, scene]);
    };

    return (
        <Box padding={2}>
            <SceneAdd onAdd={handleAdd} />
            <ScenesToDownload scenes={scenes}/>
            <ScenesToBatch/>
            <Batches/>
        </Box>
    );
}
