import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import FileDownload from "js-file-download";

const EVENTS_GET = 'EVENTS_GET';
const EVENTS_GET_START = 'EVENTS_GET_START';

const EVENTS_DOWNLOAD_START = 'EVENTS_DOWNLOAD_START';
const EVENTS_DOWNLOAD = 'EVENTS_DOWNLOAD';

const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

const loadEvents = createAction(EVENTS_GET);
const startLoadingEvents = createAction(EVENTS_GET_START);

const startDownloadingEvents = createAction(EVENTS_DOWNLOAD_START);
const saveDownloadedEvents = createAction(EVENTS_DOWNLOAD);

const showLoader = createAction(SHOW_LOADER);
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getEvents = (token, moteId, from, to, timeZone) => {
    const dateFrom = from.setZone(timeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(timeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingEvents ());
        dispatch (showLoader ());
        axios.get(`/events/mote/${moteId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadEvents(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const downloadEvents = (token, moteId, moteName, from, to, timeZone) => {
    const dateFrom = from.setZone(timeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(timeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startDownloadingEvents ());
        dispatch (showLoader ());
        axios.get(`download/events/mote/${moteId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            FileDownload(response.data, `events-${moteName}-${dateFrom}-${dateTo}.csv`);
            dispatch(saveDownloadedEvents());
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        EVENTS_GET_START: (state, action) => {
            return {
                ...state,
                events: [],
                isSuccessful: false,
            };
        },
        EVENTS_GET: (state, action) => {
            return {
                ...state,
                events: action.payload,
            };
        },
        EVENTS_DOWNLOAD_START: (state, action) => {
            return {
                ...state,
                isDownloading: true,
            };
        },
        EVENTS_DOWNLOAD: (state, action) => {
            return {
                ...state,
                isDownloading: false,
                isDownloadSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
