import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fab from "@material-ui/core/Fab";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormControl from "@material-ui/core/FormControl";
import FarmGroupEditDialog from "./FarmGroupEditDialog";
import FarmGroupAddDialog from "./FarmGroupAddDialog";

function FarmGroupActions({selectedFarmGroup, onEditSave}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <FormControl>
                <Fab color="default" size="small" aria-label="more" onClick={handleMenuClick}>
                    <MoreVertIcon fontSize="small"/>
                </Fab>
            </FormControl>
            <Menu
                id="farm-group-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {["Edit", "Add New"].map((option, index) => (
                    <MenuItem
                        key={option}
                        onClick={event => handleMenuItemClick(event, index)}
                        disabled={index === 0 && (!selectedFarmGroup || !selectedFarmGroup.farm_group_name)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            {selectedFarmGroup &&
                <FarmGroupEditDialog
                    farmGroup={selectedFarmGroup}
                    open={selectedIndex === 0}
                    onClose={() => setSelectedIndex(-1)}
                    onEditSave={onEditSave}
                />
            }
            <FarmGroupAddDialog
                open={selectedIndex === 1}
                onClose={() => setSelectedIndex(-1)}
            />
        </>
    );

}

export default FarmGroupActions;
