import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import {DateTime} from "luxon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DownloadIcon from "@material-ui/icons/SaveAlt";
import IconButton from "@material-ui/core/IconButton";
import {getPaddockPphs, downloadPaddockPphs, moveToOutOfRotation} from "../../../store/DashboardStore";
import FeedWedge from '../FeedWedge';
import {AuthContext} from "../../../use-auth";
import PaddockActions from '../paddocks/PaddockActions';
import {db} from '../../../base';
import NoDataMessage from "../../common/NoDataMessage";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'table',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        margin: 20,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    table: {
        minWidth: 200,
    },
    button: {
        margin: theme.spacing(1),
    },
    inactiveRow: {
        backgroundColor: '#eeeeee',
    },
    checkButtonCell: {
        paddingRight: 0,
    },
}));

const colors = [
    {heading: '#4FCF95', aggregate: '#6FD8A8', rows: '#BFEDD9'},
    {heading: '#64BAE3', aggregate: '#86CAE9', rows: '#BAE1F2'},
    {heading: '#F7B578', aggregate: '#F9CA9F', rows: '#FCDFC5'},
    {heading: '#CACF85', aggregate: '#D9DDA7', rows: '#ECEED3'},
    {heading: '#BA9C8C', aggregate: '#C9B2A6', rows: '#E0D3CC'},
];

// const getRandomColor = () => colors[Math.floor(Math.random() * 5)];

function Paddocks({ getPaddockPphs, filters, paddockPphs, isLoading, isSuccessful }) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const [showProcessing, setShowProcessing] = React.useState(false);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getPaddockPphs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone, filters.targetPostGrazingResidual);
            setShowProcessing(false);
        };
        if (filters && filters.farmId) {
            callApi();
            db.ref(`/processes/status/pph/${filters.farmId}/`).on('value', (snapshot) => {
                const status = snapshot.val();
                if(status && status.date_completed) {
                    callApi();
                } else {
                    setShowProcessing(true);
                }
            });
        }
    }, [filters, getPaddockPphs, currentUser]);

    const getPaddockCovers = (zoneGroup) => {
        const increment = (filters.targetPreGrazingResidual - filters.targetPostGrazingResidual) / (zoneGroup.growths.length - 1);
        const getTargetGrazing = (index) => filters.targetPreGrazingResidual - (increment * index);
        const cuttingTarget = parseInt(filters.targetPreCutting, 10);
        return (zoneGroup.growths)
            .map((g, index) => ({zone: g.zone_name, cover: g.cover, target: zoneGroup.is_cutting_zone_group ? cuttingTarget : getTargetGrazing(index), area: g.effective_area}));
    };

    async function handleDownload() {
        const token = await currentUser.getIdToken(true);
        downloadPaddockPphs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone, filters.targetPostGrazingResidual, filters.timeZone);
    }

    async function handleMove() {
        const token = await currentUser.getIdToken(true);
        getPaddockPphs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone, filters.targetPostGrazingResidual);
        setShowProcessing(false);
    }

    function getColor(index) { return colors && index <= colors.length ? colors[index] : colors[0]; }

    const buildTables = () => (
        <div>
            <GridList className={classes.gridList} cols={2.5}>
                {paddockPphs.map(p => (
                    <Paper className={classes.root} key={p.date}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={9}>
                                        <Typography variant='h6' align='center'>
                                            {DateTime.fromISO(p.date).setZone('utc').setZone(filters.timeZone).toLocaleString(DateTime.DATE_HUGE)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" />
                                    <TableCell align="right">Cover</TableCell>
                                    <TableCell align="right">Available Cover</TableCell>
                                    <TableCell align="right">Available Feed</TableCell>
                                    <TableCell align="right">Daily Growth Rate</TableCell>
                                    <TableCell align="right">3 Days Growth Rate</TableCell>
                                    <TableCell align="right">7 Days Growth Rate</TableCell>
                                    <TableCell align="right">Effective Area</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }} />
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM/ha</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM/ha</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM/ha/day</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM/ha/day</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">kg DM/ha/day</Typography></TableCell>
                                    <TableCell align="right" style={{ lineHeight: 0, paddingBottom: 0, paddingTop: 0 }}><Typography variant="caption">ha</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Farm Average</TableCell>
                                    <TableCell align="right">{p.aggregate.average_cover}</TableCell>
                                    <TableCell align="right">{p.aggregate.average_available_cover}</TableCell>
                                    <TableCell align="right">{`Total: ${p.aggregate.total_available_feed}`}</TableCell>
                                    <TableCell align="right">{p.aggregate.average_daily_growth_rate}</TableCell>
                                    <TableCell align="right">{p.aggregate.average_growth_3_days}</TableCell>
                                    <TableCell align="right">{p.aggregate.average_growth_7_days}</TableCell>
                                    <TableCell align="right">{`Total: ${p.aggregate.total_effective_area}`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {p.zone_groups.map((zg, index) => (
                                    <>
                                        <TableRow style={{ backgroundColor: getColor(index).heading }}>
                                            <TableCell align="center" colSpan={9}><Typography variant={"body2"} style={{ fontWeight: "bold" }}>Paddock Group: {zg.zone_group}</Typography></TableCell>
                                        </TableRow>
                                        {zg.is_active &&
                                        <>
                                            <TableRow style={{ backgroundColor: getColor(index).aggregate }}>
                                                <TableCell align="right">Group Average</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{zg.aggregate.average_cover}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{zg.aggregate.average_available_cover}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{`Total: ${zg.aggregate.total_available_feed}`}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{zg.aggregate.average_daily_growth_rate}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{zg.aggregate.average_growth_3_days}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{zg.aggregate.average_growth_7_days}</TableCell>
                                                <TableCell align="right" style={{ fontWeight: "bold" }}>{`Total: ${zg.aggregate.total_effective_area}`}</TableCell>
                                            </TableRow>
                                            {zg.growths.map(g => (
                                                <TableRow key={g.zone_name} style={{ backgroundColor: getColor(index).rows }}>
                                                    <TableCell align="right">{g.paddock_name}</TableCell>
                                                    <TableCell align="right">{g.cover}</TableCell>
                                                    <TableCell align="right">{g.available_cover}</TableCell>
                                                    <TableCell align="right">{g.available_feed}</TableCell>
                                                    <TableCell align="right">{g.daily_growth_rate}</TableCell>
                                                    <TableCell align="right">{g.growth_3_days}</TableCell>
                                                    <TableCell align="right">{g.growth_7_days}</TableCell>
                                                    <TableCell align="right">{g.effective_area}</TableCell>
                                                </TableRow>
                                            ))}
                                        </>}
                                        {!zg.is_active && zg.growths.map(g => (
                                            <TableRow key={g.zone_name}>
                                                <TableCell align="right">{g.paddock_name}</TableCell>
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                                <TableCell align="right" />
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                ))}
            </GridList>
        </div>
    );

    const buildGraphs = () => ( <>
        {paddockPphs && paddockPphs[0].zone_groups.map(zg => <>
            {zg.is_active && <FeedWedge zoneGroup={zg.zone_group} data={getPaddockCovers(zg)} totalArea={zg.aggregate.total_effective_area}/>} </>)}
    </>);

    return  (
        <>
            {!isLoading && showProcessing &&
                <>
                    Please wait while we update your Pasture data...
                </>
            }
            {!showProcessing && paddockPphs && paddockPphs.length > 0 &&
                <>
                    <div>
                        <IconButton aria-label="download" className={classes.button} onClick={handleDownload}>
                            <DownloadIcon fontSize="inherit" />
                        </IconButton>
                        Download CSV
                    </div>
                    {filters && filters.farmLocalTimeZone && filters.farmLocalTimeZone.toLowerCase() === 'europe/amsterdam' ?
                    <>
                        {buildGraphs()}
                        {buildTables()}
                    </> :
                    <>
                        {buildTables()}
                        {buildGraphs()}
                    </>}
                </>
            }
            {!isLoading && (!paddockPphs || paddockPphs.length === 0) && <NoDataMessage isSuccessful={isSuccessful} requiredField="Farm" />}
            {filters && filters.farmId && <PaddockActions farmGroupId={filters} farmId={filters.farmId} farmName={filters.farmName} farmTimeZone={filters.farmLocalTimeZone} onMove={handleMove}/>}
        </>
    )
}

export default connect (state => ({
    paddockPphs: state.dashboardStore.paddockPphs,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getPaddockPphs,
    moveToOutOfRotation,
}) (Paddocks);
