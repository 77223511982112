import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import NavBar from '../components/nav/NavBar';
import NavDrawer from '../components/nav/NavDrawer';
import PrivateRoute from '../components/PrivateRoute';
import Dashboard from '../containers/Dashboard';
import Activity from '../containers/Activity';
import Users from '../containers/Users';
import Events from '../containers/Events';
import Motes from '../containers/Motes';
import SignInScreen from '../components/auth/SignInScreen';
import {AuthContext} from "../use-auth";
import FarmStatus from "./FarmStatus";
import NdviTracking from "./NdviTracking";
import ScanEui from './ScanEui';

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
    main: {
        marginTop: 60,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(7) + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 56,
        }
    },
    mainShift: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    env: {
        margin: '0 auto',
        padding: 5,
        position: 'fixed',
        left: '50%',
        transform: 'translate(-50%,0)',
        zIndex: 9999,
        textAlign: 'center',
        width: 80,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    envDev: {
        backgroundColor: '#3773E8',
        color: '#FFFFFF',
    },
    envTest: {
        backgroundColor: '#F9CA29',
    },
}));

function App({isDev, isTest}) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    const { currentUser } = useContext(AuthContext);
    function toggleDrawerOpen() {
        setDrawerOpen(!drawerOpen)
    }
    function toggleMobileDrawerOpen() {
        setMobileDrawerOpen(!mobileDrawerOpen)
    }
    return (
        <div>
            {(isDev || isTest) && <div>
                {isDev && <div className={clsx(classes.env,classes.envDev)}>DEV</div>}
                {isTest && <div className={clsx(classes.env,classes.envTest)}>TEST</div>}
            </div>
            }
            <BrowserRouter>
                {currentUser &&
                    <header className={classes.envHeight}>
                        <NavBar
                            drawerOpen={drawerOpen}
                            toggleDrawerOpen={toggleDrawerOpen}
                            toggleMobileDrawerOpen={toggleMobileDrawerOpen}
                        />
                        <NavDrawer
                            drawerOpen={drawerOpen}
                            toggleDrawerOpen={toggleDrawerOpen}
                            mobileDrawerOpen={mobileDrawerOpen}
                            toggleMobileDrawerOpen={toggleMobileDrawerOpen}
                        />
                    </header>
                }
                <main className={clsx({[classes.main]: currentUser}, {
                        [classes.mainShift]: drawerOpen,
                    })}
                >
                    <Switch>
                        <Route path="/" exact component={SignInScreen} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/activity" component={Activity} />
                        <PrivateRoute path="/users" component={Users} />
                        <PrivateRoute path="/events" component={Events} />
                        <PrivateRoute path="/motes" component={Motes} />
                        <PrivateRoute path="/farm-status" component={FarmStatus} />
                        <PrivateRoute path="/ndvi-tracking" component={NdviTracking} />
                        <Route path="/scan/eui/:devEui" component={ScanEui} />
                    </Switch>
                </main>
            </BrowserRouter>
        </div>
    );
}

export default App;
