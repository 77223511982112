import React, {useContext, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {connect} from "react-redux";
import {getRaw} from "../../../store/DashboardStore";
import {AuthContext} from "../../../use-auth";
import NoDataMessage from "../../common/NoDataMessage";
import {formatStrToDateMed, formatStrToFullTime, times} from '../../common/Util';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        cursor: 'pointer',
    },
    container: {
        maxHeight: 'calc(100vh - 280px)',
        maxWidth: 'fit-content',
    },
    cell: {
        padding: 4,
        border: 'solid lightgray 0.001rem',
    },
    rightCell: {
        textAlign: 'right',
    },
    centerCell: {
        textAlign: 'center',
    },
    headerCell: {
        height: 76,
    },
    lastCell: {
      borderRight: 'solid darkgray 0.2rem',
    },
    lastRow: {
        borderBottom: 'solid darkgray 0.2rem',
        padding: 0,
    },
    missingValueCell: {
        backgroundColor: '#FCE9EF',
    },
    coloredRow: {
        backgroundColor: '#F2F2F2',
    },
    focusedRow: {
        backgroundColor: '#FBEB74 !important',
    },
}));

function Raw({ getRaw, raw, filters }) {
    const { currentUser } = useContext(AuthContext);
    const [selectedRow, setSelectedRow] = useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const totalSensors = 6;
    const totalReadingsPerRound = 5;

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getRaw(token, filters.moteId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
        };
        if (filters && filters.dateFrom) {
            callApi();
        }
    }, [filters, getRaw, currentUser]);

    const classes = useStyles();

    const renderReadingValues = (sensor, showRound) =>
        <>
            {!sensor.time && <>
                {showRound && <TableCell className={clsx(classes.cell, classes.centerCell)}>{sensor.round}</TableCell>}
                {times(4, n => <TableCell className={clsx(classes.cell, n === 3 && classes.lastCell, classes.missingValueCell)}/>)}
            </>}
            {sensor.time && <>
                {showRound && <TableCell className={clsx(classes.cell, classes.centerCell)}>{sensor.round}</TableCell>}
                <TableCell
                    className={clsx(classes.cell, classes.rightCell)}>{formatStrToFullTime(sensor.time, filters.timeZone)}</TableCell>
                <TableCell className={clsx(classes.cell, classes.rightCell)}>{sensor.height}</TableCell>
                <TableCell className={clsx(classes.cell, classes.rightCell)}>{sensor.spread}</TableCell>
                {/*<TableCell className={clsx(classes.cell, classes.rightCell)}>{sensor.sigma}</TableCell>*/}
                <TableCell className={clsx(classes.cell, classes.rightCell, classes.lastCell)}>{sensor.time_diff}</TableCell>
            </>}
        </>;

    const roundReadings = (sensor, round) => {
        let filteredSensor = sensor.filter(s => s.round === round);
        const missingRounds = filteredSensor ? filteredSensor.length : 0;
        const missingRoundValues = [];
        times(totalReadingsPerRound - missingRounds, () => {
            missingRoundValues.push({round: round});
        });
        return [...missingRoundValues, ...filteredSensor];
    };

    const renderSensorValuesForADay = (sensors) => {
        const date = formatStrToDateMed(sensors.dawn_event_date, filters.timeZone);
        const totalRoundsPerSensor = ([sensors.sensor1, sensors.sensor2, sensors.sensor3, sensors.sensor4, sensors.sensor5, sensors.sensor6]
            .sort((a,b) => a.length > b.length ? 1 : 0))[0][0].round;
        let round = totalRoundsPerSensor;
        let sensor1, sensor2, sensor3, sensor4, sensor5, sensor6;

        return <>
            {times(totalRoundsPerSensor, r => {
            if (round !== 0) {
                sensor1 = roundReadings(sensors.sensor1, round);
                sensor2 = roundReadings(sensors.sensor2, round);
                sensor3 = roundReadings(sensors.sensor3, round);
                sensor4 = roundReadings(sensors.sensor4, round);
                sensor5 = roundReadings(sensors.sensor5, round);
                sensor6 = roundReadings(sensors.sensor6, round);
            }
            round = round - 1;

            return times(totalReadingsPerRound, rd =>
            <TableRow onClick={() => setSelectedRow({round: r, reading: rd, date: date})}
                        selected={r === selectedRow.round && rd === selectedRow.reading && date === selectedRow.date}
                        className={r % 2 === 0 && classes.coloredRow}
                        classes={{selected: classes.focusedRow}}>
                {r === 0 && rd === 0 && <TableCell className={clsx(classes.cell, classes.centerCell)} rowSpan={totalRoundsPerSensor * totalReadingsPerRound}>{date}</TableCell>}
                {renderReadingValues(sensor1[rd], true)}
                {renderReadingValues(sensor2[rd], false)}
                {renderReadingValues(sensor3[rd], false)}
                {renderReadingValues(sensor4[rd], false)}
                {renderReadingValues(sensor5[rd], false)}
                {renderReadingValues(sensor6[rd], false)}
            </TableRow>)
        })}
        <TableRow><TableCell colSpan={(totalSensors * 4) + 2} className={classes.lastRow}/></TableRow>
        </>;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!raw) {
        return <NoDataMessage isSuccessful={false} requiredField="Mote" />;
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small" align={"center"} aria-label="sticky round table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}/>
                            {times(totalSensors,n => <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} colSpan={4}>{`Sensor ${n+1}`}</TableCell>)}
                        </TableRow>
                        <TableRow>
                            <TableCell className={clsx(classes.headerCell, classes.cell)}>Dawn Event Date</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell,)}><Typography style={{ transform: 'rotate(270deg)'}}>Round</Typography></TableCell>
                            {times(totalSensors, _ =>
                            <>
                                <TableCell className={clsx(classes.headerCell, classes.cell)}><Typography style={{ transform: 'rotate(270deg)'}}>Time</Typography></TableCell>
                                <TableCell className={clsx(classes.headerCell, classes.cell)}><Typography style={{ transform: 'rotate(270deg)'}}>Height</Typography></TableCell>
                                <TableCell className={clsx(classes.headerCell, classes.cell)}><Typography style={{ transform: 'rotate(270deg)'}}>Spread</Typography></TableCell>
                                {/*<TableCell className={clsx(classes.headerCell, classes.cell)}><Typography style={{ transform: 'rotate(270deg)'}}>Sigma</Typography></TableCell>*/}
                                <TableCell className={clsx(classes.headerCell, classes.cell, classes.lastCell)}><Typography style={{ transform: 'rotate(270deg)'}}>Time Diff</Typography></TableCell>
                            </> )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {raw.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(r => <> {renderSensorValuesForADay(r)} </>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={raw.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default connect (state => ({
    raw: state.dashboardStore.raw,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getRaw,
}) (Raw);
