import React, {useContext, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import SingleSelectAutoComplete from '../common/SingleSelectAutoComplete';
import {connect} from "react-redux";
import {getMotes} from "../../store/FilterStore";
import {AuthContext} from "../../use-auth";

const useStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 128px)',
        padding: 20,
    },
    formControl: {
        minWidth: 180,
    },
    buttonWrap: {
        minWidth: 270,
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: 20,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    datePicker: {
        minWidth: 260,
    },
}));

function EventsFilters(props) {
    const { currentUser, farmGroupId } = useContext(AuthContext);

    const { getMotes } = props;
    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getMotes(token, farmGroupId);
        };
        callApi();
    }, [getMotes, farmGroupId, currentUser]);

    const classes = useStyles();
    const theme = useTheme();
    const { onSearchClick } = props;

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedFromDate, handleFromDateChange] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')).startOf('day'));
    const [selectedToDate, handleToDateChange] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')).endOf('day'));

    const browserTimeZone = DateTime.local().zoneName;

    const [values, setValues] = React.useState({
        mote: {},
        timeZone: {
            value: browserTimeZone,
            label: `Browser Local (${browserTimeZone})`
        },
    });

    const defaultTimeZones = [
        {value: 'utc', label: `UTC`},
        {value: browserTimeZone, label: `Browser Local (${browserTimeZone})`},
    ];
    const [timeZones, setTimeZones] = React.useState(defaultTimeZones);

    function handleChange(name, value) {
        setValues(oldValues => ({
            ...oldValues,
            timeZone: {
                value: browserTimeZone,
                label: `Browser Local (${browserTimeZone})`
            },
        }));
        setValues(oldValues => ({
            ...oldValues,
            [name]: value,
        }));
        if (name !== 'timeZone') {
            const tzs = defaultTimeZones;
            tzs.push({
                value: value.timeZone,
                label: `Farm Local (${value.timeZone})`,
            });
            setTimeZones(tzs);
        }
    }

    function handleSearchClick() {
        onSearchClick({
            dateFrom: selectedFromDate,
            dateTo: selectedToDate,
            timeZone: values.timeZone.value,
            timeZoneName: values.timeZone.label,
            moteId: values.mote.value,
            moteName: values.mote.label,
        })
    }

    const dateFiled = (label, value, minValue, onChange) => (
        <KeyboardDateTimePicker
            autoOk
            ampm={false}
            variant={isDesktop ? "inline" : "dialog"}
            inputVariant="outlined"
            label={label}
            format="dd/MM/yyyy HH:mm"
            value={value}
            minDate={minValue}
            disableFuture
            InputAdornmentProps={{ position: "start" }}
            onChange={date => onChange(date)}
            className={classes.datePicker}
        />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    {dateFiled('From', selectedFromDate, '01/01/2018', handleFromDateChange)}
                </Grid>
                <Grid item>
                    {dateFiled('To', selectedToDate, selectedFromDate, handleToDateChange)}
                </Grid>
                {props.motes &&
                    <Grid item>
                        <SingleSelectAutoComplete
                            name="mote"
                            title="Mote"
                            suggestions={props.motes.map(d => ({
                                value: d.moteId,
                                label: `${d.moteName} - ${d.devEui}`,
                                timeZone: d.timeZone
                            }))}
                            value={{value: values.mote.value, label: values.mote.label, timeZone: values.mote.timeZone}}
                            handleChange={(value) => handleChange('mote', value)}
                        />
                    </Grid>
                }
                <Grid item>
                    <SingleSelectAutoComplete
                        name="time-zone"
                        title="Time Zone"
                        suggestions={timeZones.map(d => ({
                            value: d.value,
                            label: d.label,
                        }))}
                        value={{value: values.timeZone.value, label: values.timeZone.label}}
                        handleChange={(value) => handleChange('timeZone', value)}
                    />
                </Grid>
                <Grid item>
                    <div className={classes.buttonWrap}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={handleSearchClick}
                            disabled={values.mote.value === undefined
                                || values.mote.value === null
                                || values.timeZone.value === undefined
                                || values.timeZone.value === null
                            }
                        >
                            Search
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default connect (state => ({
    motes: state.filterStore.motes,
    isLoading: state.filterStore.isLoading,
}), {
    getMotes,
}) (EventsFilters);
