import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: grey["200"],
        color: grey["900"],
    },
    headerText: {
        fontSize: '1.1rem',
        flexGrow: 1,
    }
});

export default function TitleBar(props) {
    const classes = useStyles();
    const { title, children } = props;

    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0} className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={classes.headerText}>
                        {title}
                    </Typography>
                    {children}
                </Toolbar>
            </AppBar>
        </div>
    );
}
