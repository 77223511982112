import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/FilterList';
import LinearProgress from '@material-ui/core/LinearProgress';
import TitleBar from '../components/common/TitleBar';
import FarmStatusTable from '../components/farmStatus/FarmStatusTable';
import FilterDrawer from '../components/nav/FilterDrawer';
import FilterSummary from '../components/dashboard/FilterSummary';
import Toaster from '../components/common/Toaster';
import {AuthContext} from "../use-auth";
import {getFarmStatus} from "../store/FarmStatusStore";
import FarmStatusFilters from "../components/farmStatus/FarmStatusFilters";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    fullWidth: {
        width: '100%',
    },
    widthWithoutDrawer: {
        width: 'calc(100% - 300px)',
    },
    button: {
        fontSize: '0.7rem',
        fontWeight: 600,
        letterSpacing: 0,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function FarmStatus({getFarmStatus, farmStatus, isLoading, isSuccessful}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { currentUser } = useContext(AuthContext);

    const [filters, setFilters] = React.useState({});
    const [showFilters, setShowFilters] = React.useState(true);
    const [showMobileFilters, setShowMobileFilters] = React.useState(false);

    async function handleFilterSearchClick(filters) {
        setShowMobileFilters(false);
        setFilters(filters);
        getFarmStatus(currentUser, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
    }

    function handleShowFiltersClick() {
        setShowFilters(!showFilters);
    }

    function handleShowMobileFiltersClick() {
        setShowMobileFilters(!showMobileFilters);
    }

    return (
        <div>
            <TitleBar title="Farm Status">
                <Button color="secondary" className={classes.button} onClick={isDesktop ? handleShowFiltersClick : handleShowMobileFiltersClick}>
                    <FilterIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                    {isDesktop && <div>{!showFilters ? 'Show Filters' : 'Hide Filters'}</div>}
                </Button>
            </TitleBar>
            <div className={isDesktop ? classes.root : ''}>
                <div className={clsx({
                        [classes.fullWidth]: isDesktop && !showFilters,
                        [classes.widthWithoutDrawer]: isDesktop && showFilters
                    },
                )}>
                    {filters && filters.dateFrom &&
                    <FilterSummary filters={filters} farmTimeZone={''} hideMoteInFilter={true} format="dd/MM/yyyy HH:mm"/>
                    }
                    {isLoading && <LinearProgress />}
                    {isSuccessful && farmStatus &&
                        <Toaster variant="success" message="Retrieved Farm Status!"/>
                    }
                    <FarmStatusTable farmStatus={farmStatus}/>
                </div>
                <div>
                    <FilterDrawer
                        drawerOpen={showFilters}
                        mobileDrawerOpen={showMobileFilters}
                        toggleMobileDrawerOpen={handleShowMobileFiltersClick}
                    >
                        <FarmStatusFilters onSearchClick={handleFilterSearchClick} />
                    </FilterDrawer>
                </div>
            </div>
        </div>
    );
}

export default connect (state => ({
    farmStatus: state.farmStatusStore.farmStatus,
    isLoading: state.farmStatusStore.isLoading,
    isSuccessful: state.farmStatusStore.isSuccessful,
}),{
    getFarmStatus,
})(FarmStatus)
