import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import {DateTime} from "luxon";
import FarmGroupsAutoComplete from "../common/FarmGroupsAutoComplete";

const useStyles = makeStyles(theme => ({
    datePicker: {
        maxWidth: 260,
        whiteSpace: 'pre-line',
    },
}));

function FarmGroupSearch({onSearchClick}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedFarmGroup, setSelectedFarmGroup] = React.useState({});
    const handleFarmGroupSelect = (farmGroup) => {
        setSelectedFarmGroup(farmGroup);
    };

    const [selectedDateReported, setSelectedDateReported] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')).startOf('day'));
    return (
        <Grid container spacing={1}>
            <Grid item>
                <FarmGroupsAutoComplete onFarmGroupSelect={handleFarmGroupSelect} />
            </Grid>
            <Grid item>
                <FormControl className={classes.datePicker}>
                    <KeyboardDateTimePicker
                        autoOk
                        ampm={false}
                        variant={isDesktop ? "inline" : "dialog"}
                        inputVariant="outlined"
                        label={selectedFarmGroup && selectedFarmGroup.farm_group_time_zone ? `at ${selectedFarmGroup.farm_group_time_zone} midday` : 'On'}
                        format="dd/MM/yyyy HH:mm"
                        value={selectedDateReported}
                        disableFuture
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => {setSelectedDateReported(date)}}
                        className={classes.datePicker}
                        disabled={!selectedFarmGroup || !selectedFarmGroup.farm_group_name}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl className={classes.formControl}>
                    <Fab color="primary" size="small" aria-label="send" onClick={() => onSearchClick(selectedFarmGroup, selectedDateReported)} disabled={!selectedFarmGroup || !selectedFarmGroup.farm_group_name}>
                        <SendIcon fontSize="small"/>
                    </Fab>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default FarmGroupSearch;
