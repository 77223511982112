import React, {useContext, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {getZoneHapgrs, getRegressionInput, approveBatch, estimateZonePphs} from "../../../store/RegressionStore";
import AuthContext from "../../../AuthContext";
import RegressionTabs from "./RegressionTabs";
import Formula from "./Formula";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function Regression({open, onClose, regressionInput, getRegressionInput, zoneHapgrs, getZoneHapgrs, approveBatch, estimateZonePphs, batchId, processIds, dateReported}) {
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getRegressionInput(token, batchId);
        };
        if (open) {
            callApi();
        }
    }, [currentUser, getRegressionInput, batchId, open]);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getZoneHapgrs(token, batchId);
        };
        if (open) {
            callApi();
        }
    }, [currentUser, getZoneHapgrs, batchId, open]);

    const handleApproveBatch = async (type) => {
        const token = await currentUser.getIdToken(true);
        approveBatch(token, batchId, type);
    }

    const handleEstimateZonePPHs = async () => {
        const token = await currentUser.getIdToken(true);
        estimateZonePphs(token, batchId);
    }

    return (
        <div>
            <Dialog fullScreen onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    Regression <Formula batchId={batchId} processIds={processIds} dateReported={dateReported}/>
                </DialogTitle>
                <DialogContent dividers>
                    {regressionInput && <RegressionTabs batchId={batchId} regressionInput={regressionInput} zoneHapgrs={zoneHapgrs}/>}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleEstimateZonePPHs} color="primary" variant="outlined">
                        Estimate Zone PPHs
                    </Button>
                    <Button autoFocus onClick={() => handleApproveBatch('NDVI')} color="primary" variant="outlined">
                        Approve NDVI
                    </Button>
                    <Button autoFocus onClick={() => handleApproveBatch('NDRE')} color="primary" variant="outlined">
                        Approve NDRE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect( state => ({
    isLoading: state.regressionStore.isLoading,
    regressionInput: state.regressionStore.regressionInput,
    zoneHapgrs: state.regressionStore.zoneHapgrs
}), {
    getRegressionInput,
    getZoneHapgrs,
    approveBatch,
    estimateZonePphs
}) (Regression);
