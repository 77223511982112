import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const ENTITY_EDIT_START = 'ENTITY_EDIT_START';
const startLoadingEntityEdit = createAction(ENTITY_EDIT_START);

const MOTE_MAPPINGS_GET = 'MOTE_MAPPINGS_GET';
const loadMoteMappings = createAction(MOTE_MAPPINGS_GET);
const MOTE_MAPPINGS_GET_START = 'MOTE_MAPPINGS_GET_START';
const startLoadingMoteMappings = createAction(MOTE_MAPPINGS_GET_START);

const ZONE_MOTE_MAPPINGS_GET = 'ZONE_MOTE_MAPPINGS_GET';
const loadZoneMoteMappings = createAction(ZONE_MOTE_MAPPINGS_GET);
const ZONE_MOTE_MAPPINGS_GET_START = 'ZONE_MOTE_MAPPINGS_GET_START';
const startLoadingZoneMoteMappings = createAction(ZONE_MOTE_MAPPINGS_GET_START);

const UNMAPPED_MOTES_GET = 'UNMAPPED_MOTES_GET';
const loadUnmappedMotes = createAction(UNMAPPED_MOTES_GET);
const UNMAPPED_MOTES_GET_START = 'UNMAPPED_MOTES_GET_START';
const startLoadingUnmappedMotes = createAction(UNMAPPED_MOTES_GET_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getMoteMappings = (token, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingMoteMappings ());
        dispatch (showLoader ());
        axios.post(`/motes/mappings/farm-group/${farmGroupId}`, {
            date_reported: dateReported
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadMoteMappings(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getZoneMoteMappings = (token, moteId) => {
    return dispatch => {
        dispatch (startLoadingZoneMoteMappings ());
        dispatch (showLoader ());
        axios.get(`/motes/${moteId}/zone-mappings`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadZoneMoteMappings(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const updateZoneMoteMappings = (token, moteId, zoneId, startDate, endDate) => {
    return dispatch => {
        dispatch (startLoadingZoneMoteMappings ());
        dispatch (showLoader ());
        axios.post(`/motes/${moteId}/zone-mappings/zone/${zoneId}`, {
            start_date: startDate,
            end_date: endDate
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadZoneMoteMappings(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const addFarm = (token, farmGroupId, farmName, farmAddress, timeZone) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.post(`/farm/add/farm-group/${farmGroupId}`, {
            farm_name: farmName,
            farm_address: farmAddress,
            time_zone: timeZone,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const renameFarm = (token, farmId, farmName, farmAddress, timeZone, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.patch(`/farm/${farmId}/rename`, {
            new_farm_name: farmName,
            new_farm_address: farmAddress,
            new_time_zone: timeZone,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported)
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


export const archiveFarm = (token, farmId, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.delete(`/farm/${farmId}/archive`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const addPaddock = (token, farmId, paddockName, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.post(`/paddock/add/farm/${farmId}`, {
            farm_id: farmId,
            paddock_name: paddockName,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const renamePaddock = (token, paddockId, paddockName, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.patch(`/paddock/${paddockId}/rename`, {
            new_paddock_name: paddockName,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


export const archivePaddock = (token, paddockId, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.delete(`/paddock/${paddockId}/archive`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const addZone = (token, paddockId, zoneName, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.post(`/zone/add/paddock/${paddockId}`, {
            paddock_id: paddockId,
            zone_name: zoneName,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const renameZone = (token, zoneId, zoneName, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.patch(`/zone/${zoneId}/rename`, {
            new_zone_name: zoneName,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


export const archiveZone = (token, zoneId, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.delete(`/zone/${zoneId}/archive`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getUnmappedMotes = (token, farmGroupId) => {
    return dispatch => {
        dispatch (startLoadingUnmappedMotes ());
        dispatch (showLoader ());
        axios.get(`/motes/unmapped/farm-group/${farmGroupId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadUnmappedMotes(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const mapMote = (token, zoneId, moteId, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.get(`/mote/${moteId}/map/zone/${zoneId}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const unmapMote = (token, moteId, farmGroupId, dateReported) => {
    return dispatch => {
        dispatch (startLoadingEntityEdit ());
        dispatch (showLoader ());
        axios.get(`/mote/${moteId}/unmap`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getMoteMappings(token, farmGroupId, dateReported);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        MOTE_MAPPINGS_GET_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        MOTE_MAPPINGS_GET: (state, action) => {
            return {
                ...state,
                mappings: action.payload,
                isSuccessful: true,
            };
        },
        ZONE_MOTE_MAPPINGS_GET_START: (state, action) => {
            return {
                ...state,
                zoneMoteMappings: [],
                isSuccessful: false,
            };
        },
        ZONE_MOTE_MAPPINGS_GET: (state, action) => {
            return {
                ...state,
                zoneMoteMappings: action.payload,
                isSuccessful: true,
            };
        },
        UNMAPPED_MOTES_GET_START: (state, action) => {
            return {
                ...state,
                unmappedMotes: [],
                isSuccessful: false,
            };
        },
        UNMAPPED_MOTES_GET: (state, action) => {
            return {
                ...state,
                unmappedMotes: action.payload,
                isSuccessful: true,
            };
        },
        ENTITY_EDIT_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
