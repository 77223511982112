import React, {useContext, useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import AssociatedIcon from '@material-ui/icons/FlashOn';
import DisassociatedIcon from '@material-ui/icons/FlashOff';
import ArchivedIcon from '@material-ui/icons/Block';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import {getMoteAssociationsHistory} from "../../../store/AssociationsStore";
import {AuthContext} from "../../../use-auth";

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 550,
        maxHeight: 'calc(100vh - 360px)',
        overflow: 'scroll',
    },
    iconText: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function AssociationsHistory({moteId, getMoteAssociationsHistory, associationsHistory, isLoading}) {
    const classes = useStyles();

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        getMoteAssociationsHistory(currentUser, moteId);
    },[moteId, getMoteAssociationsHistory, currentUser]);

    const dotColor = (index, length) => {
      if (index === 0) {
          return "primary";
      } else if (index === length - 1) {
          return "secondary";
      } else {
          return "";
      }
    };

    return (
        <div className={classes.root}>
            <Timeline>
                {associationsHistory.map((h, i) =>
                    <TimelineItem>
                        <TimelineOppositeContent>
                            <Typography color="textSecondary">{h.farm_group_name}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={dotColor(i, associationsHistory.length)} />
                            {i !== associationsHistory.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            {h.date_archived && <Typography color="textSecondary" className={classes.iconText}><ArchivedIcon fontSize="small"/> {h.date_archived}</Typography>}
                            {h.date_disassociated && <Typography color="textSecondary" className={classes.iconText}><DisassociatedIcon fontSize="small"/> {h.date_disassociated}</Typography>}
                            {h.date_associated && <Typography color="textSecondary" className={classes.iconText}><AssociatedIcon fontSize="small"/> {h.date_associated}</Typography>}
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
        </div>
    );
}

export default connect (state => ({
    associationsHistory: state.associationsStore.associationsHistory,
    isLoading: state.associationsStore.isLoading,
    isSuccessful: state.associationsStore.isSuccessful,
}), {
    getMoteAssociationsHistory,
}) (AssociationsHistory);
