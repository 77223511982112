import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Raw from './tabs/Raw';
import Filtered from './tabs/Filtered';
import Sensors from './tabs/Sensors';
import Motes from './tabs/Motes';
import MoteDM from './tabs/MoteDM';
import Zones from './tabs/Zones';
import Paddocks from './tabs/Paddocks';
import {AuthContext} from "../../use-auth";
import {connect} from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabContainer: {
        overflowY: 'scroll',
        height: '75vh',
    }
}));

function DashboardTabs({ filters, setHideMoteInFilter, isLoading }) {
    const classes = useStyles();
    const { loading, isAdmin, farmGroupId } = useContext(AuthContext);

    useEffect(() => {
        if(loading === false && isAdmin === false) {
            setHideMoteInFilter(true);
        }
    }, [loading, isAdmin, setHideMoteInFilter]);

    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setHideMoteInFilter(newValue > 2);
        setValue(newValue);
    }

    //TODO: move this to proper permissions system
    function showPaddockTabForFarmGroup() {
        const agriProveId = 142;
        const ruralDirectionsId = 140;
        return farmGroupId !== agriProveId &&
            farmGroupId !== ruralDirectionsId;
    }

    return (<>
        {isLoading && <LinearProgress/>}
        <div className={classes.root}>
            {isAdmin &&
                <>
                <AppBar elevation={0} position="static" color="default" className={classes.appBar}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Raw"/>
                        <Tab label="Filtered"/>
                        <Tab label="Sensors"/>
                        <Tab label="Motes"/>
                        <Tab label="Mote DM"/>
                        <Tab label="Zones"/>
                        <Tab label="Paddocks"/>
                    </Tabs>
                </AppBar>
                {value === 0 && <TabContainer><div><Raw filters={filters} /></div></TabContainer>}
                {value === 1 && <TabContainer><div><Filtered filters={filters}/></div></TabContainer>}
                {value === 2 && <TabContainer><div><Sensors filters={filters} /></div></TabContainer>}
                {value === 3 && <TabContainer><div className={classes.tabContainer}><Motes filters={filters} className={classes.tabContainer}/></div></TabContainer>}
                {value === 4 && <TabContainer><div className={classes.tabContainer}><MoteDM filters={filters} /></div></TabContainer>}
                {value === 5 && <TabContainer><div className={classes.tabContainer}><Zones filters={filters} /></div></TabContainer>}
                {value === 6 && <TabContainer><div className={classes.tabContainer}><Paddocks filters={filters} /></div></TabContainer>}
            </>
            }
            {!isAdmin && !showPaddockTabForFarmGroup() && <div className={classes.tabContainer}><MoteDM filters={filters} /></div>}
            {!isAdmin && showPaddockTabForFarmGroup() && <div className={classes.tabContainer}><Paddocks filters={filters} /></div>}
        </div>
   </> );
}

export default connect (state => ({
    isLoading: state.dashboardStore.isLoading,
})) (DashboardTabs);
