import React, { useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoveIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";
import {save} from "../../../store/ReportStore";
import {AuthContext} from "../../../use-auth";
import AdjustmentAddRow from "./AdjustmentAddRow";
import Toaster from "../../common/Toaster";

const useStyles = makeStyles(theme => ({
    container: {
        cursor: 'pointer',
        height: 210,
        width: 600,
    },
    cell: {
        padding: 4,
        border: 'solid lightgray 0.001rem',
        textAlign: 'center',
    },
    actionCell: {
        border: 'none',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    datePicker: {
        minWidth: 210,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function MoteAdjustmentsDialog({open, onClose, farmId, farmName, farmTimeZone, motes, save, isSuccessful}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 5;

    const [reportedMotes, setReportedMotes] = useState([]);
    const handleAdd = (mote, reason, notes, begin, end, pgr) => {
        setReportedMotes([
            ...reportedMotes,
            { mote, reason, notes, begin, end, pgr },
        ]);
    };

    const handleDelete = (moteId, reasonName, begin, end) => {
        const notRequired = reportedMotes.filter(rm => rm.mote.mote_id === moteId && rm.reason.name === reasonName && rm.begin === begin && rm.end === end);
        setReportedMotes(reportedMotes.filter(rm => !notRequired.includes(rm)));
    };

    const handleCancel = () => {
        setReportedMotes([]);
        onClose();
    };

    const handleSave = async () => {
        const token = await currentUser.getIdToken(true);
        const reportedMotesToSave = reportedMotes.map(rg => ({mote_id: rg.mote.mote_id, mote_name: rg.mote.mote_name, type: rg.reason.type, reason: rg.reason.name , notes: rg.notes, begin: rg.begin, end: rg.end, pgr: rg.pgr}));
        save(token, farmId, farmName, reportedMotesToSave);
        setReportedMotes([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose} maxWidth={500}>
                <DialogTitle>Mote Adjustments</DialogTitle>
                <DialogContent className={classes.content}>
                    <AdjustmentAddRow motes={motes} onAdd={handleAdd} />
                    <TableContainer className={classes.container}>
                        <Table stickyHeader size="small" align={"center"} aria-label="sticky sensors table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell}>Mote</TableCell>
                                    <TableCell className={classes.cell}>Reason</TableCell>
                                    <TableCell className={classes.cell}>Notes</TableCell>
                                    <TableCell className={classes.cell}>Started</TableCell>
                                    <TableCell className={classes.cell}>Ended</TableCell>
                                    <TableCell className={classes.cell}>Entered PGR</TableCell>
                                    <TableCell className={classes.actionCell}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportedMotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(r => <TableRow>
                                        <TableCell className={classes.cell}>{r.mote.mote_name}</TableCell>
                                        <TableCell className={classes.cell}>{r.reason.name}</TableCell>
                                        <TableCell className={classes.cell}>{r.notes}</TableCell>
                                        <TableCell className={classes.cell}>{r.begin}</TableCell>
                                        <TableCell className={classes.cell}>{r.end}</TableCell>
                                        <TableCell className={classes.cell}>{r.pgr}</TableCell>
                                        <TableCell className={classes.actionCell}><DeleteIcon fontSize="small" onClick={() => handleDelete(r.mote.mote_id, r.reason.name, r.begin, r.end)}/></TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={reportedMotes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    <div className={classes.actions}>
                        <IconButton color="default" aria-label="cancel" className={classes.margin} onClick={handleCancel}>
                            <CancelIcon fontSize="small" elevation={0}/>
                        </IconButton>
                        <Fab color="primary" size="small" aria-label="move" className={classes.margin} onClick={handleSave} disabled={!reportedMotes || reportedMotes.length === 0}>
                            <MoveIcon fontSize="small" />
                        </Fab>
                    </div>
                </DialogContent>
            </Dialog>
            {isSuccessful && <Toaster variant="success" message="Successfully reported grazings!"/>}
        </div>
    );
}

export default connect (state => ({
    isLoading: state.reportStore.isLoading,
    isSuccessful: state.reportStore.isSuccessful,
}), {
    save,
}) (MoteAdjustmentsDialog);
