import React, {useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import GroupIcon from '@material-ui/icons/GroupWork';
import GrazingIcon from '@material-ui/icons/Texture';
import ReportIcon from '@material-ui/icons/ReportProblem';
import GroupsDialog from './GroupsDialog';
import GrazingDialog from './GrazingDialog';
import {connect} from "react-redux";
import {getZones, getMotes} from "../../../store/PaddockActionsStore";
import {AuthContext} from "../../../use-auth";
import MoteAdjustmentsDialog from "./MoteAdjustmentsDialog";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2) + 300,
    },
    tooltip: {
        fontSize: 8,
    },
}));

function PaddockActions({farmId, farmName, farmTimeZone, getZones, zones, getMotes, motes, onMove}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const [open, setOpen] = React.useState(false);
    const [showGroupAddDialog, setShowGroupAddDialog] = React.useState(false);
    const [showGrazingDialog, setShowGrazingDialog] = React.useState(false);
    const [showReportDialog, setShowReportDialog] = React.useState(false);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getZones(token, farmId);
            getMotes(token, farmId);
        };
        if (farmId) {
            callApi();
        }
    }, [farmId, getZones, getMotes, currentUser]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const actions = [
        { icon: <GrazingIcon />, name: 'Report Grazing', onClick: () =>  {handleClose(); setShowGrazingDialog(true);} },
        { icon: <GroupIcon />, name: 'Paddock Groups', onClick: () =>  {handleClose(); setShowGroupAddDialog(true);} },
        { icon: <ReportIcon />, name: 'Mote Adjustments', onClick: () =>  {handleClose(); setShowReportDialog(true);} },
    ];

    return (
        <div className={classes.root}>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        className={classes.tooltip}
                        tooltipOpen
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
            <GroupsDialog open={showGroupAddDialog} onClose={() => setShowGroupAddDialog(false)} farmId={farmId} zones={zones} onMove={onMove}/>
            <GrazingDialog open={showGrazingDialog} onClose={() => setShowGrazingDialog(false)} farmId={farmId} farmName={farmName} farmTimeZone={farmTimeZone} zones={zones}/>
            <MoteAdjustmentsDialog open={showReportDialog} onClose={() => setShowReportDialog(false)} farmId={farmId} farmName={farmName} farmTimeZone={farmTimeZone} motes={motes}/>
        </div>
    );
}

export default connect (state => ({
    zones: state.paddockActionsStore.zones,
    motes: state.paddockActionsStore.motes,
    isLoading: state.paddockActionsStore.isLoading,
    isSuccessful: state.paddockActionsStore.isSuccessful,
}), {
    getZones,
    getMotes,
}) (PaddockActions);
