import React, {useContext, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {DatePicker} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {DateTime} from "luxon";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import MoveIcon from '@material-ui/icons/ArrowForward';
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import {getGroups, save} from "../../../store/GroupsStore";
import {AuthContext} from "../../../use-auth";
import GroupAddDialog from "./GroupAddDialog";
import Toaster from "../../common/Toaster";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    content: {
        padding: 0,
    },
}));

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function GroupsDialog({open, onClose, farmId, farmTimeZone, zones, getGroups, groups, save, onMove, isSuccessful}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getGroups(token, farmId);
        };
        if (farmId) {
            callApi();
        }
    }, [farmId, getGroups, currentUser]);

    const [zone, setZone] = React.useState('');
    const [group, setGroup] = React.useState('');
    const [selectedSinceDate, handleSinceDateChange] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')));
    const [tab, setTab] = React.useState(0);

    const handleAddGroup = async () => {
        const token = await currentUser.getIdToken(true);
        getGroups(token, farmId);
        setTab(0);
    };

    const handleMove = async () => {
        const token = await currentUser.getIdToken(true);
        save(token, group.zone_group_id, zone.zone_id, selectedSinceDate, farmTimeZone);
        setZone('');
        setGroup('');
        handleSinceDateChange(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')));
        onMove();
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Paddock Groups</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <Tabs
                            value={tab}
                            onChange={(e, newValue) => setTab(newValue)}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Move"/>
                            <Tab label="Add"/>
                        </Tabs>
                    </div>
                    {tab === 0 && <TabContainer>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="paddock-select"
                                    style={{ width: 210 }}
                                    options={zones}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={option => option.zone_name}
                                    renderOption={option => (
                                        <React.Fragment>
                                            {option.zone_name}
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Paddock"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                    value={zone}
                                    onChange={(event, newValue) => {
                                        setZone(newValue);
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="group-select"
                                    style={{ width: 210 }}
                                    options={groups}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={option => option.zone_group_name}
                                    renderOption={option => (
                                        <React.Fragment>
                                            {option.zone_group_name}
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Group"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                    value={group}
                                    onChange={(event, newValue) => {
                                        setGroup(newValue);
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <DatePicker
                                    autoOk
                                    variant={isDesktop ? "inline" : "dialog"}
                                    inputVariant="outlined"
                                    label="Since"
                                    format="dd/MM/yyyy"
                                    value={selectedSinceDate}
                                    disableFuture
                                    InputAdornmentProps={{ position: "start" }}
                                    onChange={date => handleSinceDateChange(date)}
                                    className={classes.datePicker}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <Fab color="primary" size="small" aria-label="move" onClick={handleMove}>
                                    <MoveIcon />
                                </Fab>
                            </FormControl>
                        </form>
                    </TabContainer>}
                    {tab === 1 && <TabContainer>
                        <GroupAddDialog farmId={farmId} onAdd={handleAddGroup} onMove={onMove} />
                    </TabContainer>}
                </DialogContent>
            </Dialog>
            {isSuccessful && <Toaster variant="success" message="Successfully moved Paddock!"/>}
        </div>
    );
}

export default connect (state => ({
    groups: state.groupsStore.groups,
    isLoading: state.groupsStore.isLoading,
    isSuccessful: state.groupsStore.isSuccessful,
}), {
    getGroups,
    save,
}) (GroupsDialog);
