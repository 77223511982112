import React, {useContext, useEffect, useMemo, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {connect} from "react-redux";
import SingleSceneToBatch from "./SingleSceneToBatch";
import Button from "@material-ui/core/Button";
import {batchProcesses} from "../../../store/ScenesStore";
import {transformToNdviProcesses} from "./ProcessBatchUtil";
import {firestore} from "../../../base";
import {AuthContext} from "../../../use-auth";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: 20,
        },
        paper: {
            padding: 20,
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
        }
    }),
);

function ScenesToBatch({batchProcesses}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const [downloadedScenes, setDownloadedScenes] = useState([]);
    useEffect(() => {
        firestore.collection('ndvi-process-tracking').orderBy('datetime_image_str', 'desc').onSnapshot(querySnapshot => {
            setDownloadedScenes(querySnapshot.docs.map(ds => { return {...ds.data(), id: ds.id}}));
        });
    }, []);

    const [processIds, setProcessIds] = useState([]);
    const handleSceneSelect = (processId) => {
        let updatedProcessIds;
        if (processIds.find(p => p === processId)) {
            updatedProcessIds = processIds.filter(p => p !== processId);
        } else {
            updatedProcessIds = [...processIds, processId]
        }
        setProcessIds(updatedProcessIds);
    }

    const handleProcessClick = async () => {
        const token = await currentUser.getIdToken(true);
        const result = await batchProcesses(token, processIds);
        console.dir(result);
    }

    const transformedNdviProcesses = useMemo(() => downloadedScenes && downloadedScenes.length > 0 ? transformToNdviProcesses(downloadedScenes) : [], [downloadedScenes]);

    const [filteredData, setFilteredData] = useState(transformedNdviProcesses);
    useEffect(() => {
        setFilteredData(transformedNdviProcesses);
    },[transformedNdviProcesses]);

    const handleImageDateChange = (event) => event.target.value && setFilteredData(transformedNdviProcesses.filter(t => t.scene.datetimeImage.includes(event.target.value)));
    const handleSceneIdChange = (event) => event.target.value && setFilteredData(transformedNdviProcesses.filter(t => t.scene.sceneId.includes(event.target.value)));
    const handleReportedDateChange = (event) => event.target.value && setFilteredData(transformedNdviProcesses.filter(t => t.dateReported?.includes(event.target.value)));
    const handleFarmChange = (event) => event.target.value && setFilteredData(transformedNdviProcesses.filter(t => t.farmName.includes(event.target.value)));

    return (
        <div className={classes.root}>
            <div style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1">Scenes to be Batched</Typography>
            </div>
            <Paper elevation={3} className={classes.paper}>
                <div>
                    <Grid
                        container
                        spacing={1}
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Grid item xs={2} md={1}/>
                        <Grid item xs={10} md><TextField placeholder="Image Date" onChange={handleImageDateChange}/></Grid>
                        <Grid item xs={12} md><TextField placeholder="Scene ID" onChange={handleSceneIdChange}/></Grid>
                        <Grid item xs={10} md><TextField placeholder="Reported Date" onChange={handleReportedDateChange}/></Grid>
                        <Grid item xs={12} md><TextField placeholder="Farm" onChange={handleFarmChange}/></Grid>
                    </Grid>
                    {filteredData.map((sb) =>
                        <SingleSceneToBatch
                            processId={sb.processId}
                            dateReported={sb.dateReported}
                            scene={sb.scene}
                            orderId={sb.orderId}
                            farmName={sb.farmName}
                            fileNames={sb.fileNames}
                            onSelect={handleSceneSelect}
                        />
                    )}
                </div>
                <div className={classes.buttonWrapper}>
                    <Button variant="contained" color="secondary" style={{ fontSize: 12 }} onClick={handleProcessClick}>Process Batch</Button>
                </div>
            </Paper>
        </div>
    );
}

export default connect (state => ({
    isLoading: state.scenesStore.isLoading,
}), {
    batchProcesses,
}) (ScenesToBatch);
