import React, {useContext, useEffect, useState} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import pink from '@material-ui/core/colors/pink';
import indigo from '@material-ui/core/colors/indigo';
import {times, formatStrToDateMed} from "../../common/Util";
import NoDataMessage from "../../common/NoDataMessage";
import Toaster from "../../common/Toaster";
import {connect} from "react-redux";
import {getFiltered} from "../../../store/DashboardStore";
import {AuthContext} from "../../../use-auth";

const useStyles = makeStyles( {
    root: {
        maxWidth: 'fit-content',
        cursor: 'pointer',
    },
    container: {
        maxHeight: 'calc(100vh - 275px)',
        maxWidth: 'fit-content',
    },
    cell: {
        padding: 4,
        border: 'solid lightgray 0.001rem',
    },
    centerCell: {
        textAlign: 'center',
    },
    rightCell: {
        textAlign: 'right',
        paddingRight: '5px !important',
    },
    headerCell: {
        height: 'fit-content',
    },
    lastCell: {
        borderBottom: 'solid darkgray 0.2rem',
    },
    missingValueCell: {
        backgroundColor: pink[50],
    },
    estimatedValueCell: {
        backgroundColor: indigo[100],
        fontStyle: 'italic',
    },
    focusedRow: {
        backgroundColor: '#FBEB74 !important',
    },
});

function Filtered({getFiltered, filtered, isSuccessful, isFailed, filters}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);
    const [selectedRow, setSelectedRow] = useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const emptyRows = filtered ? rowsPerPage - Math.min(rowsPerPage, filtered.length - page * rowsPerPage) : 0;
    const totalSensors = 6;

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getFiltered(token, filters.moteId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
        };
        if (filters && filters.dateFrom) {
            callApi();
        }
    }, [filters, getFiltered, currentUser]);

    useEffect(() => {
        setPage(0);
    },[filtered]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const average = (sensors) => {
        const heights = Object.values(sensors).map(s => s.height);
        const sumHeights = heights.reduce((agg, h) => agg + h, 0);
        return Math.round(sumHeights/heights.length * 100)/100;
    };

    if (!filtered) {
        return <NoDataMessage isSuccessful={false} requiredField="Mote" />;
    }

    let progressComponent;

    if (isSuccessful) {
        progressComponent = <Toaster variant="success" message="Successfully fetched Filtered Data!"/>
    } else if (isFailed) {
        progressComponent = <Toaster variant="error" message="Failed to fetch Filtered Data!"/>
    }

    return ( <>
        {progressComponent}
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small" align={"center"} aria-label="sticky filtered table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={clsx(classes.headerCell, classes.cell)} rowSpan={2}>Reported Date</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell)} rowSpan={2}><Typography style={{ transform: 'rotate(270deg)'}}>Round</Typography></TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell)} colSpan={7} align="center">Sensors</TableCell>
                        </TableRow>
                        <TableRow>
                            {times(totalSensors,n => <TableCell className={classes.cell} align="center">{n+1}</TableCell>)}
                            <TableCell className={classes.cell} align="center">Avg</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(f => <>
                                <TableRow>
                                    <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} rowSpan={f.heights.length + 1}>{formatStrToDateMed(f.date_reported, filters.timeZone)}</TableCell>
                                </TableRow>
                                {f.heights.map( (h,i) =>
                                    <TableRow onClick={() => setSelectedRow({round: h.round, date: f.date_reported})}
                                       selected={h.round === selectedRow.round && f.date_reported === selectedRow.date}
                                       classes={{selected: classes.focusedRow}}>
                                        <TableCell className={clsx(classes.cell, i === f.heights.length - 1 && classes.lastCell, classes.centerCell)}>{h.round}</TableCell>
                                        {times(totalSensors, (sen) =>
                                            <TableCell className={clsx(
                                                classes.cell
                                                , i === f.heights.length - 1 && classes.lastCell
                                                , h.sensors[sen+1]?.height === undefined && classes.missingValueCell
                                                , h.sensors[sen+1]?.is_estimate && classes.estimatedValueCell
                                                , classes.rightCell
                                            )}>
                                                {h.sensors[sen+1]?.height}
                                            </TableCell>
                                        )}
                                        <TableCell className={clsx(classes.cell, classes.centerCell,  i === f.heights.length - 1 && classes.lastCell)}>{average(h.sensors)}</TableCell>
                                </TableRow>)}
                                </>
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 24.5 * emptyRows * 5 }}>
                                <TableCell />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    </>);
}

export default connect (state => ({
    filtered: state.dashboardStore.filtered,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
    isFailed: state.dashboardStore.isFailed,
}), {
    getFiltered,
}) (Filtered);
