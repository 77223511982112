import React, {useContext, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import SingleSelectAutoComplete from '../common/SingleSelectAutoComplete';
import {connect} from "react-redux";
import {getMotes, getFarms, getFilters, saveFilters} from "../../store/FilterStore";
import Typography from "@material-ui/core/Typography";
import {AuthContext} from "../../use-auth";

const useStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 128px)',
        padding: 20,
    },
    formControl: {
        minWidth: 180,
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: 20,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    datePicker: {
        maxWidth: 260,
        whiteSpace: 'pre-line',
    },
    slider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

function DashboardFilters({ hideMoteInFilter, onSearchClick, getMotes, motes, getFarms, farms, saveFilters }) {

    const { currentUser, isAdmin, farmGroupId, loading } = useContext(AuthContext);

    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedFromDate, handleFromDateChange] = React.useState(DateTime.fromISO(DateTime.local().minus({ days: 6 }).toFormat('yyyy-MM-dd')));
    const [selectedToDate, handleToDateChange] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')));

    const browserTimeZone = DateTime.local().zoneName;

    const defaultTargetsWithTimeZones = {
        farmLocalTimeZone: '',
        timeZone: {
            value: browserTimeZone,
            label: `Browser Local (${browserTimeZone})`
        },
        targetPreGrazingResidual: 2850,
        targetPostGrazingResidual: 1500,
        targetPreCutting: 5000,
    };

    const defaultValues = {
        farm: {},
        mote: {},
        ...defaultTargetsWithTimeZones,
    };

    const [values, setValues] = React.useState(defaultValues);

    const defaultTimeZones = [
        {value: 'utc', label: `UTC`},
        {value: browserTimeZone, label: `Browser Local (${browserTimeZone})`},
    ];
    const [timeZones, setTimeZones] = React.useState(defaultTimeZones);

    const preLoadFilters = async (farmId) => {
        const token = await currentUser.getIdToken(true);
        const savedFilters = await getFilters(token, farmId);
        if(!savedFilters) {
            return;
        }
        handleChange('timeZone', {value: savedFilters.timeZone, label: savedFilters.timeZoneName});
        handleChange('targetPreGrazingResidual', savedFilters.targetPreGrazingResidual);
        handleChange('targetPostGrazingResidual', savedFilters.targetPostGrazingResidual);
        handleChange('targetPreCutting', savedFilters.targetPreCutting);
    };

    function handleChange(name, value) {
        setValues(oldValues => ({
            ...oldValues,
            [name]: value,
        }));
        if (name === 'farm') {
            setValues(oldVaues => ({
                ...oldVaues,
                ...defaultTargetsWithTimeZones,
                farmLocalTimeZone: value.timeZone,
            }));
            const tzs = defaultTimeZones;
            tzs.push({
                value: value.timeZone,
                label: `Farm Local (${value.timeZone})`,
            });
            setTimeZones(tzs);
            preLoadFilters(value.value);
        }
    }

    useEffect(() => {
        if (!loading && farmGroupId > 0) {
            const callApi = async () => {
                const token = await currentUser.getIdToken(true);
                getMotes(token, farmGroupId);
                getFarms(token, farmGroupId);
            };
            callApi();
        }
    }, [loading, farmGroupId, getMotes, getFarms, currentUser]);

    useEffect(() => {
        if (farms && farms.length > 0) {
            setValues(oldValues => ({
                ...oldValues,
                farmLocalTimeZone: farms[0].time_zone,
            }));
        }
    },[farms]);

    async function handleSearchClick() {
        const filters = {
            dateFrom: selectedFromDate.setZone(values.farmLocalTimeZone, { keepLocalTime: true }).plus({hours: 12}),
            dateTo: selectedToDate.setZone(values.farmLocalTimeZone, { keepLocalTime: true }).plus({hours: 12}),
            timeZone: values.timeZone.value,
            timeZoneName: values.timeZone.label,
            farmLocalTimeZone: values.farmLocalTimeZone,
            farmId: values.farm.value,
            farmName: values.farm.label,
            moteId: values.mote.value,
            moteName: values.mote.label,
            targetPreGrazingResidual: values.targetPreGrazingResidual,
            targetPostGrazingResidual: values.targetPostGrazingResidual,
            targetPreCutting: values.targetPreCutting,
        };
        onSearchClick(filters);
        const token = await currentUser.getIdToken(true);
        saveFilters(token, filters.farmId, filters);
    }

    const dateFiled = (label, value, minValue, onChange) => (
        <KeyboardDatePicker
            autoOk
            variant={isDesktop ? "inline" : "dialog"}
            inputVariant="outlined"
            label={label}
            format="dd/MM/yyyy"
            value={value}
            minDate={minValue}
            disableFuture
            InputAdornmentProps={{ position: "start" }}
            onChange={date => onChange(date)}
            className={classes.datePicker}
        />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    {dateFiled('From', selectedFromDate, '01/01/2018', handleFromDateChange)}
                </Grid>
                <Grid item>
                    {dateFiled('To', selectedToDate, selectedFromDate, handleToDateChange)}
                </Grid>
                {isAdmin &&
                    <Grid item>
                        <div className={classes.datePicker}>
                            <Typography variant="caption" color="primary">
                                <sup>*</sup> Each Date Filter is a reporting window which is previous day's Farm Local
                                Midday to Selected Date's Farm Local Midday
                            </Typography>
                        </div>
                    </Grid>
                }
                {hideMoteInFilter && farms &&
                    <Grid item>
                        <SingleSelectAutoComplete
                            name="farm"
                            title="Farm"
                            suggestions={farms.map(d => ({
                                value: d.farmId,
                                label: d.farmName,
                                timeZone: d.timeZone
                            }))}
                            value={{value: values.farm.value,
                                label: values.farm.label,
                                timeZone: values.farm.timeZone}}
                            handleChange={(value) => handleChange('farm', value)}
                        />
                    </Grid>
                }
                {!hideMoteInFilter && motes &&
                    <Grid item>
                        <SingleSelectAutoComplete
                            name="mote"
                            id="mote-single-select"
                            title="Mote"
                            suggestions={motes.map(d => ({
                                value: d.moteId,
                                label: `${d.moteName} - ${d.devEui}`,
                                timeZone: d.timeZone
                            }))}
                            value={{value: values.mote.value, label: values.mote.label, timeZone: values.mote.timeZone}}
                            handleChange={(value) => handleChange('mote', value)}
                        />
                    </Grid>
                }
                {isAdmin &&
                    <Grid item>
                        <SingleSelectAutoComplete
                            name="time-zone"
                            id="time-zone-single-select"
                            title="Time Zone"
                            suggestions={timeZones.map(d => ({
                                value: d.value,
                                label: d.label,
                            }))}
                            value={{value: values.timeZone.value, label: values.timeZone.label}}
                            handleChange={(value) => handleChange('timeZone', value)}
                        />
                    </Grid>
                }
                {!isAdmin &&
                    <Grid item>
                        {`Farm's Time Zone: ${values.farmLocalTimeZone}`}
                    </Grid>
                }
                {hideMoteInFilter && <>
                <Grid item>
                    <SingleSelectAutoComplete
                        name="pre-grazing-residual-target"
                        title="Pre Grazing Target"
                        suggestions={[2400, 2550, 2700, 2850, 3000, 3150, 3300].map(d => ({
                            value: d,
                            label: d,
                        }))}
                        value={{value: values.targetPreGrazingResidual, label: values.targetPreGrazingResidual}}
                        handleChange={(value) => handleChange('targetPreGrazingResidual', value.value)}
                    />
                </Grid>
                <Grid item>
                    <SingleSelectAutoComplete
                        name="post-grazing-residual-target"
                        title="Post Grazing Residual Target"
                        suggestions={[1050, 1200, 1350, 1500, 1650, 1800, 1950].map(d => ({
                            value: d,
                            label: d,
                        }))}
                        value={{value: values.targetPostGrazingResidual, label: values.targetPostGrazingResidual}}
                        handleChange={(value) => handleChange('targetPostGrazingResidual', value.value)}
                    />
                </Grid>
                <Grid item>
                    <SingleSelectAutoComplete
                        name="pre-cutting-target"
                        title="Pre Cutting Target"
                        suggestions={[4000, 4250, 4500, 4750, 5000, 5250, 5500, 5750, 6000].map(d => ({
                            value: d,
                            label: d,
                        }))}
                        value={{value: values.targetPreCutting, label: values.targetPreCutting}}
                        handleChange={(value) => handleChange('targetPreCutting', value.value)}
                    />
                </Grid>
                </>}
                <Grid item>
                    <Button variant="contained" color="secondary" className={classes.button} onClick={handleSearchClick}
                            disabled={(!hideMoteInFilter && (values.mote.value === undefined || values.mote.value === null))
                            || (hideMoteInFilter && (values.farm.value === undefined || values.farm.value === null))}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default connect (state => ({
    motes: state.filterStore.motes,
    farms: state.filterStore.farms,
    isLoading: state.filterStore.isLoading,
}), {
    getMotes,
    getFarms,
    saveFilters,
}) (DashboardFilters);
