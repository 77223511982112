import React, {useContext, useEffect} from 'react';
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {connect} from "react-redux";
import {getFarms} from "../../store/FilterStore";
import AuthContext from "../../AuthContext";

const FarmsAutoComplete = ({getFarms, isLoading, farms, onSelect}) => {
    const { currentUser, farmGroupId, loading } = useContext(AuthContext);

    useEffect(() => {
        if (!loading && farmGroupId > 0) {
            const callApi = async () => {
                const token = await currentUser.getIdToken(true);
                getFarms(token, farmGroupId);
            };
            callApi();
        }
    }, [loading, farmGroupId, getFarms, currentUser]);

    const [value, setValue] = React.useState();

    return (
        <Autocomplete
            id="farms"
            loading={isLoading}
            options={farms}
            getOptionLabel={(option) => option.farmName}
            renderInput={(params) => <TextField {...params} label="Farm" variant="outlined" />}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                onSelect(newValue);
            }}
        />
    );
}

export default connect (state => ({
    farms: state.filterStore.farms,
    isLoading: state.filterStore.isLoading,
}), {
    getFarms,
}) (FarmsAutoComplete);
