import React, { useEffect, useState } from "react";
import {auth} from "./base.js";
import {connect} from "react-redux";
import {getUser, setUserNull} from "./store/UsersStore";
import AuthContext from './AuthContext';

const AuthProvider = (props) => {
    const { children, getUser, setUserNull } = props;
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        auth.onAuthStateChanged((user) => {
            const callApi = async () => {
                setCurrentUser(user);
                if(currentUser) {
                    const token = await currentUser.getIdToken(true);
                    getUser(token, currentUser.uid);
                }
                setLoading(false);
            };
            callApi();
        });
    }, [currentUser, getUser]);

    function handleSignOut(history) {
        auth.signOut();
        setCurrentUser(null);
        setUserNull();
        history.push("/");
    }

    const farmGroupId = 86;

    return (
        <AuthContext.Provider
            value={{
                loading,
                currentUser,
                isAdmin: currentUser && (currentUser.email?.includes("@farmote.com") || currentUser.email?.includes("@farmote.eu")),
                farmGroupId: farmGroupId,
                signOut:handleSignOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default connect (state => ({
    user: state.usersStore.user,
    isLoading: state.usersStore.isLoading,
    isSuccessful: state.usersStore.isSuccessful,
}), {
    getUser,
    setUserNull,
}) (AuthProvider);
