import React, {useContext} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SingleSceneToDownload from "./SingleSceneToDownload";
import {connect} from "react-redux";
import {downloadScenes} from "../../../store/ScenesStore";
import AuthContext from "../../../AuthContext";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: 20,
        },
        paper: {
            padding: 20,
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
        }
    }),
);

function ScenesToDownload({scenes, downloadScenes, errors}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const handleDownloadClick = async () => {
      const token = await currentUser.getIdToken(true);
      downloadScenes(token, scenes.map(s => {return {...s, farmId: s.farm.farmId}}).filter(s => s.rejectReasons.length === 0));
    };

    if (scenes.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            {errors && errors.map(err =>
                <Alert severity="error">{err.message}</Alert>
            )}
            <div style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1">Scenes to be Downloaded</Typography>
            </div>
            <Paper elevation={3} className={classes.paper}>
                <div>
                    {scenes.map(s => (
                        <SingleSceneToDownload scene={s} key={s.sceneId}/>
                    ))}
                </div>
                <div className={classes.buttonWrapper}>
                    <Button variant="contained" color="secondary" style={{ fontSize: 12 }} onClick={handleDownloadClick}>Download & Process NDVI</Button>
                </div>
            </Paper>
        </div>
    );
}

export default connect (state => ({
    isLoading: state.scenesStore.isLoading,
    errors: state.scenesStore.errors,
}), {
    downloadScenes,
}) (ScenesToDownload);
