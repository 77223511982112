import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Logo from "../../resources/logo.png";
import Typography from "@material-ui/core/Typography";
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles( () => ({
    defaultMessage: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: grey[600],
    },
    infoIcon: {
        width: '15%',
    },
    sentiIcon: {
        width: 80,
        height: 80,
    },
}));

export default function NoDataMessage({isSuccessful, requiredField})
{
    const classes = useStyles();

    return (
        <div className={classes.defaultMessage}>
            {!isSuccessful &&
            <>
                <img src={Logo} alt="logo" className={classes.infoIcon}/>
                <div><Typography variant={"subtitle1"}>Nothing to see here</Typography></div>
                <div><Typography variant={"subtitle2"}>{`Please Select a ${requiredField} and Click Search`}</Typography></div>
            </>
            }
            {isSuccessful &&
            <>
                <SentimentDissatisfiedIcon className={classes.sentiIcon}/>
                <div><Typography variant={"subtitle1"}>No Mote Measurements</Typography></div>
                <div><Typography variant={"subtitle2"}>Please select different dates as there are no Mote Measurements in
                    this range</Typography></div>
            </>
            }
        </div>
    );
}
