import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const FARMS_GET = 'FARMS_GET';
const MOTES_GET = 'MOTES_GET';
const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

const loadFarms = createAction(FARMS_GET);
const loadMotes = createAction(MOTES_GET);
const showLoader = createAction(SHOW_LOADER);
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getFarms = (token, farmGroupId) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.get(`/farms/group/${farmGroupId}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadFarms(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getMotes = (token, farmGroupId) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.get(`/motes/farm-group/${farmGroupId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadMotes(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getFilters = async (token, farmId) => {
    const response = await axios.get(`/dashboard-filters/farm/${farmId}`,
        {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const saveFilters = (token, farmId, filters) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/dashboard-filters/farm/${farmId}`,
            {filters},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(() => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


// reducer
export default handleActions(
    {
        FARMS_GET: (state, action) => {
            return {
                ...state,
                farms: action.payload,
            };
        },
        MOTES_GET: (state, action) => {
            return {
                ...state,
                motes: action.payload,
            };
        },
        FILTERS_GET_START: (state) => {
            return {
                ...state,
                filters: {},
            }
        },
        FILTERS_GET: (state, action) => {
            return {
                ...state,
                filters: action.payload,
            }
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
