import React from 'react';
import {makeStyles} from '@material-ui/core';
import {DateTime} from 'luxon';

const useStyles = makeStyles( theme => ({
    filterSummary: {
        padding: 15,
        backgroundColor: '#fce9ef',
    },
    infoIcon: {
        verticalAlign: 'bottom',
    },
}));

export default function FilterSummary(props) {
    const classes = useStyles();
    const { filters, hideMoteInFilter, format } = props;

    return (
        <div className={classes.filterSummary}>
            showing results from <strong>{DateTime.fromISO(filters.dateFrom).toFormat(format)} </strong> to <strong>{DateTime.fromISO(filters.dateTo).toFormat(format)} </strong>
            <em>{filters.timeZoneName}</em>&nbsp;
            {hideMoteInFilter && <> for <strong>{filters.farmName}</strong></>}
            {!hideMoteInFilter && <> for <strong>{filters.moteName}</strong></>}
        </div>
    );
}
