import React, {useContext, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {getActivities} from "../store/ActivityStore";
import {AuthContext} from "../use-auth";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TitleBar from "../components/common/TitleBar";
import ReportIcon from '@material-ui/icons/ReportProblem';
import GrazingIcon from '@material-ui/icons/Texture';
import Grid from "@material-ui/core/Grid";
import {DateTime} from "luxon";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 20,
    },
    accordian: {
        backgroundColor: '#eeeeee',
    },
    colHeader: {
      width: 200,
      fontWeight: 'bold',
    },
}));

const Activity = ({getActivities, activities}) => {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getActivities(token);
        };
        callApi();
    }, [getActivities, currentUser]);

    const buildTimeline = (activity) => (
        <Accordion className={classes.accordian} elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="activity-content"
                id="activity-header"
            >
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <Typography variant="caption">{activity.time}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {activity.activity_type === 'MOTE_ADJUSTMENT' ? <ReportIcon fontSize="small" /> : <GrazingIcon fontSize="small" />}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="caption">{activity.activity_type} on {activity.farm_name} for {activity.zone_name ?? activity.mote_name}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} size="small" aria-label="details">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.colHeader}>Reported Date</TableCell>
                                <TableCell>{DateTime.fromISO(activity.date_reported).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.colHeader}>User</TableCell>
                                <TableCell>{activity.email}</TableCell>
                            </TableRow>
                            {activity.activity_type === 'MOTE_ADJUSTMENT' && <>
                            <TableRow>
                                <TableCell className={classes.colHeader}>Reason</TableCell>
                                <TableCell>{activity.reason}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.colHeader}>Notes</TableCell>
                                <TableCell>{activity.notes}</TableCell>
                            </TableRow>
                            {activity.pgr &&
                            <TableRow>
                                <TableCell className={classes.colHeader}>PGR</TableCell>
                                <TableCell>{activity.pgr}</TableCell>
                            </TableRow>}
                            </>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );

    return (
        <div>
            <TitleBar title="Activity" />
            <div>
                {activities && Object.entries(activities).map(([date, activitiesByDate]) => <div className={classes.root}>
                    <Typography variant="caption">{date}</Typography>
                    {activitiesByDate.map(activity => <>{buildTimeline(activity)}</>)}
                </div>)}
            </div>
        </div>
    );
};

export default connect (state => ({
    activities: state.activityStore.activities,
    isLoading: state.activityStore.isLoading,
    isSuccessful: state.activityStore.isSuccessful,
}), {
    getActivities,
}) (Activity);
