import React, {useContext, useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {getFormula} from "../../../store/RegressionStore";
import AuthContext from "../../../AuthContext";

function Formula({batchId, processIds, dateReported, formula, getFormula}) {
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getFormula(token, batchId);
        };
        if (batchId) {
            callApi();
        }
    }, [batchId, currentUser, getFormula]);

    if (!formula || !batchId || !processIds || !dateReported) {
        return null;
    }

    return (<>
        <Typography variant="body2">
            Batch: <strong>{batchId}</strong>
        </Typography>
        <Typography variant="body2">
            Processes: {processIds && <strong>{processIds.join(' | ')}</strong>}
        </Typography>
        <Typography variant="body2">
            Date Reported: <strong>{dateReported}</strong>
        </Typography>
        {formula && formula.map(f =>
            <Typography variant="body2">
               <strong>{f.type}</strong> - m: <strong>{f.m}</strong>, c: <strong>{f.c}</strong>, r<sup>2</sup>: <strong>{f.r2}</strong>
            </Typography>
        )}</>)
}

export default connect( state => ({
    formula: state.regressionStore.formula
}), {
    getFormula,
}) (Formula);
