import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const MOTE_ASSOCIATIONS_GET = 'MOTE_ASSOCIATIONS_GET';
const MOTE_ASSOCIATIONS_GET_START = 'MOTE_ASSOCIATIONS_GET_START';

const loadMoteAssociations = createAction(MOTE_ASSOCIATIONS_GET);
const startLoadingMoteAssociations = createAction(MOTE_ASSOCIATIONS_GET_START);

const MOTE_ASSOCIATIONS_HISTORY_GET = 'MOTE_ASSOCIATIONS_HISTORY_GET';
const MOTE_ASSOCIATIONS_HISTORY_GET_START = 'MOTE_ASSOCIATIONS_HISTORY_GET_START';

const loadMoteAssociationsHistory = createAction(MOTE_ASSOCIATIONS_HISTORY_GET);
const startLoadingMoteAssociationsHistory = createAction(MOTE_ASSOCIATIONS_HISTORY_GET_START);

const MOTE_ASSOCIATIONS_TRANSFER = 'MOTE_ASSOCIATIONS_TRANSFER';
const MOTE_ASSOCIATIONS_TRANSFER_START = 'MOTE_ASSOCIATIONS_TRANSFER_START';

const loadMoteAssociationsTransfer = createAction(MOTE_ASSOCIATIONS_TRANSFER);
const startLoadingMoteAssociationsTransfer = createAction(MOTE_ASSOCIATIONS_TRANSFER_START);

const MOTE_ASSOCIATIONS_ARCHIVE = 'MOTE_ASSOCIATIONS_ARCHIVE';
const MOTE_ASSOCIATIONS_ARCHIVE_START = 'MOTE_ASSOCIATIONS_ARCHIVE_START';

const loadMoteAssociationsArchive = createAction(MOTE_ASSOCIATIONS_ARCHIVE);
const startLoadingMoteAssociationsArchive = createAction(MOTE_ASSOCIATIONS_ARCHIVE_START);

const MOTE_RENAME = 'MOTE_RENAME';
const MOTE_RENAME_START = 'MOTE_RENAME_START';

const loadRenameMote = createAction(MOTE_RENAME);
const startLoadingMoteRename = createAction(MOTE_RENAME_START);

const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

const showLoader = createAction(SHOW_LOADER);
const hideLoader = createAction(HIDE_LOADER);

// actions

export const getMoteAssociations = (currentUser, farmGroupId, dateReported, farmGroupTimeZone) => {
    const dateReportedLocal = dateReported.setZone(farmGroupTimeZone, { keepLocalTime: true }).toUTC().toString();
    return async dispatch => {
        dispatch(startLoadingMoteAssociations());
        dispatch(showLoader());
        if (!farmGroupId || farmGroupId < 0) {
            dispatch(loadMoteAssociations([]));
            dispatch(hideLoader());
        } else {
            const token = await currentUser.getIdToken(true);
            axios.post(`/associations/farm-group/${farmGroupId}`, {
                date_reported: dateReportedLocal
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                dispatch(loadMoteAssociations(response.data));
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                const error = (err.response && err.response.data && err.response.data.error) ?
                    new Error(err.response.data.error) : err;
                console.log(error);
            })
        }
    };
};

export const getMoteAssociationsHistory = (currentUser, moteId) => {
    return async dispatch => {
        dispatch(startLoadingMoteAssociationsHistory());
        dispatch(showLoader());
        if (!moteId || moteId < 0) {
            dispatch(loadMoteAssociationsHistory([]));
            dispatch(hideLoader());
        } else {
            const token = await currentUser.getIdToken(true);
            axios.get(`/associations/history/mote/${moteId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                dispatch(loadMoteAssociationsHistory(response.data));
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                const error = (err.response && err.response.data && err.response.data.error) ?
                    new Error(err.response.data.error) : err;
                console.log(error);
            })
        }
    };
};

export const renameMote = (currentUser, devEui, moteName, farmGroupId, dateReported, farmGroupTimeZone) => {
    const dateReportedLocal = dateReported.setZone(farmGroupTimeZone, { keepLocalTime: true }).toUTC().toString();
    return async dispatch => {
        dispatch (startLoadingMoteRename ());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.post(`/associations/farm-group/${farmGroupId}/rename`, {
            dev_eui: devEui,
            mote_name: moteName,
            date_reported: dateReportedLocal,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadRenameMote(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const transferMote = (currentUser, farmGroupId, toFarmGroupId, moteId, dateTransferredUtc, dateReportedUtc) => {
    return async dispatch => {
        dispatch (startLoadingMoteAssociationsTransfer ());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.post(`/associations/farm-group/${farmGroupId}/transfer`, {
            to_farm_group_id: toFarmGroupId,
            mote_id: moteId,
            date_transferred: dateTransferredUtc,
            date_reported: dateReportedUtc,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadMoteAssociationsTransfer(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const archiveMote = (currentUser, farmGroupId, moteId, dateArchivedUtc, dateReportedUtc) => {
    return async dispatch => {
        dispatch (startLoadingMoteAssociationsArchive ());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.post(`/associations/farm-group/${farmGroupId}/archive`, {
            mote_id: moteId,
            date_archived: dateArchivedUtc,
            date_reported: dateReportedUtc,
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {

            dispatch(loadMoteAssociationsArchive(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        MOTE_ASSOCIATIONS_GET_START: (state, action) => {
            return {
                ...state,
                associations: [],
                associationsHistory: [],
                isSuccessful: false,
            };
        },
        MOTE_ASSOCIATIONS_GET: (state, action) => {
            return {
                ...state,
                associations: action.payload,
                isSuccessful: true,
            };
        },
        MOTE_ASSOCIATIONS_HISTORY_GET_START: (state, action) => {
            return {
                ...state,
                associationsHistory: [],
                isSuccessful: false,
            };
        },
        MOTE_ASSOCIATIONS_HISTORY_GET: (state, action) => {
            return {
                ...state,
                associationsHistory: action.payload,
                isSuccessful: true,
            };
        },
        MOTE_RENAME_START: (state, action) => {
            return {
                ...state,
                isRenameSuccessful: false,
            };
        },
        MOTE_RENAME: (state, action) => {
            return {
                ...state,
                associations: action.payload,
                isRenameSuccessful: true,
            };
        },
        MOTE_ASSOCIATIONS_TRANSFER_START: (state, action) => {
            return {
                ...state,
                isTransferSuccessful: false,
            };
        },
        MOTE_ASSOCIATIONS_TRANSFER: (state, action) => {
            return {
                ...state,
                associations: action.payload,
                isTransferSuccessful: true,
            };
        },
        MOTE_ASSOCIATIONS_ARCHIVE_START: (state, action) => {
            return {
                ...state,
                isArchiveSuccessful: false,
            };
        },
        MOTE_ASSOCIATIONS_ARCHIVE: (state, action) => {
            return {
                ...state,
                associations: action.payload,
                isArchiveSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
