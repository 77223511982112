import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SingleBatch from "./SingleBatch";
import {firestore} from '../../../base';
import Regression from "../regression/Regression";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: 20,
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1),
        }
    }),
);

export default function Batches() {
    const classes = useStyles();

    const [batches, setBatches] = useState([]);
    useEffect(() => {
        firestore.collection('ndvi-process-batch').onSnapshot(querySnapshot => {
            setBatches(querySnapshot.docs.map(ds => {return {...ds.data(), id: ds.id}}));
        });
    }, []);

    const [showRegression, setShowRegressionInput] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState({});
    const handleShowRegressionClick = (batchId, processIds, dateReported) => {
        setSelectedBatch({batchId, processIds, dateReported});
        setShowRegressionInput(!showRegression);
    }

    return (
        <div className={classes.root}>
            <div style={{ marginBottom: 10 }}>
                <Typography variant="subtitle1">Batches</Typography>
            </div>
            <Paper elevation={3} className={classes.paper}>
                <div>
                    {Object.entries(batches).map(([_, value]) => <>
                    {value.status && !value.status['DELETED'] && <>
                        <SingleBatch
                            batchId={value.id}
                            dateReported={value.date_reported_str}
                            processIds={value.process_ids}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button variant="contained" color="secondary" style={{ fontSize: 12, marginRight: 8 }} onClick={() => handleShowRegressionClick(value.id, value.process_ids, value.date_reported_str)}>Show Regression</Button>
                        </div>
                    </>}
                    </>)}
                    <Regression open={showRegression} onClose={handleShowRegressionClick} batchId={selectedBatch.batchId} processIds={selectedBatch.processIds} dateReported={selectedBatch.dateReported}/>
                </div>
            </Paper>
        </div>
    );
}
