import React from 'react';
import RegressionInputNdviGraph from "./RegressionInputNdviGraph";
import RegressionInputNdreGraph from "./RegressionInputNdreGraph";

function RegressionInputGraph({data}) {

    return (
        <div>
            <RegressionInputNdviGraph data={data}/>
            <RegressionInputNdreGraph data={data}/>
        </div>
    );
}

export default RegressionInputGraph;
