import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TitleBar from '../components/common/TitleBar';
import MotesTabs from '../components/motes/MotesTabs';

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

function Motes() {
    const classes = useStyles();

    return (
        <div>
            <TitleBar title="Motes">
            </TitleBar>
            <div className={classes.root}>
                <MotesTabs/>
            </div>
        </div>
    );
}

export default Motes;
