import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import NoDataMessage from "../../common/NoDataMessage";
import {getSensors} from "../../../store/DashboardStore";
import {AuthContext} from "../../../use-auth";
import {formatStrToDateMed, times} from "../../common/Util";

const useStyles = makeStyles( {
    root: {
        maxWidth: 'fit-content',
        cursor: 'pointer',
    },
    container: {
        maxHeight: 'calc(100vh - 275px)',
        maxWidth: 'fit-content',
    },
    cell: {
        padding: 4,
        width: 40,
        border: 'solid lightgray 0.001rem',
    },
    headerCell: {
        height: 76,
    },
    centerCell: {
        textAlign: 'center',
    },
    rightCell: {
        textAlign: 'right',
        paddingRight: '5px !important',
    },
    lastCell: {
        borderBottom: 'solid darkgray 0.2rem',
    },
    estimate: {
        fontStyle: 'italic',
        backgroundColor: '#FCE9EF',
    },
    focusedRow: {
        backgroundColor: '#FBEB74 !important',
    },
    avg: {
        fontWeight: 'bold',
    },
});

function Sensors({ getSensors, sensors, filters }) {
    const { currentUser } = useContext(AuthContext);
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const emptyRows = sensors ? rowsPerPage - Math.min(rowsPerPage, sensors.length - page * rowsPerPage) : 0;

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getSensors(token, filters.moteId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
        };
        if (filters && filters.moteId) {
            callApi();
        }
    }, [filters, getSensors, currentUser]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!sensors) {
        return <NoDataMessage isSuccessful={false} requiredField="Mote" />;
    }

    const sensorValues = ['first', 'last', 'min', 'max'];

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small" align={"center"} aria-label="sticky sensors table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Reported Date</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Sensors</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>1</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>2</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>3</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>4</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>5</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>6</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Average</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Fitted Gross</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Current PGR</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Adjustment</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Pasture Event</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Zone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sensors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(s => <>
                                {sensorValues.map((sv, j) =>
                                <TableRow onClick={() => setSelectedRow({row: j, date: s.date_reported})}
                                          selected={j === selectedRow.row && s.date_reported === selectedRow.date}
                                          classes={{selected: classes.focusedRow}}>
                                    {j === 0 && <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} rowSpan={4}>{formatStrToDateMed(s.date_reported, filters.timeZone)}</TableCell>}
                                    <TableCell className={clsx(classes.cell, j === sensorValues.length - 1 && classes.lastCell, classes.centerCell)}>{sv}</TableCell>
                                    {times(6, h =>
                                        <TableCell className={clsx(classes.cell, j === sensorValues.length - 1 && classes.lastCell, classes.rightCell)}>{s[sv].heights[h+1]}</TableCell>
                                    )}
                                    <TableCell className={clsx(classes.cell, j === sensorValues.length - 1 && classes.lastCell, classes.rightCell, classes.avg)}>{s[sv].average}</TableCell>
                                    {j === 0 && <>
                                        <TableCell className={clsx(classes.cell, classes.lastCell, classes.rightCell)} rowSpan={4}>{s.net?.fitted_gross}</TableCell>
                                        <TableCell className={clsx(classes.cell, classes.lastCell, classes.rightCell)} rowSpan={4}>{s.net?.post_grazing_residual}</TableCell>
                                        <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} rowSpan={4}>{s.adjustments.map(a => a.adjustment ?
                                            `${a.adjustment} - ${formatStrToDateMed(a.adjustment_date, filters.timeZone)}` : '')}</TableCell>
                                        <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} rowSpan={4}>{s.net?.pasture_event}</TableCell>
                                        <TableCell className={clsx(classes.cell, classes.lastCell, classes.centerCell)} rowSpan={4}>{`${s.mapping?.farm_name} / ${s.mapping?.zone_name}`}</TableCell>
                                    </>}
                                </TableRow>)}
                                </>
                            )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 24.5 * emptyRows * 4 }}>
                                <TableCell />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sensors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default connect (state => ({
    sensors: state.dashboardStore.sensors,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getSensors,
}) (Sensors);
