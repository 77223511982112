import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {connect} from "react-redux";
import {add} from "../../../store/GroupsStore";
import {AuthContext} from "../../../use-auth";
import {Checkbox} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    content: {
        padding: 0,
    },
}));

function GroupAddDialog({farmId, add, onAdd}) {
    const classes = useStyles();

    const { currentUser } = useContext(AuthContext);

    const [groupName, setGroupName] = React.useState('');
    const handleGroupNameChange = (e) => {
      setGroupName(e.target.value);
    };

    const [isCuttingGroup, setIsCuttingGroup] = React.useState(false);

    const handleGroupNameAdd = async () => {
        const token = await currentUser.getIdToken(true);
        add(token, farmId, groupName, isCuttingGroup);
        setGroupName('');
        onAdd();
    };

    return (
        <div>
            <form className={classes.container}>
                <FormControl className={classes.formControl}>
                    <TextField label="Group Name" variant="outlined" onChange={handleGroupNameChange} value={groupName} />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <FormControlLabel
                        control={<Checkbox checked={isCuttingGroup} onChange={ (event) => setIsCuttingGroup(event.target.checked)} />}
                        label="Cutting Group"
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <Fab color="primary" size="small" aria-label="move" onClick={handleGroupNameAdd}>
                        <AddIcon />
                    </Fab>
                </FormControl>
            </form>
        </div>
    );
}

export default connect (state => ({
    isLoading: state.groupsStore.isLoading,
    isSuccessful: state.groupsStore.isSuccessful,
}), {
    add,
}) (GroupAddDialog);
