import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {transformToNdviProcess} from "./ProcessBatchUtil";
import {firestore} from "../../../base";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

export default function SingleProcessInBatch({processId}) {
    const classes = useStyles();

    const [process, setProcess] = useState();
    useEffect(() => {
        firestore.collection('ndvi-process-tracking').doc(processId).onSnapshot(docSnapshot => {
            const processData = {...docSnapshot.data(), id: docSnapshot.id};
            if (processData) {
                setProcess(transformToNdviProcess(processData));
            }
        });
    }, [processId])

    if (!process) {
        return <></>;
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={1}
                justify="space-evenly"
                alignItems="center"
            >
                <Grid item xs={10} md>{process.scene.datetimeImage}</Grid>
                <Grid item xs={12} md>{process.scene.sceneId}</Grid>
                <Grid item xs={12} md>{process.farmName}</Grid>
                <Grid item xs>{processId}</Grid>
                <Grid item xs>{process.orderId}</Grid>
            </Grid>
        </div>
    );
}
