import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import {DateTime} from "luxon";

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

const ACTIVITIES_GET = 'ACTIVITIES_GET';
const loadActivities = createAction(ACTIVITIES_GET);
const ACTIVITIES_GET_START = 'ACTIVITIES_GET_START';
const startLoadingActivities = createAction(ACTIVITIES_GET_START);

// actions
export const getActivities = (token) => {
    return dispatch => {
        dispatch (showLoader());
        dispatch(startLoadingActivities());
        axios.get(`/user-activities`,
        { headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch(loadActivities(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

const transformActivities = (activities) => {
    const breakDateAndTime = (dateTime) => {
        const dateTimeIso = DateTime.fromISO(dateTime);
        return {
            date: `${dateTimeIso.toRelativeCalendar()} | ${dateTimeIso.toLocaleString(DateTime.DATE_HUGE)}`,
            time: dateTimeIso.toLocaleString(DateTime.TIME_WITH_SECONDS)
        };
    };
  return _(activities)
      .map(a => {return {...a,...breakDateAndTime(a.created_at)}})
      .groupBy('date')
      .value();
};

// reducer
export default handleActions(
    {
        ACTIVITIES_GET_START: (state) => {
            return {
                ...state,
                activities: [],
            };
        },
        ACTIVITIES_GET: (state, action) => {
            return {
                ...state,
                activities: transformActivities(action.payload),
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
