import React, { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root: {
        margin: 20,
    },
}));

function FeedWedge({zoneGroup, data}) {

    const classes = useStyles();
    const [chartData, setChartData] = useState({bar: {x:[], y: [], width: []}, line: {x:[], y:[]}});
    const Plot = window.createPlotlyComponent(window.Plotly);

    useEffect(() => {
        let totalArea = 0;
        const barXs = [];
        data.forEach(d => {
            const x = totalArea + (d.area / 2);
            totalArea += d.area;
            barXs.push(x);
        });
        const targets = data.map(d => d.target);
        const zones = data.map(d => d.zone);
        setChartData({
            bar: {x: barXs, y: data.map(d => d.cover), text: zones, width: data.map(d => d.area)},
            line: {x: [barXs[0], barXs[barXs.length-1]], y: [targets[0],targets[targets.length-1]], text: [zones[0],zones[zones.length-1]]},
        });
    }, [data]);

    return (
        <div className={classes.root}>
           <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'target',
                        x: chartData.line.x,
                        y: chartData.line.y,
                        text: chartData.line.text,
                        textposition: 'auto',
                        hoverinfo: 'none',
                        marker: {color: '#086105'},
                    },
                    {
                        type: 'bar',
                        name: 'cover',
                        x: chartData.bar.x,
                        y: chartData.bar.y,
                        text: chartData.bar.text,
                        textposition: 'auto',
                        insidetextanchor: 'start',
                        width: chartData.bar.width,
                        hoverinfo: 'text',
                        marker: {color: '#38A756', line: {
                            color: '#ffffff',
                            width: 1
                        }},
                    },
                ]}
                layout={ {title: `Paddock Group: ${zoneGroup}`, showlegend: false, xaxis: {visible: false}, yaxis: {title: 'Cover (kgDM/ha)'} } }
            />
        </div>
    );
}

export default FeedWedge;
