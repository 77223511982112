import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RegressionInputGraph from "./RegressionInputGraph";
import RegressionInputTable from "./RegressionInputTable";
import ZoneHapgrTable from "./ZoneHapgrTable";
import ZoneHapgrGraph from "./ZoneHapgrGraph";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function RegressionTabs({batchId, regressionInput, zoneHapgrs}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Input" {...a11yProps(0)} />
                <Tab label="Graph" {...a11yProps(1)} />
                <Tab label="Zone HAPGRs" {...a11yProps(2)} />
                <Tab label="Zone HAPGRs Graph" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <RegressionInputTable rows={regressionInput} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RegressionInputGraph data={regressionInput} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ZoneHapgrTable batchId={batchId} rows={zoneHapgrs}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ZoneHapgrGraph data={zoneHapgrs}/>
            </TabPanel>
        </div>
    );
}
