import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {connect} from "react-redux";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import {downloadZoneHapgrs} from "../../../store/RegressionStore";
import AuthContext from "../../../AuthContext";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function ZoneHapgrTable({batchId, rows}) {
    const classes = useStyles();

    const { currentUser } = useContext(AuthContext);

    const handleDownload = async () => {
        const token = await currentUser.getIdToken(true);
        downloadZoneHapgrs(token, batchId)
    }

    return (
        <TableContainer component={Paper}>
            <div>
                <IconButton aria-label="download" className={classes.button} onClick={handleDownload}>
                    <DownloadIcon fontSize="inherit" />
                </IconButton>
                Download CSV
            </div>
            <Table className={classes.table} aria-label="Zone HAPGR table">
                <TableHead>
                    <TableRow>
                        <TableCell>Farm</TableCell>
                        <TableCell>Zone</TableCell>
                        <TableCell>HAPGR Mean from NDVI</TableCell>
                        <TableCell>Zone NDVI</TableCell>
                        <TableCell>HAPGR Mean from NDRE</TableCell>
                        <TableCell>Zone NDRE</TableCell>
                        <TableCell>Processed Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows && rows.map((zh) => (
                        <TableRow key={zh.zone_name}>
                            <TableCell component="th" scope="row">
                                {zh.farm_name}
                            </TableCell>
                            <TableCell>{zh.zone_name}</TableCell>
                            <TableCell>{zh.hapgr_mean_from_ndvi}</TableCell>
                            <TableCell>{zh.zone_ndvi}</TableCell>
                            <TableCell>{zh.hapgr_mean_from_ndre}</TableCell>
                            <TableCell>{zh.zone_ndre}</TableCell>
                            <TableCell>{zh.datetime_processed}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default connect( state => ({
    isLoading: state.regressionStore.isLoading,
}), {
    downloadZoneHapgrs,
}) (ZoneHapgrTable);
