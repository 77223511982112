import React, { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root: {
        margin: 20,
    },
}));

function RegressionInputNdviGraph({data}) {

    const classes = useStyles();
    const [chartData, setChartData] = useState({scatter: {x1:[], y1: [], x2:[], y2:[], text: "Regression Input"}});
    const Plot = window.createPlotlyComponent(window.Plotly);

    useEffect(() => {
        const x1s = data.filter(d => !d.excluded).map(d => d.mean_mote_ndvi);
        const y1s = data.filter(d => !d.excluded).map(d => d.hapgr);
        const x2s = data.filter(d => d.excluded).map(d => d.mean_mote_ndvi);
        const y2s = data.filter(d => d.excluded).map(d => d.hapgr);
        setChartData({
            scatter: {x1: x1s, y1: y1s, x2: x2s, y2: y2s},
        });
    }, [data]);

    return (
        <div className={classes.root}>
            <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Included',
                        x: chartData.scatter.x1,
                        y: chartData.scatter.y1,
                        trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                    },
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Excluded',
                        x: chartData.scatter.x2,
                        y: chartData.scatter.y2,
                        trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                    }
                ]}
                layout={ {title: `Regression Input`, showlegend: false, xaxis: {title: 'Mean Mote NDVI'}, yaxis: {title: 'HAPGR'} } }
            />
        </div>
    );
}

export default RegressionInputNdviGraph;
