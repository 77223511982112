import React, {useContext} from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import SingleProcessInBatch from "./SingleProcessInBatch";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {deleteBatch} from "../../../store/RegressionStore";
import AuthContext from "../../../AuthContext";
import Button from "@material-ui/core/Button";

function SingleBatch({batchId, dateReported, processIds, deleteBatch}) {
    const { currentUser } = useContext(AuthContext);

    const handleDeleteBatch = async () => {
        const token = await currentUser.getIdToken(true);
        deleteBatch(token, batchId);
    }

    return ( <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
            >
                <Box textAlign="center">
                    Batch ID: <strong>{batchId}</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                    Date Reported: <strong>{dateReported}</strong>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                    spacing={1}
                    justify="space-evenly"
                    alignItems="center"
                >
                {processIds && processIds.map(processId =>
                    <Grid item xs={12}><SingleProcessInBatch processId={processId}/></Grid>
                )}
                <Button variant="contained" onClick={handleDeleteBatch}>Delete</Button>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>);
}

export default connect( state => ({
    isLoading: state.regressionStore.isLoading,
}), {
    deleteBatch
}) (SingleBatch);
