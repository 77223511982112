import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Orange from '@material-ui/core/colors/orange';
import Blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Popover from "@material-ui/core/Popover";
import { withRouter } from 'react-router';
import {AuthContext} from "../../use-auth";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    header: {
        textAlign: 'center',
        backgroundColor: Orange["50"],
        padding: theme.spacing(1),
    },
    details: {
        margin: theme.spacing(1),
    },
    avatar: {
      width: 80,
      height: 80,
    },
    cardContent: {
        padding: theme.spacing(1),
    },
    link: {
        margin: theme.spacing(1),
        color: Blue.A400,
        cursor: 'pointer',
    },
    button: {
        marginLeft: 'auto',
        textTransform: 'none',
    },
    cardActions: {
        padding: 4,
    },
    popover: {
        marginTop: 12,
        marginLeft: 12,
    }
}));

const Profile = (props) => {
    const classes = useStyles();
    const { isMenuOpen, anchorEl, onMenuClose, history } = props;
    const {currentUser, isAdmin, farmGroupName, signOut} = useContext(AuthContext);

    function handleSignout() {
        signOut(history);
    }

    const content = (
        <Card className={classes.root}>
            {isAdmin &&
                <div className={classes.header}>
                    This account has <strong>admin</strong> privileges
                </div>
            }
            <Grid container spacing={0} className={classes.details}>
                <Grid item>
                    <Avatar
                        alt="profile pic"
                        src={currentUser.photoURL}
                        className={classes.avatar}
                    />
                </Grid>
                <Grid item>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="body1"><strong>{currentUser.displayName}</strong></Typography>
                        <Typography variant="caption" display="block">{currentUser.email}</Typography>
                        <Typography variant="button">{farmGroupName}</Typography>
                    </CardContent>
                    {isAdmin && <Link className={classes.link}>Impersonate</Link>}
                </Grid>
            </Grid>
            <Divider/>
            <CardActions className={classes.cardActions}>
                {isAdmin && <Link className={classes.link}>My Account</Link>}
                <Button size="small" variant="outlined" className={classes.button}
                        onClick={handleSignout}
                >
                    Sign out
                </Button>
            </CardActions>
        </Card>
    );
    return (
        <Popover
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={onMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className={classes.popover}
        >
            {content}
        </Popover>
    );
};

export default withRouter(Profile);
