import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import {AuthContext} from "../../use-auth";
import ProfileBox from '../profile/ProfileBox';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    toolBar: {
      paddingLeft: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function NavBar(props) {
    const classes = useStyles();
    const {currentUser} = useContext(AuthContext);
    const { toggleDrawerOpen, toggleMobileDrawerOpen } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const profileId = isMenuOpen ? 'login-avatar' : undefined;

    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    const menuIcon = (handleMenuClick) => (
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={handleMenuClick}
        >
            <MenuIcon />
        </IconButton>
    );

    return (
        <div className={classes.grow}>
            <CssBaseline />
            <AppBar position="fixed"
                    elevation={0}
                    className={classes.appBar}
            >
                <Toolbar className={classes.toolBar}>
                    <Hidden smUp implementation="css">
                        {menuIcon(toggleMobileDrawerOpen)}
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        {menuIcon(toggleDrawerOpen)}
                    </Hidden>
                    <Typography variant="h6" noWrap>
                        Farmote Portal
                    </Typography>
                    <div className={classes.grow} />
                    {currentUser &&
                        <>
                            <Avatar
                                aria-describedby={profileId}
                                alt="profile pic"
                                src={currentUser.photoURL}
                                className={classes.avatar}
                                onClick={handleProfileMenuOpen}
                            />
                            <ProfileBox
                                user={currentUser}
                                isMenuOpen={isMenuOpen}
                                anchorEl={anchorEl}
                                onMenuClose={handleMenuClose}
                            />
                        </>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
