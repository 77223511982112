import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import DashboardStore from './DashboardStore';
import FilterStore from './FilterStore';
import EventsStore from './EventsStore';
import AssociationsStore from './AssociationsStore';
import MappingsStore from './MappingsStore';
import PaddockActionsStore from './PaddockActionsStore';
import GrazingStore from './GrazingStore';
import ReportStore from './ReportStore';
import GroupsStore from './GroupsStore';
import UsersStore from './UsersStore';
import FarmGroupsStore from './FarmGroupsStore';
import FarmStatusStore from './FarmStatusStore';
import ActivityStore from './ActivityStore';
import ScenesStore from "./ScenesStore";
import RegressionStore from "./RegressionStore";

let rootReducer = combineReducers({
    dashboardStore: DashboardStore,
    activityStore: ActivityStore,
    filterStore: FilterStore,
    eventsStore: EventsStore,
    associationsStore: AssociationsStore,
    mappingsStore: MappingsStore,
    paddockActionsStore: PaddockActionsStore,
    grazingStore: GrazingStore,
    reportStore: ReportStore,
    groupsStore: GroupsStore,
    usersStore: UsersStore,
    farmGroupsStore: FarmGroupsStore,
    farmStatusStore: FarmStatusStore,
    scenesStore: ScenesStore,
    regressionStore: RegressionStore
});

const loggerMiddleware = createLogger();

export default function configureStore(preloadedState) {
    return createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    );
};
