import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const FARM_GROUP_SELECT = 'FARM_GROUP_SELECT';
const loadSetSelectedFarmGroup = createAction(FARM_GROUP_SELECT);

const FARM_GROUPS_GET_ALL = 'FARM_GROUPS_GET_ALL';
const FARM_GROUPS_GET_ALL_START = 'FARM_GROUPS_GET_ALL_START';

const loadAllFarmGroups = createAction(FARM_GROUPS_GET_ALL);
const startLoadingAllFarmGroups = createAction(FARM_GROUPS_GET_ALL_START);

const FARM_GROUP_EDIT = 'FARM_GROUP_EDIT';
const FARM_GROUP_EDIT_START = 'FARM_GROUP_EDIT_START';

const loadEditFarmGroup = createAction(FARM_GROUP_EDIT);
const startLoadingEditFarmGroup = createAction(FARM_GROUP_EDIT_START);

const FARM_GROUP_ADD = 'FARM_GROUP_ADD';
const FARM_GROUP_ADD_START = 'FARM_GROUP_ADD_START';

const loadAddFarmGroup = createAction(FARM_GROUP_ADD);
const startLoadingAddFarmGroup = createAction(FARM_GROUP_ADD_START);

const SHOW_LOADER = 'SHOW_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

const showLoader = createAction(SHOW_LOADER);
const hideLoader = createAction(HIDE_LOADER);

// actions
export const setSelectedFarmGroup = (farmGroup) => {
    return async dispatch => {
        dispatch(loadSetSelectedFarmGroup(farmGroup));
    };
};

export const getAllFarmGroups = (currentUser) => {
    return async dispatch => {
        dispatch (startLoadingAllFarmGroups());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.get(`/farm-groups`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadAllFarmGroups(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const editFarmGroup = (currentUser, farmGroupId, newFarmGroupName, newFarmGroupTimeZone) => {
    return async dispatch => {
        dispatch (startLoadingEditFarmGroup());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.post(`/farm-groups/${farmGroupId}/rename`, {
            new_farm_group_name: newFarmGroupName,
            new_farm_group_time_zone: newFarmGroupTimeZone
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadEditFarmGroup(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const addFarmGroup = (currentUser, newFarmGroupName, newFarmGroupTimeZone) => {
    return async dispatch => {
        dispatch (startLoadingAddFarmGroup());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.post(`/farm-groups/add`, {
            new_farm_group_name: newFarmGroupName,
            new_farm_group_time_zone: newFarmGroupTimeZone
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadAddFarmGroup(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        FARM_GROUP_SELECT: (state, action) => {
            return {
                ...state,
                selectedFarmGroup: action.payload,
            };
        },
        FARM_GROUPS_GET_ALL_START: (state, action) => {
            return {
                ...state,
                farmGroups: [],
                isSuccessful: false,
            };
        },
        FARM_GROUPS_GET_ALL: (state, action) => {
            return {
                ...state,
                farmGroups: action.payload,
                selectedFarmGroup: {},
                isSuccessful: true,
            };
        },
        FARM_GROUP_EDIT_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        FARM_GROUP_EDIT: (state, action) => {
            return {
                ...state,
                farmGroups: action.payload,
                selectedFarmGroup: {},
                isSuccessful: true,
            };
        },
        FARM_GROUP_ADD_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        FARM_GROUP_ADD: (state, action) => {
            return {
                ...state,
                farmGroups: action.payload,
                selectedFarmGroup: {},
                isSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
