import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import NoDataMessage from "../../common/NoDataMessage";
import {getMoteAssociations, renameMote, archiveMote, transferMote} from "../../../store/AssociationsStore";
import {AuthContext} from "../../../use-auth";
import MoteArchiveDialog from './MoteArchiveDialog';
import MoteTransferDialog from "./MoteTransferDialog";
import AssociationsHistory from "./AssociationsHistory";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2),
    },
    container: {
        cursor: 'pointer',
        maxHeight: '60vh',
        maxWidth: 'fit-content',
    },
    cell: {
        padding: 4,
        border: 'dotted 0.001rem',
        textAlign: 'center',
    },
}));

function AssociationsTab({selectedFarmGroup, selectedDateReported, getMoteAssociations, associations, renameMote, archiveMote, transferMote, isLoading}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);
    const [selectedMoteId, setSelectedMoteid] = useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        if (selectedFarmGroup.farm_group_id) {
            getMoteAssociations(currentUser, selectedFarmGroup.farm_group_id, selectedDateReported, selectedFarmGroup.farm_group_time_zone);
        }
    }, [currentUser, selectedFarmGroup, selectedDateReported, getMoteAssociations]);

    // const handleMoteRename = (moteId, newMoteName) => {
    //     renameMote(currentUser, moteId, newMoteName, selectedFarmGroup.farm_group_id, selectedDateReported, selectedFarmGroup.farm_group_time_zone);
    // };

    const [transferRowData, setTransferRowData] = React.useState({});
    const handleTransfer = (moteId, toFarmGroup, dateTransferred) => {
        const dateTransferredUtc = dateTransferred.setZone(toFarmGroup.farm_group_time_zone, { keepLocalTime: true }).toUTC().toString();
        const dateReportedUtc = selectedDateReported.setZone(selectedFarmGroup.farm_group_time_zone, { keepLocalTime: true }).toUTC().toString();
        transferMote(currentUser, selectedFarmGroup.farm_group_id, toFarmGroup.farm_group_id, moteId, dateTransferredUtc, dateReportedUtc);
        setTransferRowData({});
    };

    const [deleteRowData, setDeleteRowData] = React.useState({});
    const handleArchive = (moteId, dateArchived) => {
        const dateArchivedUtc = dateArchived.setZone(selectedFarmGroup.farm_group_time_zone, { keepLocalTime: true }).toUTC().toString();
        const dateReportedUtc = selectedDateReported.setZone(selectedFarmGroup.farm_group_time_zone, { keepLocalTime: true }).toUTC().toString();
        archiveMote(currentUser, selectedFarmGroup.farm_group_id, moteId, dateArchivedUtc, dateReportedUtc);
        setDeleteRowData({});
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!associations) {
        return <NoDataMessage isSuccessful={false} requiredField="Farm Group" />;
    }

    return (
        <>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader size="small" align={"center"} aria-label="sticky sensors table">
                      <TableHead>
                          <TableRow>
                              <TableCell className={classes.cell}>Mote Name</TableCell>
                              <TableCell className={classes.cell}>Dev EUI</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {associations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map(a => <TableRow onClick={() => setSelectedMoteid(a.mote_id)}
                                                  selected={a.mote_id === selectedMoteId}>
                                  <TableCell className={classes.cell}>{a.mote_name}</TableCell>
                                  <TableCell className={classes.cell}>{a.dev_eui}</TableCell>
                              </TableRow>)}
                      </TableBody>
                  </Table>
                </TableContainer>
                <AssociationsHistory moteId={selectedMoteId} />
            </Paper>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={associations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <MoteArchiveDialog
              moteName={deleteRowData.mote_name}
              open={deleteRowData.mote_name}
              onClose={() => setDeleteRowData({})}
              onArchive={(dateArchived) => handleArchive(deleteRowData.mote_id, dateArchived)}
            />
            <MoteTransferDialog
              moteName={transferRowData.mote_name}
              farmGruoupTimeZone={selectedFarmGroup ? selectedFarmGroup.farm_group_time_zone : ''}
              open={transferRowData.mote_name}
              onClose={() => setTransferRowData({})}
              onTransfer={(toFarmGroup, dateTransferred) => handleTransfer(transferRowData.mote_id, toFarmGroup, dateTransferred)}
            />
      </>
    );
}

export default connect (state => ({
    associations: state.associationsStore.associations,
    isLoading: state.associationsStore.isLoading,
    isSuccessful: state.associationsStore.isSuccessful,
}), {
    getMoteAssociations,
    renameMote,
    archiveMote,
    transferMote,
}) (AssociationsTab);
