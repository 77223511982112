import React, {useContext, useEffect} from 'react';
import {connect} from "react-redux";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {green} from "@material-ui/core/colors";
import {getMoteMappings,
    addFarm, addPaddock, addZone, mapMote,
    renameFarm, renamePaddock, renameZone,
    archiveFarm, archivePaddock, archiveZone, unmapMote
} from "../../../store/MappingsStore";
import {AuthContext} from "../../../use-auth";
// import OrganizationChart from "@dabeng/react-orgchart";
// import _ from 'lodash';
// import OrgTemplate from './OrgTemplate';
// import Pluralize from 'pluralize';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    fullWidth: {
        width: '100%',
    },
    widthWithoutDrawer: {
        width: 'calc(100% - 300px)',
        padding: 1,
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        backgroundColor: '#eeeeee',
        padding: theme.spacing(2),
        overflowY: 'scroll',
        height: '100vh',
    },
    card: {
        margin: theme.spacing(1),
    },
    list: {
        padding: 0,
    },
    selectedListItem: {
        backgroundColor: '#eeeeee',
    },
    dropDown: {
        paddingBottom: theme.spacing(2),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
}));

function Mappings({ selectedFarmGroup, selectedDateReported,
                      addFarm, addPaddock, addZone, mapMote,
                      renameFarm, renamePaddock, renameZone, renameMote,
                      archiveFarm, archivePaddock, archiveZone, unmapMote,
                      getMoteMappings, mappings
                  }) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getMoteMappings(token, selectedFarmGroup.farm_group_id, selectedDateReported);
        };
        callApi();
    }, [getMoteMappings, selectedFarmGroup, selectedDateReported, currentUser]);

    // const transitionDuration = {
    //     enter: theme.transitions.duration.enteringScreen,
    //     exit: theme.transitions.duration.leavingScreen,
    // };
    // const [showFilters, setShowFilters] = React.useState(false);
    // const [entity, setEntity] = React.useState({});
    // function handleEntityEditSelect(entity) {
    //     setShowFilters(true);
    //     setEntity(entity);
    // }
    //
    // const [showEditTree, setShowEditTree] = React.useState(false);
    // function handleEditClick() {
    //     setShowEditTree(!showEditTree);
    //     if(showEditTree) {
    //         setShowFilters(false);
    //     }
    // }
    //
    // async function handleEntityEditSave(entity) {
    //     setShowFilters(false);
    //     const token = await currentUser.getIdToken(true);
    //     switch (entity.prefix) {
    //         case "F" : renameFarm(token, entity.id, entity.primaryText, entity.secondaryText, entity.thirdText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "P" : renamePaddock(token, entity.id, entity.primaryText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "Z" : renameZone(token, entity.id, entity.primaryText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         default: break;
    //     }
    //     getMoteMappings(token, selectedFarmGroup.farm_group_id, selectedDateReported);
    // }
    //
    // async function handleEntityArchiveSave(entity) {
    //     setShowFilters(false);
    //     const token = await currentUser.getIdToken(true);
    //     switch (entity.prefix) {
    //         case "F" : archiveFarm(token, entity.id, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "P" : archivePaddock(token, entity.id, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "Z" : archiveZone(token, entity.id, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "M" : unmapMote(token, entity.id, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         default: break;
    //     }
    // }
    //
    // async function handleChildEntityCreateSave(entity) {
    //     setShowFilters(false);
    //     const token = await currentUser.getIdToken(true);
    //    switch (entity.prefix) {
    //         case "FG" : addFarm(token, entity.id, entity.addChildPrimaryText, entity.addChildSecondaryText, entity.addChildThirdText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "F" : addPaddock(token, entity.id, entity.addChildPrimaryText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "P" : addZone(token, entity.id, entity.addChildPrimaryText, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         case "Z" : mapMote(token, entity.id, entity.mappedMote.value, selectedFarmGroup.farm_group_id, selectedDateReported); break;
    //         default: break;
    //     }
    // }

    // const getChartData = () => {
    //     const farmGroup = {};
    //     _.forEach(mappings, m => {
    //         const farms = [];
    //         _.forEach(m.farms, f => {
    //             const paddocks = [];
    //             _.forEach(f.paddocks, p => {
    //                 const zones = [];
    //                 _.forEach(p.zones, z => {
    //                     const motes = [];
    //                     _.forEach(z.motes, m => {
    //                         motes.push({
    //                             id: m.mote_id,
    //                             title: m.mote_name,
    //                         });
    //                     });
    //                     zones.push({
    //                         id: z.zone_id,
    //                         title: z.zone_name,
    //                         name: Pluralize('Mote', motes.length, true),
    //                         children: motes,
    //                     });
    //                 });
    //                 paddocks.push({
    //                     id: p.paddock_id,
    //                     title: p.paddock_name,
    //                     name: Pluralize('Zone', zones.length, true),
    //                     children: zones,
    //                 });
    //             });
    //             farms.push({
    //                 id: f.farm_id,
    //                 title: f.farm_name,
    //                 name: Pluralize('Paddock', paddocks.length, true),
    //                 children: paddocks,
    //                 collapsed: true,
    //                 className: 'slide-up',
    //             })
    //         });
    //         farmGroup.id = m.farm_group_id;
    //         farmGroup.title = m.farm_group_name;
    //         farmGroup.name = Pluralize('Farm', farms.length, true);
    //         farmGroup.children = farms;
    //         farmGroup.collapsed = true;
    //         farmGroup.className = 'slide-up';
    //     });
    //     return farmGroup;
    // };

    return (
        <div className={isDesktop ? classes.root : ''}>
            {/*<OrganizationChart datasource={getChartData()} chartClass="myChart" NodeTemplate={OrgTemplate} />*/}
        </div>
    );
}

export default connect (state => ({
    mappings: state.mappingsStore.mappings,
    selectedFarmGroupMapping: state.mappingsStore.selectedFarmGroupMapping,
    isLoading: state.mappingsStore.isLoading,
    isSuccessful: state.mappingsStore.isSuccessful,
}), {
    getMoteMappings,
    addFarm,
    renameFarm,
    archiveFarm,
    addPaddock,
    renamePaddock,
    archivePaddock,
    addZone,
    renameZone,
    archiveZone,
    mapMote,
    unmapMote
}) (Mappings);



