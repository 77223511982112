import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import {DateTime} from "luxon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import _ from 'lodash';
import {getZoneHapgrs} from "../../../store/DashboardStore";
// import FeedWedge from '../FeedWedge';
import {AuthContext} from "../../../use-auth";
import NoDataMessage from "../../common/NoDataMessage";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'table',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        margin: 20,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    table: {
        minWidth: 200,
    },
}));

function MoteDM(props) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const { getZoneHapgrs, filters, zones, isLoading, isSuccessful } = props;
    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getZoneHapgrs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone, filters.targetPostGrazingResidual);
        };
        if (filters && filters.farmId) {
            callApi();
        }
    }, [filters, getZoneHapgrs, currentUser]);

    // const getPaddockCovers = () => {
    //     if (!zones || zones.length === 0) {
    //         return [];
    //     }
    //     console.log(filters);
    //     const increment = (filters.targetPreGrazingResidual - filters.targetPostGrazingResidual) / (zones[0].growths.length - 1);
    //     const paddockCovers = [];
    //     _(zones[0].growths)
    //         .each((g, index) => paddockCovers.push([g.zone_name, filters.targetPreGrazingResidual - (increment * index), g.pph_dm]));
    //     return paddockCovers;
    // };

    return  (
        <>
            {zones && zones.length > 0 &&
                <>
                    <div>
                        <GridList className={classes.gridList} cols={2.5}>
                            {zones.map(z => (
                                <Paper className={classes.root} key={z.date}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant='h6' align='center'>
                                                        {DateTime.fromISO(z.date).setZone('utc').setZone(filters.timeZone).toLocaleString(DateTime.DATE_HUGE)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">(kg DM/ha)</TableCell>
                                                <TableCell align="right">Cover DM</TableCell>
                                                <TableCell align="right">Available Cover DM</TableCell>
                                                <TableCell align="right">Daily Growth</TableCell>
                                                <TableCell align="right">Last 3 Days Growth</TableCell>
                                                <TableCell align="right">Last 7 Days Growth</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="right">Averages</TableCell>
                                                <TableCell align="right">{z.average.pph_dm}</TableCell>
                                                <TableCell align="right">{z.average.available_dry_matter}</TableCell>
                                                <TableCell align="right">{z.average.growth_1_day}</TableCell>
                                                <TableCell align="right">{z.average.growth_3_days}</TableCell>
                                                <TableCell align="right">{z.average.growth_7_days}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {z.growths.map(g => (
                                                <TableRow key={g.zone_name}>
                                                    <TableCell align="right">{g.zone_name}</TableCell>
                                                    <TableCell align="right">{g.pph_dm}</TableCell>
                                                    <TableCell align="right">{g.available_dry_matter}</TableCell>
                                                    <TableCell align="right">{g.growth_1_day}</TableCell>
                                                    <TableCell align="right">{g.growth_3_days}</TableCell>
                                                    <TableCell align="right">{g.growth_7_days}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            ))}
                        </GridList>
                    </div>
                    {/*<FeedWedge data={getPaddockCovers()}/>*/}
                </>
            }
            {!isLoading && (!zones || zones.length === 0) && <NoDataMessage isSuccessful={isSuccessful} requiredField="Farm" />}
        </>
    )
}

export default connect (state => ({
    zones: state.dashboardStore.zones,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getZoneHapgrs,
}) (MoteDM);
