import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Associations from './associations/AssociationsTab';
import Mappings from './mappings/Mappings';
import FarmGroupActions from "./associations/FarmGroupActions";
import FarmGroupSearch from "./FarmGroupSearch";

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        padding: theme.spacing(4),
    },
    datePicker: {
        maxWidth: 260,
        whiteSpace: 'pre-line',
    },
}));

export default function MotesTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    const [isSearchClicked, setIsSearchClicked] = React.useState(false);
    const [filters, setFilters] = React.useState({});
    const handleSearch = (selectedFarmGroup, selectedDateReported) => {
        setIsSearchClicked(true);
        setFilters({selectedFarmGroup, selectedDateReported});
    };

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="static" color="default" className={classes.appBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Associations" />
                    <Tab label="Mappings" />
                </Tabs>
            </AppBar>
            <div className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item>
                        <FarmGroupSearch onSearchClick={handleSearch} />
                    </Grid>
                    {value === 0 && <Grid item>
                        <FarmGroupActions
                            selectedFarmGroup={filters.selectedFarmGroup}
                            onEditSave={() => setFilters({})}
                        />
                    </Grid>}
                </Grid>
                {isSearchClicked && <>
                    {value === 0 &&
                        <TabContainer>
                            <Associations
                                selectedFarmGroup={filters.selectedFarmGroup}
                                selectedDateReported={filters.selectedDateReported}
                                setSelectedFarmGroup={(fg) => setFilters({fg, selectedDateReported: filters.selectedDateReported})}
                            />
                        </TabContainer>}
                    {value === 1 &&
                        <TabContainer>
                            <Mappings
                                selectedFarmGroup={filters.selectedFarmGroup}
                                selectedDateReported={filters.selectedDateReported}
                            />
                        </TabContainer>}
                </>}
            </div>
        </div>
    );
}
