import React from 'react';
import ZoneHapgrFromNdviGraph from "./ZoneHapgrFromNdviGraph";
import ZoneHapgrFromNdreGraph from "./ZoneHapgrFromNdreGraph";

function ZoneHapgrGraph({data}) {
    return (
        <div>
            <ZoneHapgrFromNdviGraph data={data}/>
            <ZoneHapgrFromNdreGraph data={data}/>
        </div>
    );
}

export default ZoneHapgrGraph;
