import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const ZONES_GET = 'ZONES_GET';
const loadZones = createAction(ZONES_GET);
const ZONES_GET_START = 'ZONES_GET_START';
const startLoadingZones = createAction(ZONES_GET_START);

const MOTES_BY_FARM_GET = 'MOTES_BY_FARM_GET';
const loadMotes = createAction(MOTES_BY_FARM_GET);
const MOTES_BY_FARM_GET_START = 'MOTES_BY_FARM_GET_START';
const startLoadingMotes = createAction(MOTES_BY_FARM_GET_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getZones = (token, farmId) => {
    return dispatch => {
        dispatch (startLoadingZones ());
        dispatch (showLoader ());
        axios.get(`/zones/farm/${farmId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadZones(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getMotes = (token, farmId) => {
    return dispatch => {
        dispatch (startLoadingMotes());
        dispatch (showLoader ());
        axios.get(`/motes/farm/${farmId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadMotes(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        ZONES_GET_START: (state, action) => {
            return {
                ...state,
                zones: [],
                isSuccessful: false,
            };
        },
        ZONES_GET: (state, action) => {
            const zones = action.payload??[];
            return {
                ...state,
                zones: zones.map(z => {return {zone_id: z.zone_id, zone_name: z.zone_name?.split('/')[0]}}),
                isSuccessful: true,
            };
        },
        MOTES_BY_FARM_GET_START: (state, action) => {
            return {
                ...state,
                motes: [],
                isSuccessful: false,
            };
        },
        MOTES_BY_FARM_GET: (state, action) => {
            return {
                ...state,
                motes: action.payload,
                isSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
