import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import {DateTime} from "luxon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import {getNdviZoneHapgrs, downloadNdviZoneHapgrs} from "../../../store/DashboardStore";
import {AuthContext} from "../../../use-auth";
import NoDataMessage from "../../common/NoDataMessage";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'table',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        margin: 20,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    table: {
        minWidth: 200,
    },
}));

function Zones(props) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const { getNdviZoneHapgrs, filters, ndviZones, isLoading, isSuccessful } = props;
    useEffect(() => {
        const callApi = async () => {
            const token = await currentUser.getIdToken(true);
            getNdviZoneHapgrs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
        };
        if (filters && filters.farmId) {
            callApi();
        }
    }, [filters, getNdviZoneHapgrs, currentUser]);

    async function handleDownload() {
        const token = await currentUser.getIdToken(true);
        downloadNdviZoneHapgrs(token, filters.farmId, filters.dateFrom, filters.dateTo, filters.farmLocalTimeZone);
    }

    return  (
        <>
            {ndviZones && ndviZones.length > 0 &&
            <>
                <div>
                    <IconButton aria-label="download" className={classes.button} onClick={handleDownload}>
                        <DownloadIcon fontSize="inherit" />
                    </IconButton>
                    Download CSV
                </div>
                <div>
                    <GridList className={classes.gridList} cols={2.5}>
                        {ndviZones.map(m => (
                            <Paper className={classes.root} key={m.date}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography variant='h6' align='center'>
                                                    {DateTime.fromISO(m.date).setZone('utc').setZone(filters.timeZone).toLocaleString(DateTime.DATE_HUGE)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Paddock / Zone</TableCell>
                                            <TableCell align="right">Zone PPH Datetime</TableCell>
                                            <TableCell align="right">Est. Zone PPH</TableCell>
                                            <TableCell align="right">Zone PPH</TableCell>
                                            <TableCell align="right">24hr Growth</TableCell>
                                            <TableCell align="right">48hr Growth</TableCell>
                                            <TableCell align="right">Zone Grazing Event Source</TableCell>
                                            <TableCell align="right">Last Grazing Datetime</TableCell>
                                            <TableCell align="right">Mote</TableCell>
                                            <TableCell align="right">Net or Gross</TableCell>
                                            <TableCell align="right">Method</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {m.growths.map(g => (
                                            <TableRow key={g.zone_name}>
                                                <TableCell align="right">{g.zone_name}</TableCell>
                                                <TableCell align="right">{DateTime.fromISO(g.datetime_zone_pph).setZone(filters.timeZone).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                <TableCell align="right">{g.est_zone_pph}</TableCell>
                                                <TableCell align="right">{g.zone_pph}</TableCell>
                                                <TableCell align="right">{g.growth_24hr}</TableCell>
                                                <TableCell align="right">{g.growth_48hr}</TableCell>
                                                <TableCell align="right">{g.zone_grazing_event_source}</TableCell>
                                                <TableCell align="right">{g.last_grazing_date == null ? '-' : DateTime.fromISO(g.last_grazing_date).setZone(filters.timeZone).toFormat('dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                <TableCell align="right">{g.mote_name}</TableCell>
                                                <TableCell align="right">{g.net_or_gross}</TableCell>
                                                <TableCell align="right">{g.method}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        ))}
                    </GridList>
                </div>
            </>
            }
            {!isLoading && (!ndviZones || ndviZones.length === 0) && <NoDataMessage isSuccessful={isSuccessful} requiredField="Farm" />}
        </>
    )
}

export default connect (state => ({
    ndviZones: state.dashboardStore.ndviZones,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
}), {
    getNdviZoneHapgrs,
}) (Zones);
