import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import DateRangePicker from '../../common/DateRangePicker';
import { useUtils } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    datePicker: {
        minWidth: 210,
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

export default function GrazingAddRow({zones, onAdd}) {
    const classes = useStyles();
    const [zone, setZone] = React.useState('');
    const [begin, setBegin] = React.useState('');
    const [end, setEnd] = React.useState('');
    const [dateRange, setDateRange] = React.useState([]);
    const utils = useUtils();

    const formatDate = date => utils.format(date, "dd/MM/yyyy" || utils.dateFormat);

    const handleAdd = () => {
        onAdd(zone, begin, end);
        setZone('');
        setDateRange([]);
        setBegin('');
        setEnd('');
    };

    return (
        <form className={classes.container}>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    id="paddock-select"
                    style={{ width: 210 }}
                    options={zones}
                    classes={{
                        option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={option => option.zone_name}
                    renderOption={option => (
                        <React.Fragment>
                            {option.zone_name}
                        </React.Fragment>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Paddock"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'disabled', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    value={zone}
                    onChange={(event, newValue) => {
                        setZone(newValue);
                    }}
                />
            </FormControl>
            <FormControl className={clsx(classes.formControl, classes.datePicker)}>
                <DateRangePicker
                    value={dateRange}
                    placeholder="Select a date range"
                    onChange={values => {
                        setBegin(formatDate(values.begin));
                        setEnd(formatDate(values.end));
                    }}
                    autoOk={true}
                    format="dd/MM/yyyy"
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Fab color="default" size="small" aria-label="add" onClick={handleAdd} disabled={!(zone && begin && end)}>
                    <AddIcon fontSize="small" elevation={0}/>
                </Fab>
            </FormControl>
        </form>
    );
}
