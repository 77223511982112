import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../use-auth";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
    const {currentUser} = useContext(AuthContext);

    const render = props => !!currentUser ? <Component {...props} /> : <Redirect to={"/"} />;
    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
