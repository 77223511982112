import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import FileDownload from "js-file-download";

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);

const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

const REGRESSION_INPUT_SUCCESS = 'REGRESSION_INPUT_SUCCESS';
const regressionInputSuccess = createAction(REGRESSION_INPUT_SUCCESS);

const REGRESSION_INPUT_START = 'REGRESSION_INPUT_START';
const regressionInputStart = createAction(REGRESSION_INPUT_START);

const FORMULA_SUCCESS = 'FORMULA_SUCCESS';
const formulaSuccess = createAction(FORMULA_SUCCESS);

const ZONE_HAPGR_START = 'ZONE_HAPGR_START';
const zoneHapgrStart = createAction(ZONE_HAPGR_START);

const ZONE_HAPGR_SUCCESS = 'ZONE_HAPGR_SUCCESS';
const zoneHapgrSucess = createAction(ZONE_HAPGR_SUCCESS);

// actions
export const getRegressionInput = (token, batchId) => {
    return dispatch => {
        dispatch (showLoader ());
        dispatch (regressionInputStart ());
        axios.get(`/regression/input/${batchId}`,
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(hideLoader());
            dispatch(regressionInputSuccess(response.data))
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getFormula = (token, batchId) => {
    return dispatch => {
        axios.get(`/regression/formula/${batchId}`,
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(formulaSuccess(response.data))
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getZoneHapgrs = (token, batchId) => {
    return dispatch => {
        dispatch (showLoader ());
        dispatch(zoneHapgrStart());
        axios.get(`/regression/zone-hapgr/${batchId}`,
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(hideLoader());
            dispatch(zoneHapgrSucess(response.data))
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const downloadZoneHapgrs = (token, batchId) => {
    axios.get(`/download/regression/zone-hapgr/${batchId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        FileDownload(response.data, `${batchId}.csv`);
    }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        console.log(error);
    });
}

export const approveBatch = (token, batchId, type) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/regression/approve/${batchId}/type/${type}`, {},
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
}

export const estimateZonePphs = (token, batchId) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/pphs/estimate/batch/${batchId}`, {},
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
}

export const deleteBatch = (token, batchId) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.delete(`/regression/${batchId}`,
            { headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
            dispatch(hideLoader());
            dispatch(estimateZonePphs(token, batchId))
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
}

// reducer
export default handleActions(
    {
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        REGRESSION_INPUT_START: (state) => {
          return {
              ...state,
              regressionInput: [],
          }
        },
        REGRESSION_INPUT_SUCCESS: (state, action) => {
            return {
                ...state,
                regressionInput: action.payload,
            };
        },
        FORMULA_SUCCESS: (state, action) => {
            return {
                ...state,
                formula: action.payload,
            };
        },
        ZONE_HAPGR_START: (state) => {
            return {
                ...state,
                zoneHapgrs: [],
            };
        },
        ZONE_HAPGR_SUCCESS: (state, action) => {
            return {
                ...state,
                zoneHapgrs: action.payload,
            };
        }
    },
    [],
);
