import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import ReceiptIcon from '@material-ui/icons/Receipt';
import {FormControlLabel} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        row: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        item: {
            flexGrow: 1,
            alignSelf: 'center',
            justifyContent: 'flex-start',
        },
        text: {
            width: '25ch',
            wordBreak: 'break-word',
        },
        files: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: theme.spacing(1),
        },
        processing: {
            backgroundColor: theme.palette.warning.light,
        }
    }),
);

export default function SingleSceneToBatch({processId, dateReported, scene, orderId, farmName, fileNames, onSelect}) {
    const classes = useStyles();

    const isProcessing = fileNames && !fileNames.includes("manifest.json");

    return ( <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                className={isProcessing && classes.processing}
            >
                <Grid
                    container
                    spacing={1}
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Grid item xs={2} md={1}>
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox onChange={() => onSelect(processId)}/>}
                            label=""
                        />
                    </Grid>
                    <Grid item xs={10} md>{scene.datetimeImage}</Grid>
                    <Grid item xs={12} md>{scene.sceneId}</Grid>
                    <Grid item xs={10} md>{dateReported}</Grid>
                    <Grid item xs={12} md>{farmName}</Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.files}>
                   <Chip label={<strong>{processId}</strong>} className={classes.chip}/>
                   <Chip icon={<ReceiptIcon fontSize="small"/>} label={<strong>{orderId}</strong>} className={classes.chip}/>
                   {fileNames && fileNames.map(fn => <Chip label={fn} key={fn} className={classes.chip}/>)}
                </div>
            </AccordionDetails>
        </Accordion>
    </>);
}
