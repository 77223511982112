import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import FileDownload from 'js-file-download';

const RAW_GET = 'RAW_GET';
const loadRaw = createAction(RAW_GET);
const RAW_GET_START = 'RAW_GET_START';
const startLoadingRaw = createAction(RAW_GET_START);

const SENSORS_GET = 'SENSORS_GET';
const loadSensors = createAction(SENSORS_GET);
const SENSORS_GET_START = 'SENSORS_GET_START';
const startLoadingSensors = createAction(SENSORS_GET_START);

const MOTE_HAPGRS_GET = 'MOTE_HAPGRS_GET';
const loadMoteHapgrs = createAction(MOTE_HAPGRS_GET);
const MOTE_HAPGRS_GET_START = 'MOTE_HAPGRS_GET_START';
const startLoadingMoteHapgrs = createAction(MOTE_HAPGRS_GET_START);

const ZONE_HAPGRS_GET = 'ZONE_HAPGRS_GET';
const loadZoneHapgrs = createAction(ZONE_HAPGRS_GET);
const ZONE_HAPGRS_GET_START = 'ZONE_HAPGRS_GET_START';
const startLoadingZoneHapgrs = createAction(ZONE_HAPGRS_GET_START);

const PADDOCK_PPHS_GET = 'PADDOCK_PPHS_GET';
const loadPaddockPphs = createAction(PADDOCK_PPHS_GET);
const PADDOCK_PPHS_GET_START = 'PADDOCK_PPHS_GET_START';
const startLoadingPaddockPphs = createAction(PADDOCK_PPHS_GET_START);

const NDVI_ZONE_HAPGRS_GET = 'NDVI_ZONE_HAPGRS_GET';
const loadNdviZoneHapgrs = createAction(NDVI_ZONE_HAPGRS_GET);
const NDVI_ZONE_HAPGRS_GET_START = 'NDVI_ZONE_HAPGRS_GET_START';
const startLoadingNdviZoneHapgrs = createAction(NDVI_ZONE_HAPGRS_GET_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getRaw = (token, moteId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingRaw ());
        dispatch (showLoader ());
        axios.get(`/hapgr/raw/mote/${moteId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadRaw(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader(false));
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

const FILTERED_GET = 'FILTERED_GET';
const loadFiltered = createAction(FILTERED_GET);
const FILTERED_GET_START = 'FILTERED_GET_START';
const startLoadingFiltered = createAction(FILTERED_GET_START);
const FILTERED_GET_ERROR = 'FILTERED_GET_ERROR';
const errorLoadingFiltered = createAction(FILTERED_GET_ERROR);

export const getFiltered = (token, moteId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingFiltered());
        dispatch (showLoader());
        axios.get(`/hapgr/filtered/mote/${moteId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadFiltered(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
            errorLoadingFiltered(error);
        })
    };
};

export const getSensors = (token, moteId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingSensors ());
        dispatch (showLoader ());
        axios.get(`/hapgr/sensors/mote/${moteId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadSensors(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getMoteHapgrs = (token, farmId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingMoteHapgrs ());
        dispatch (showLoader ());
        axios.get(`/hapgr/motes/farm/${farmId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadMoteHapgrs(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const downloadMoteHapgrs = (token, farmId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    axios.get(`/download/hapgr/motes/farm/${farmId}/from/${dateFrom}/to/${dateTo}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        FileDownload(response.data, `motes-${farmId}-${dateFrom.substr(0,10)}-${dateTo.substr(0,10)}.csv`);
    }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        console.log(error);
    });
};

export const getZoneHapgrs = (token, farmId, from, to, farmLocalTimeZone, targetPostGrazingResidual) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingZoneHapgrs ());
        dispatch (showLoader ());
        axios.get(`/hapgr/zones/farm/${farmId}/from/${dateFrom}/to/${dateTo}/tpgr/${targetPostGrazingResidual}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadZoneHapgrs(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getPaddockPphs = (token, farmId, from, to, farmLocalTimeZone, targetPostGrazingResidual) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingPaddockPphs ());
        dispatch (showLoader ());
        axios.get(`/pphs/farm/${farmId}/from/${dateFrom}/to/${dateTo}/tpgr/${targetPostGrazingResidual}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadPaddockPphs(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const downloadPaddockPphs = (token, farmId, from, to, farmLocalTimeZone, targetPostGrazingResidual, filterTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    axios.post(`download/pphs/farm/${farmId}/from/${dateFrom}/to/${dateTo}/tpgr/${targetPostGrazingResidual}`, { time_zone: filterTimeZone},{
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        FileDownload(response.data, `paddocks-${farmId}-${dateFrom.substr(0,10)}-${dateTo.substr(0,10)}.csv`);
    }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        console.log(error);
    });
};

export const getNdviZoneHapgrs = (token, farmId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return dispatch => {
        dispatch (startLoadingNdviZoneHapgrs ());
        dispatch (showLoader ());
        axios.get(`/pphs/zones/farm/${farmId}/from/${dateFrom}/to/${dateTo}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadNdviZoneHapgrs(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const downloadNdviZoneHapgrs = (token, farmId, from, to, farmLocalTimeZone) => {
    const dateFrom = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const dateTo = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    axios.get(`/download/pphs/zones/farm/${farmId}/from/${dateFrom}/to/${dateTo}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        FileDownload(response.data, `zones-${farmId}-${dateFrom.substr(0,10)}-${dateTo.substr(0,10)}.csv`);
    }).catch((err) => {
        const error = (err.response && err.response.data && err.response.data.error) ?
            new Error(err.response.data.error) : err;
        console.log(error);
    });
};

export const moveToOutOfRotation = (token, farmId, fromUtc, zoneId, resultsFrom, resultsTo, farmLocalTimeZone, targetPostGrazingResidual) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/zone-group/out-of-rotation/farm/${farmId}`, {
            from: fromUtc,
            zone_id: zoneId
        },{
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(hideLoader());
            dispatch(getPaddockPphs(token, farmId, resultsFrom, resultsTo, farmLocalTimeZone, targetPostGrazingResidual));
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};


// reducer
export default handleActions(
    {
        RAW_GET_START: (state, action) => {
            return {
                ...state,
                raw: [],
                isSuccessful: false,
            };
        },
        RAW_GET: (state, action) => {
            return {
                ...state,
                raw: action.payload,
                isSuccessful: true,
            };
        },
        FILTERED_GET_START: (state) => {
            return {
                ...state,
                filtered: [],
                isSuccessful: false,
                isFailed: false,
            };
        },
        FILTERED_GET: (state, action) => {
            return {
                ...state,
                filtered: action.payload,
                isSuccessful: true,
                isFailed: false,
            };
        },
        FILTERED_GET_ERROR: (state, action) => {
            return {
                ...state,
                filtered: [],
                isSuccessful: false,
                isFailed: true,
            };
        },
        SENSORS_GET_START: (state, action) => {
            return {
                ...state,
                sensors: [],
                isSuccessful: false,
            };
        },
        SENSORS_GET: (state, action) => {
            return {
                ...state,
                sensors: action.payload,
                isSuccessful: true,
            };
        },
        MOTE_HAPGRS_GET_START: (state, action) => {
            return {
                ...state,
                motes: [],
                isSuccessful: false,
            };
        },
        MOTE_HAPGRS_GET: (state, action) => {
            return {
                ...state,
                motes: action.payload,
                isSuccessful: true,
            };
        },
        ZONE_HAPGRS_GET_START: (state, action) => {
            return {
                ...state,
                zones: [],
                isSuccessful: false,
            };
        },
        ZONE_HAPGRS_GET: (state, action) => {
            return {
                ...state,
                zones: action.payload,
                isSuccessful: true,
            };
        },
        NDVI_ZONE_HAPGRS_GET_START: (state, action) => {
            return {
                ...state,
                ndviZones: [],
                isSuccessful: false,
            };
        },
        NDVI_ZONE_HAPGRS_GET: (state, action) => {
            return {
                ...state,
                ndviZones: action.payload,
                isSuccessful: true,
            };
        },
        PADDOCK_PPHS_GET_START: (state, action) => {
            return {
                ...state,
                paddockPphs: [],
                isSuccessful: false,
            };
        },
        PADDOCK_PPHS_GET: (state, action) => {
            return {
                ...state,
                paddockPphs: action.payload,
                isSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
