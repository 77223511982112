import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import CheckIcon from '@material-ui/icons/Check';
import Fab from "@material-ui/core/Fab";
import clsx from "clsx";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {DateTime} from "luxon";
import FarmGroupsAutoComplete from "../../common/FarmGroupsAutoComplete";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    content: {
        padding: 0,
    },
}));

function MoteTransferDialog({moteName, open, onClose, onTransfer}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [dateTranferred, setDateTransferred] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')).startOf('day'));

    const [selectedFarmGroup, setSelectedFarmGroup] = React.useState({});
    const handleFarmGroupSelect = async (farmGroup) => {
        setSelectedFarmGroup(farmGroup);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{`Transfer ${moteName} to`}</DialogTitle>
            <DialogContent className={classes.content}>
                <div>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <FarmGroupsAutoComplete onFarmGroupSelect={handleFarmGroupSelect} className={classes.farmGroupDropdown}/>
                        </FormControl>
                        <FormControl className={clsx(classes.formControl, classes.datePicker)}>
                            <KeyboardDateTimePicker
                                autoOk
                                ampm={false}
                                variant={isDesktop ? "inline" : "dialog"}
                                inputVariant="outlined"
                                label={selectedFarmGroup && selectedFarmGroup.farm_group_time_zone ? `at ${selectedFarmGroup.farm_group_time_zone}` : 'On'}
                                format="dd/MM/yyyy HH:mm"
                                value={dateTranferred}
                                disableFuture
                                InputAdornmentProps={{ position: "start" }}
                                onChange={date => setDateTransferred(date)}
                                className={classes.datePicker}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Fab color="primary" size="small" aria-label="move" onClick={() => onTransfer(selectedFarmGroup, dateTranferred)}>
                                <CheckIcon />
                            </Fab>
                        </FormControl>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default MoteTransferDialog;
