import React, {useContext, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import {connect} from "react-redux";
import {getMotes, getFarms} from "../../store/FilterStore";
import {Typography} from "@material-ui/core";
import {AuthContext} from "../../use-auth";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const useStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 128px)',
        padding: 20,
    },
    formControl: {
        minWidth: 180,
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: 20,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    datePicker: {
        maxWidth: 260,
        whiteSpace: 'pre-line',
    },
    slider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

function FarmStatusFilters({ getFarms, farms, onSearchClick, isLoading }) {

    const { currentUser, farmGroupId, loading } = useContext(AuthContext);

    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedFromDate, handleFromDateChange] = React.useState(DateTime.fromISO(DateTime.local().minus({ days: 6 }).toFormat('yyyy-MM-dd')));
    const [selectedToDate, handleToDateChange] = React.useState(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')));

    const [selectedFarm, setSelectedFarm] = React.useState(null);

    useEffect(() => {
        if (!loading && farmGroupId > 0) {
            const callApi = async () => {
                const token = await currentUser.getIdToken(true);
                getFarms(token, farmGroupId);
            };
            callApi();
        }
    }, [loading, farmGroupId, getFarms, currentUser]);

    function handleSearchClick() {
        onSearchClick({
            dateFrom: selectedFromDate.setZone(selectedFarm.time_zone, { keepLocalTime: true }).plus({hours: 12}),
            dateTo: selectedToDate.setZone(selectedFarm.time_zone, { keepLocalTime: true }).plus({hours: 12}),
            timeZone: selectedFarm.time_zone,
            timeZoneName: selectedFarm.time_zone,
            farmLocalTimeZone: selectedFarm.time_zone,
            farmId: selectedFarm.farm_id,
            farmName: selectedFarm.farm_name,
        })
    }

    const dateFiled = (label, value, minValue, onChange) => (
        <KeyboardDatePicker
            autoOk
            variant={isDesktop ? "inline" : "dialog"}
            inputVariant="outlined"
            label={label}
            format="dd/MM/yyyy"
            value={value}
            minDate={minValue}
            disableFuture
            InputAdornmentProps={{ position: "start" }}
            onChange={date => onChange(date)}
            className={classes.datePicker}
        />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item>
                    {dateFiled('From', selectedFromDate, '01/01/2018', handleFromDateChange)}
                </Grid>
                <Grid item>
                    {dateFiled('To', selectedToDate, selectedFromDate, handleToDateChange)}
                </Grid>
                <Grid item>
                    <div className={classes.datePicker}>
                        <Typography variant="caption" color="primary">
                            <sup>*</sup> Each Date Filter is a reporting window which is previous day's Farm Local
                            Midday to Selected Date's Farm Local Midday
                        </Typography>
                    </div>
                </Grid>
                <Grid item>
                    <Autocomplete
                        id="farm-select"
                        style={{ width: 260 }}
                        options={farms}
                        autoHighlight
                        getOptionLabel={option => option.farm_name}
                        renderOption={option => (
                            <React.Fragment>
                                {option.farm_name}
                            </React.Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Farm"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={selectedFarm}
                        onChange={(event, newValue) => {
                            setSelectedFarm(newValue);
                        }}
                        disabled={isLoading}
                    />
                </Grid>
                {selectedFarm && <Grid item>
                    {`Farm's Time Zone: ${selectedFarm.time_zone}`}
                </Grid>}
                <Grid item>
                    <Button variant="contained" color="secondary" className={classes.button} onClick={handleSearchClick}
                            disabled={!selectedFarm}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default connect (state => ({
    motes: state.filterStore.motes,
    farms: state.filterStore.farms,
    isLoading: state.filterStore.isLoading,
}), {
    getMotes,
    getFarms,
}) (FarmStatusFilters);
