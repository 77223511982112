import React, { useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MoveIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import GrazingAddRow from './GrazingAddRow';
import {connect} from "react-redux";
import {DateTime} from "luxon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Toaster from "../../common/Toaster";
import {save} from "../../../store/GrazingStore";
import {AuthContext} from "../../../use-auth";
import TablePagination from "@material-ui/core/TablePagination";

const useStyles = makeStyles(theme => ({
    container: {
        cursor: 'pointer',
        minHeight: 210,
    },
    cell: {
        padding: 4,
        border: 'solid lightgray 0.001rem',
        textAlign: 'center',
    },
    actionCell: {
        border: 'none',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    datePicker: {
        minWidth: 210,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function GrazingDialog({open, onClose, farmId, farmName, timeZone, zones, save, isSuccessful}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 5;

    const [reportedGrazings, setReportedGrazings] = useState([]);
    const handleAdd = (zone, begin, end) => {
        setReportedGrazings([
            ...reportedGrazings,
            { zone, begin, end },
        ]);
    };

    const handleDelete = (zone, begin, end) => {
        const notRequired = reportedGrazings.filter(rg => rg.zone === zone && rg.begin === begin && rg.end === end);
        setReportedGrazings(reportedGrazings.filter(rg => !notRequired.includes(rg)));
    };

    const handleCancel = () => {
        setReportedGrazings([]);
        onClose();
    };

    const formatDate = (date) => DateTime.fromFormat(date, 'dd/MM/yyyy').setZone(timeZone, { keepLocalTime: true }).toUTC().toString();

    const handleSave = async () => {
        const token = await currentUser.getIdToken(true);
        save(token, farmId, farmName, reportedGrazings.map(rg => ({zone_id: rg.zone.zone_id, zone_name: rg.zone.zone_name, begin: formatDate(rg.begin), end: formatDate(rg.end)})));
        setReportedGrazings([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Report Grazing</DialogTitle>
                <DialogContent className={classes.content}>
                    <GrazingAddRow zones={zones} onAdd={handleAdd} />
                    <TableContainer className={classes.container}>
                        <Table stickyHeader size="small" align={"center"} aria-label="sticky sensors table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell}>Paddock</TableCell>
                                    <TableCell className={classes.cell}>Grazing Started</TableCell>
                                    <TableCell className={classes.cell}>Grazing Ended</TableCell>
                                    <TableCell className={classes.actionCell}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportedGrazings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(r => <TableRow>
                                        <TableCell className={classes.cell}>{r.zone.zone_name}</TableCell>
                                        <TableCell className={classes.cell}>{r.begin}</TableCell>
                                        <TableCell className={classes.cell}>{r.end}</TableCell>
                                        <TableCell className={classes.actionCell}><DeleteIcon fontSize="small" onClick={() => handleDelete(r.zone, r.begin, r.end)}/></TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={reportedGrazings.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    <div className={classes.actions}>
                        <IconButton color="default" aria-label="cancel" className={classes.margin} onClick={handleCancel}>
                            <CancelIcon fontSize="small" elevation={0}/>
                        </IconButton>
                        <Fab color="primary" size="small" aria-label="move" className={classes.margin} onClick={handleSave} disabled={!reportedGrazings || reportedGrazings.length === 0}>
                            <MoveIcon fontSize="small" />
                        </Fab>
                    </div>
                </DialogContent>
            </Dialog>
            {isSuccessful && <Toaster variant="success" message="Successfully reported grazings!"/>}
        </div>
    );
}

export default connect (state => ({
    isLoading: state.grazingStore.isLoading,
    isSuccessful: state.grazingStore.isSuccessful,
}), {
    save,
}) (GrazingDialog);
