import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import CheckIcon from '@material-ui/icons/Check';
import Fab from "@material-ui/core/Fab";
import clsx from "clsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {editFarmGroup} from "../../../store/FarmGroupsStore";
import {AuthContext} from "../../../use-auth";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    content: {
        padding: 0,
    },
}));

function FarmGroupEditDialog({farmGroup, open, onClose, editFarmGroup}) {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);

    const [newFarmGroupName, setNewFarmGroupName] = React.useState(null);
    const handleNameChange = event => {
        setNewFarmGroupName(event.target.value);
    };

    const [newFarmGroupTimeZone, setNewFarmGroupTimeZone] = React.useState(null);
    const handleTimeZoneChange = event => {
        setNewFarmGroupTimeZone(event.target.value);
    };

    const handleSave = () => {
        const name = newFarmGroupName ? newFarmGroupName : farmGroup.farm_group_name;
        const timeZone = newFarmGroupTimeZone ? newFarmGroupTimeZone : farmGroup.farm_group_time_zone;
        editFarmGroup(currentUser, farmGroup.farm_group_id, name, timeZone);
        setNewFarmGroupName(null);
        setNewFarmGroupTimeZone(null);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{`Editing ${farmGroup.farm_group_name}`}</DialogTitle>
            <DialogContent className={classes.content}>
                <div>
                    <form className={classes.container}>
                        <FormControl className={clsx(classes.formControl, classes.datePicker)}>
                            <TextField label={farmGroup.farm_group_name} variant="outlined" value={newFarmGroupName} onChange={handleNameChange} />
                        </FormControl>
                        <FormControl className={clsx(classes.formControl, classes.datePicker)}>
                            <TextField label={farmGroup.farm_group_time_zone} variant="outlined" value={newFarmGroupTimeZone} onChange={handleTimeZoneChange} />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Fab color="primary" size="small" aria-label="move" onClick={handleSave}>
                                <CheckIcon />
                            </Fab>
                        </FormControl>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default connect (state => ({
    isLoading: state.farmGroupsStore.isLoading,
    isSuccessful: state.farmGroupsStore.isSuccessful,
}), {
    editFarmGroup,
}) (FarmGroupEditDialog);
