import React from 'react';
// import MaterialTable from "material-table";
import TitleBar from '../components/common/TitleBar';
import {connect} from "react-redux";
import {getAllUsers, addUser, updateUser, removeUser} from "../store/UsersStore";
// import {AuthContext} from "../use-auth";
// import {tableIcons} from "../components/common/TableIcons";

function Users() {
    // const { currentUser } = useContext(AuthContext);
    //
    // const { getAllUsers, users, farmGroups } = props;
    // useEffect(() => {
    //     const callApi = async () => {
    //         const token = await currentUser.getIdToken(true);
    //         getAllUsers(token);
    //     };
    //     callApi();
    // }, [getAllUsers, currentUser]);

    // function getFarmGroupName(farmGroupId) {
    //     const farmGroup = farmGroups.find(fg => fg.farm_group_id === farmGroupId);
    //     if (!farmGroup) {
    //         return '';
    //     }
    //     return farmGroup.farm_group_name
    // }

    // async function handleAdd(newUser) {
    //     const token = await currentUser.getIdToken(true);
    //     addUser(token, newUser);
    // }
    //
    // async function handleUpdate(newUser, oldUser) {
    //     const token = await currentUser.getIdToken(true);
    //     updateUser(token, newUser);
    // }

    // async function handleDelete(userId) {
    //     const token = await currentUser.getIdToken(true);
    //     removeUser(token, userId);
    // }

    return (
        <div>
            <TitleBar title="Users"/>
            <div>
                {/*<MaterialTable*/}
                {/*    icons={tableIcons}*/}
                {/*    title=""*/}
                {/*    columns={[*/}
                {/*        {title: 'Name', field: 'displayName'},*/}
                {/*        {title: 'Email', field: 'email'},*/}
                {/*        {title: 'Role', field: 'role'},*/}
                {/*        {title: 'FarmGroup', field: 'farmGroupId', render: rowData => getFarmGroupName(rowData.farmGroupId) },*/}
                {/*    ]}*/}
                {/*    data={users}*/}
                {/*    options={{*/}
                {/*        pageSize: 15,*/}
                {/*        pageSizeOptions: [15,30,45],*/}
                {/*        filtering: true,*/}
                {/*        search: true,*/}
                {/*        grouping: true,*/}
                {/*        exportButton: true,*/}
                {/*        actionsColumnIndex: -1*/}
                {/*    }}*/}
                {/*    editable={{*/}
                {/*        onRowAdd: newData => handleAdd(newData),*/}
                {/*        onRowUpdate: (newData, oldData) => handleUpdate(newData, oldData),*/}
                {/*        onRowDelete: oldData => handleDelete(oldData.uid)*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
        </div>
    );
}

export default connect (state => ({
    users: state.usersStore.data ? state.usersStore.data.users : [],
    farmGroups: state.usersStore.farmGroups,
    isLoading: state.usersStore.isLoading,
    isSuccessful: state.usersStore.isSuccessful,
}), {
    getAllUsers,
    addUser,
    updateUser,
    removeUser,
}) (Users);
