import React, {useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import blue from '@material-ui/core/colors/blue';
import TextField from "@material-ui/core/TextField";
import NoDataMessage from "../common/NoDataMessage";
import {formatStrToDateTime} from "../common/Util";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useStyles = makeStyles( {
    root: {
        width: '100%',
        cursor: 'pointer',
    },
    container: {
        maxHeight: '80vh',
        maxWidth: 'fit-content',
    },
    cell: {
        padding: 4,
        border: 'solid lightgray 0.001rem',
    },
    centerCell: {
        textAlign: 'center',
    },
    rightCell: {
        textAlign: 'right',
        paddingRight: '5px !important',
    },
    headerCell: {
        height: 40,
    },
    lastCell: {
        borderBottom: 'solid darkgray 0.2rem',
    },
    focusedRow: {
        backgroundColor: '#FBEB74 !important',
    },
});


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function EventsTable({events, timeZone}) {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filteredEvents, setFilteredEvents] = useState([]);
    useEffect(() => {
        if (events) {
            setFilteredEvents(events);
            setPage(0);
        }
    },[events, setFilteredEvents]);
    const handleFilterData = (propName, value, isDate) => {
        let filteredEvents;
        if (isDate) {
            filteredEvents = events.filter(e => formatStrToDateTime(e[propName], timeZone).includes(value));
        } else {
            filteredEvents = events.filter(e => e[propName]?.toString().toLowerCase().includes(value?.toLowerCase()));
        }
       setFilteredEvents(filteredEvents);
       setPage(0);
    };

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("date_recorded");

    if (!events) {
        return <NoDataMessage isSuccessful={false} requiredField="Mote" />;
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const formatEventData = (eventId, eventData) => {
        if (eventId === 1) {
            return eventData === 1 || eventData === "1" || eventData === "true" || eventData === true ? "ON" : "OFF";
        }
        if (eventId === 2) {
            return eventData === 1 || eventData === "1" || eventData === "true" || eventData === true ? "Wake" : "Sleep";
        }
        if (eventId === 63 || eventId === 80) {
            return eventData === 1 || eventData === "1" || eventData === "true" || eventData === true ? "Enter" : "Exit";
        }
        if (eventId === 65) {
            return eventData === 1 || eventData === "1" || eventData === "true" || eventData === true ? "Sunrise" : "Sunset";
        }
        return eventData;
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small" align={"center"} aria-label="sticky filtered table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} colSpan={5}>Event</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} colSpan={2}>Counter</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} colSpan={5}>Network</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>
                                <TableSortLabel active={orderBy === "date_recorded"}
                                                direction={orderBy === "date_recorded" ? order : "desc"} onClick={() => handleRequestSort("date_recorded")}>
                                    <TextField fullWidth placeholder="Event Date" onChange={(e) => handleFilterData("date_recorded", e.target.value, true)}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} width={40}>
                                <TextField fullWidth placeholder="ID" onChange={(e) => handleFilterData("event_id", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>
                                <TextField fullWidth placeholder="Name" onChange={(e) => handleFilterData("event_name", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>
                                <TextField fullWidth placeholder="Data" onChange={(e) => handleFilterData("event_data", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Lat Lng</TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} width={60}>
                                <TableSortLabel active={orderBy === "global_counter"}
                                                direction={orderBy === "global_counter" ? order : "desc"} onClick={() => handleRequestSort("global_counter")}>
                                    <TextField fullWidth placeholder="Global" onChange={(e) => handleFilterData("global_counter", e.target.value)}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} width={60}>
                                <TextField fullWidth placeholder="Event" onChange={(e) => handleFilterData("event_counter", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>
                                <TableSortLabel active={orderBy === "date_network"}
                                                direction={orderBy === "date_network" ? order : "asc"} onClick={() => handleRequestSort("date_network")}>
                                    <TextField fullWidth placeholder="N/W Date" onChange={(e) => handleFilterData("date_network", e.target.value, true)}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>
                                <TextField fullWidth placeholder="Message ID" onChange={(e) => handleFilterData("message_id", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} width={60}>
                                <TextField fullWidth placeholder="FCnt Up" onChange={(e) => handleFilterData("frame_count_up", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)} width={60}>
                                <TextField fullWidth placeholder="FCnt Down" onChange={(e) => handleFilterData("frame_count_down", e.target.value)}/>
                            </TableCell>
                            <TableCell className={clsx(classes.headerCell, classes.cell, classes.centerCell)}>Sp Factor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(filteredEvents, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(e => <TableRow onClick={() => setSelectedRow({id: e.event_id, date: e.date_recorded})}
                                                               selected={e.event_id === selectedRow.id && e.date_recorded === selectedRow.date}
                                                               classes={{selected: classes.focusedRow}}>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>{formatStrToDateTime(e.date_recorded, timeZone)}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.event_id}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>{e.event_name}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>{formatEventData(e.event_id, e.event_data)}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>
                                    {e.latitude !== 0 ? <Link href={`http://maps.google.com/maps?q=${e.latitude},${e.longitude}`} target="_blank" color={blue[200]}>
                                        {`${e.latitude} , ${e.longitude}`}
                                    </Link> : `${e.latitude} , ${e.longitude}`}
                                </TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.global_counter}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.event_counter}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>{formatStrToDateTime(e.date_network, timeZone)}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.centerCell)}>{e.message_id}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.frame_count_up}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.frame_count_down}</TableCell>
                                <TableCell className={clsx(classes.cell, classes.rightCell)}>{e.spreading_factor}</TableCell>
                            </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100, 150, 200]}
                component="div"
                count={filteredEvents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
