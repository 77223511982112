import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
    createStyles({
        row: {
            display: 'flex',
            marginBottom: 10,
            borderBottom: 'solid lightgray 0.001rem',
        },
        item: {
            flexGrow: 1,
            alignSelf: 'center',
            justifyContent: 'flex-start',
        },
        text: {
            width: '25ch',
            wordBreak: 'break-word',
        },
    }),
);

export default function SingleSceneToDownload({scene}) {
    const classes = useStyles();

    return (
        <div className={classes.row}>
            <Checkbox
                checked={scene.rejectReasons.length === 0}
                disabled
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                className={classes.item}
            />
            <div className={clsx(classes.item, classes.text)}>{scene.sceneId}</div>
            <div className={clsx(classes.item, classes.text)}>{scene.farm?.farmName}</div>
            <div className={clsx(classes.item, classes.text)}>{scene.rejectReasons.join()}</div>
        </div>
    );
}
