import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const save = (token, farmId, farmName, reportedGrazings) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/zone-grazing/farm/${farmId}/${farmName}`,
        { reportedGrazings },
        { headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
