import React, {useEffect, useContext} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebase from 'firebase/app';
import {Redirect} from "react-router-dom";
import {makeStyles} from '@material-ui/core';
import clsx from "clsx";
import { AuthContext } from "../../use-auth";
import LogoFull from "../../resources/logo-full.png";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import {auth} from '../../base';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    header: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    logoStacked: {
        width: '100%',
        maxWidth: 400,
    },
    centered: {
        position: 'fixed',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    progress: {
        margin: theme.spacing(2),
    },
}));

function SignInScreen() {
    const classes = useStyles();
    const [isSignedIn, setIsSignedIn] = React.useState(false);

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        }
    };

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(
            (user) => setIsSignedIn(!!user)
        );
        return function cleanup() {
            unregisterAuthObserver();
        }
    });

    const { loading, currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to={"/dashboard"} />;
    }

    return (
        <div>
            {!isSignedIn &&
                <div className={classes.centered}>
                    <div className={classes.header}>
                        <img
                            src={LogoFull}
                            alt="logo"
                            className={clsx(classes.logo, classes.logoStacked)}
                        />
                        <div>
                            <Typography variant="body1">Precise Pasture Management</Typography>
                        </div>
                        <div>
                            {loading && <CircularProgress className={classes.progress} />}
                        </div>
                    </div>
                    {!loading && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>}
                </div>
            }
        </div>
    )
}

export default SignInScreen;
