import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const FARM_STATUS_GET = 'FARM_STATUS_GET';
const loadFarmStatus = createAction(FARM_STATUS_GET);

const FARM_STATUS_GET_START = 'FARM_STATUS_GET_START';
const startLoadingFarmStatus = createAction(FARM_STATUS_GET_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);

const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getFarmStatus = (currentUser, farmId, from, to, farmLocalTimeZone) => {
    const since = from.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    const until = to.setZone(farmLocalTimeZone, { keepLocalTime: true }).toUTC().toString();
    return async dispatch => {
        dispatch (startLoadingFarmStatus ());
        dispatch (showLoader ());
        const token = await currentUser.getIdToken(true);
        axios.get(`/mote-status/farm/${farmId}/since/${since}/until/${until}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadFarmStatus(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        FARM_STATUS_GET_START: (state, action) => {
            return {
                ...state,
                farmStatus: [],
                isSuccessful: false,
            };
        },
        FARM_STATUS_GET: (state, action) => {
            return {
                ...state,
                farmStatus: action.payload,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
