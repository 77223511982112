import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/FilterList';
import TitleBar from '../components/common/TitleBar';
import DashboardFilters from '../components/dashboard/DashboardFilters';
import DashboardTabs from '../components/dashboard/DashboardTabs';
import FilterDrawer from '../components/nav/FilterDrawer';

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    fullWidth: {
        width: '100%',
    },
    widthWithoutDrawer: {
        width: 'calc(100% - 300px)',
    },
    button: {
        fontSize: '0.7rem',
        fontWeight: 600,
        letterSpacing: 0,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    }
}));

export default function Dashboard({raw}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [filters, setFilters] = React.useState({});
    const [showFilters, setShowFilters] = React.useState(true);
    const [showMobileFilters, setShowMobileFilters] = React.useState(false);
    const [hideMoteInFilter, setHideMoteInFilter] = React.useState(false);

    async function handleFilterSearchClick(filters) {
        setShowMobileFilters(false);
        setFilters(filters);
    }

    function handleShowFiltersClick() {
        setShowFilters(!showFilters);
    }

    function handleShowMobileFiltersClick() {
        setShowMobileFilters(!showMobileFilters);
    }

    function handleSetHideMoteInFilter(isHidden) {
        setHideMoteInFilter(isHidden);
    }

    return (
        <div>
            <TitleBar title="Dashboard">
                <Button color="secondary" className={classes.button} onClick={isDesktop ? handleShowFiltersClick : handleShowMobileFiltersClick}>
                    <FilterIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                    {isDesktop && <div>{!showFilters ? 'Show Filters' : 'Hide Filters'}</div>}
                </Button>
            </TitleBar>
            <div className={isDesktop ? classes.root : ''}>
                <div className={clsx({
                        [classes.fullWidth]: isDesktop && !showFilters,
                        [classes.widthWithoutDrawer]: isDesktop && showFilters
                    },
                )}>
                    <DashboardTabs
                        rawData={raw}
                        filters={filters}
                        setHideMoteInFilter={handleSetHideMoteInFilter}
                    />
                </div>
                <div className={classes.filterDrawer}>
                    <FilterDrawer
                        drawerOpen={showFilters}
                        mobileDrawerOpen={showMobileFilters}
                        toggleMobileDrawerOpen={handleShowMobileFiltersClick}
                    >
                        <DashboardFilters hideMoteInFilter={hideMoteInFilter} onSearchClick={handleFilterSearchClick} />
                    </FilterDrawer>
                </div>
            </div>
        </div>
    );
}
