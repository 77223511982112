import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';

const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerOpen: {
        [theme.breakpoints.up('sm')]: {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: 0,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}));

export default function FilterDrawer(props) {
    const classes = useStyles();
    const { drawerOpen, mobileDrawerOpen, toggleMobileDrawerOpen } = props;

    const drawerContent = (
        <div>
            {props.children}
        </div>
    );

    const mobileDrawer = (
        <Drawer
            variant="temporary"
            anchor="right"
            open={mobileDrawerOpen}
            onClose={toggleMobileDrawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div className={classes.toolbar} />
            {drawerContent}
        </Drawer>
    );

    const desktopDrawer = (
        <Fade in>
            <Paper className={clsx(classes.drawer, {
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    })}
            >
                {drawerContent}
            </Paper>
        </Fade>
    );

    return (
        <div>
            <CssBaseline />
            <nav className={classes.drawer} aria-label="Menu">
                <Hidden smUp implementation="css">
                    {mobileDrawer}
                </Hidden>
                <Hidden xsDown implementation="css">
                    {desktopDrawer}
                </Hidden>
            </nav>
        </div>
    );
}
