import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const GROUPS_GET = 'GROUPS_GET';
const loadGroups = createAction(GROUPS_GET);
const GROUPS_GET_START = 'GROUPS_GET_START';
const startLoadingGroups = createAction(GROUPS_GET_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getGroups = (token, farmId) => {
    return dispatch => {
        dispatch (startLoadingGroups ());
        dispatch (showLoader ());
        axios.get(`/zone-group/farm/${farmId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadGroups(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const add = (token, farmId, groupName, isCuttingGroup) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/zone-group`,
        { farm_id: farmId, zone_group_name: groupName, active: true, is_cutting_group: isCuttingGroup },
        { headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const save = (token, groupId, zoneId, since) => {
    return dispatch => {
        dispatch (showLoader ());
        axios.post(`/zone-group/${groupId}/zones`,
        { group_id: groupId, zone_id: zoneId, since },
        { headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

// reducer
export default handleActions(
    {
        GROUPS_GET_START: (state, action) => {
            return {
                ...state,
                groups: [],
                isSuccessful: false,
            };
        },
        GROUPS_GET: (state, action) => {
            return {
                ...state,
                groups: action.payload,
                isSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
