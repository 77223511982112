import React, { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles( () => ({
    root: {
        margin: 20,
    },
}));

function RegressionInputNdreGraph({data}) {

    const classes = useStyles();
    const [chartData, setChartData] = useState({scatter: {x:[], y: [], text: "Regression Input"}});
    const Plot = window.createPlotlyComponent(window.Plotly);

    useEffect(() => {
        const xs = data.map(d => d.mean_mote_ndre);
        const ys = data.map(d => d.hapgr);
        setChartData({
            scatter: {x: xs, y: ys},
        });
    }, [data]);

    return (
        <div className={classes.root}>
            <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Linear Regression',
                        x: chartData.scatter.x,
                        y: chartData.scatter.y,
                        trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                        hoverinfo: 'none',
                    }
                ]}
                layout={ {title: `Regression Input`, showlegend: false, xaxis: {title: 'Mean Mote NDRE'}, yaxis: {title: 'HAPGR'} } }
            />
        </div>
    );
}

export default RegressionInputNdreGraph;
