import React, {useContext} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ActivityIcon from '@material-ui/icons/History';
import UsersIcon from '@material-ui/icons/Group';
import EventsIcon from '@material-ui/icons/Event';
import MotesIcon from '@material-ui/icons/PinDrop';
import FarmStatusIcon from '@material-ui/icons/Landscape';
import NdviIcon from '@material-ui/icons/SatelliteSharp';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../resources/logo-pink.png';
import LogoStacked from '../../resources/logo-stacked.png';
import {AuthContext} from "../../use-auth";

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    logo: {
        position: 'absolute',
        bottom: theme.spacing(1),
        margin: theme.spacing(1),
    },
    logoSingle: {
        width: 30,
    },
    logoStacked: {
        width: 140,
    },
    link: {
        color: 'inherit',
    }
}));

export default function NavDrawer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { drawerOpen, mobileDrawerOpen, toggleMobileDrawerOpen } = props;
    const {isAdmin} = useContext(AuthContext);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    function handleListItemClick(event, index) {
        setSelectedIndex(index);
    }

    const listItem = (icon, name, link, index) => (
        <Link to={link} className={classes.link}>
            <ListItem
                button
                selected={selectedIndex === index}
                onClick={event => handleListItemClick(event, index)}
            >
                    <ListItemIcon>
                            {icon}
                    </ListItemIcon>
                    <ListItemText primary={name} />
            </ListItem>
        </Link>
    );

    const toggleLogo = (isDrawerOpen) => (
        <img
            src={isDrawerOpen ? LogoStacked : Logo}
            alt="logo"
            className={clsx(classes.logo, {
                [classes.logoStacked]: isDrawerOpen,
                [classes.logoSingle]: !isDrawerOpen} )}
        />
    );

    const drawerContent = (
        <div>
            <Divider />
            <List>
                    {listItem(<DashboardIcon/>,'Dashboard','/dashboard', 0)}
                    {listItem(<ActivityIcon/>,'Activity','/activity', 1)}
            </List>
            {isAdmin &&
                <>
                    <Divider />
                    <List>
                        {listItem(<UsersIcon/>,'Users','/users', 2)}
                        {listItem(<EventsIcon/>,'Events','/events', 3)}
                        {listItem(<MotesIcon/>,'Motes','/motes', 4)}
                        {listItem(<FarmStatusIcon/>,'Farm Status','/farm-status', 5)}
                        {listItem(<NdviIcon/>,'NDVI Tracking','/ndvi-tracking', 6)}
                    </List>
                </>
            }
        </div>
    );

    const mobileDrawer = (
        <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileDrawerOpen}
            onClose={toggleMobileDrawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div className={classes.toolbar} />
            {drawerContent}
            {toggleLogo(mobileDrawerOpen)}
        </Drawer>
    );

    const desktopDrawer = (
        <Drawer variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    }),
                }}
                open={drawerOpen}
        >
            <div className={classes.toolbar} />
            {drawerContent}
            {toggleLogo(drawerOpen)}
        </Drawer>
    );

    return (
        <div>
            <CssBaseline />
            <nav className={classes.drawer} aria-label="Menu">
                <Hidden smUp implementation="css">
                    {mobileDrawer}
                </Hidden>
                <Hidden xsDown implementation="css">
                    {desktopDrawer}
                </Hidden>
            </nav>
        </div>
    );
}
