import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';

const USERS_GET = 'USERS_GET';
const loadUsersGet = createAction(USERS_GET);
const USERS_GET_START = 'USERS_GET_START';
const startLoadUsersGet = createAction(USERS_GET_START);

const USER_GET = 'USER_GET';
const loadUserGet = createAction(USER_GET);
const USER_GET_START = 'USER_GET_START';
const startLoadUserGet = createAction(USER_GET_START);

// const USER_EDIT = 'USER_EDIT';
// const loadUserEdit = createAction(USER_EDIT);
const USER_EDIT_START = 'USER_EDIT_START';
const startLoadUserEdit = createAction(USER_EDIT_START);

const SHOW_LOADER = 'SHOW_LOADER';
const showLoader = createAction(SHOW_LOADER);
const HIDE_LOADER = 'HIDE_LOADER';
const hideLoader = createAction(HIDE_LOADER);

// actions
export const getAllUsers = (token) => {
    return dispatch => {
        dispatch (startLoadUsersGet ());
        dispatch (showLoader ());
        axios.get(`/users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadUsersGet(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const getUser = (token, userId) => {
    return dispatch => {
        dispatch (startLoadUserGet ());
        dispatch (showLoader ());
        axios.get(`/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch(loadUserGet(response.data));
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const addUser = (token, newUser) => {
    return dispatch => {
        dispatch (startLoadUserEdit ());
        dispatch (showLoader ());
        axios.post(`/users`, {
            displayName: newUser.displayName,
            email: newUser.email,
            password: newUser.password,
            role: newUser.role,
            farmGroupId: newUser.farmGroupId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getAllUsers(token);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const updateUser = (token, newUser, oldUser) => {
    return dispatch => {
        dispatch (startLoadUserEdit ());
        dispatch (showLoader ());
        axios.patch(`/users/${oldUser.uid}`, {
            displayName: newUser.displayName,
            email: newUser.email,
            role: newUser.role,
            farmGroupId: newUser.farmGroupId,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            getAllUsers(token);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const removeUser = (token, userId) => {
    return dispatch => {
        dispatch (startLoadUserEdit ());
        dispatch (showLoader ());
        axios.delete(`/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            console.log(response);
            getAllUsers(token);
        }).catch((err) => {
            dispatch(hideLoader());
            const error = (err.response && err.response.data && err.response.data.error) ?
                new Error(err.response.data.error) : err;
            console.log(error);
        })
    };
};

export const setUserNull = () => {
    return dispatch => {
        dispatch (loadUserGet (null));
    };
};

// reducer
export default handleActions(
    {
        USERS_GET_START: (state, action) => {
            return {
                ...state,
                data: [],
                isSuccessful: false,
            };
        },
        USERS_GET: (state, action) => {
            return {
                ...state,
                data: action.payload,
                isSuccessful: true,
            };
        },
        USER_GET_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        USER_GET: (state, action) => {
            return {
                ...state,
                user: action.payload ? action.payload.user : null,
                isSuccessful: true,
            };
        },
        USER_EDIT_START: (state, action) => {
            return {
                ...state,
                isSuccessful: false,
            };
        },
        USER_EDIT: (state, action) => {
            return {
                ...state,
                isSuccessful: true,
            };
        },
        SHOW_LOADER: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        HIDE_LOADER: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    [],
);
