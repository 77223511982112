import React, {useContext} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import DateRangePicker from '../../common/DateRangePicker';
import {KeyboardDatePicker, useUtils} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as ReasonTypes from '../../../ReportReasonTypes';
import {DateTime} from "luxon";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {AuthContext} from "../../../use-auth";
import {getLatestGrazing} from "../../../store/ReportStore";

const useStyles = makeStyles(theme => ({
    datePicker: {
        minWidth: 210,
        width: '100%',
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const AdjustmentAddRow = ({motes, onAdd}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [mote, setMote] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [exclusionReason, setExclusionReason] = React.useState('');
    const [isPgrAutomatic, setIsPgrAutomatic] = React.useState(true);
    const [notes, setNotes] = React.useState('');
    const [pgr, setPgr] = React.useState(null);
    const [begin, setBegin] = React.useState('');
    const [end, setEnd] = React.useState('');
    const [latestGrazing, setLatestGrazing] = React.useState('');
    const [dateRange, setDateRange] = React.useState([]);
    const [selectedDate, setSelectedDateChange] = React.useState('');
    const utils = useUtils();

    const { currentUser } = useContext(AuthContext);

    const loadLatestGrazing = async (mote, reason, selectedDate) => {
        setLatestGrazing('');
        if (mote !== '' && mote.mote_id && reason.type === ReasonTypes.PREVIOUS_GRAZING && selectedDate) {
            const token = await currentUser.getIdToken(true);
            const lg = await getLatestGrazing(token, mote.mote_id, selectedDate);
            setLatestGrazing(lg);
        }
    };

    const handleMoteChange = async (newMote) => {
        loadLatestGrazing(newMote, reason, selectedDate);
        setMote(newMote);
    };

    const handleReasonChange = async (newReason) => {
        loadLatestGrazing(mote, newReason, selectedDate);
        setReason(newReason);
    };

    const handleSelectedDateChange = async (newSelectedDate) => {
        loadLatestGrazing(mote, reason, newSelectedDate);
        setSelectedDateChange(newSelectedDate);
    };

    const formatDate = date => utils.format(date, "dd/MM/yyyy" || utils.dateFormat);

    const handleAdd = () => {
        let reasonToAdd = reason;
        if (reason && reason.type === ReasonTypes.GRAZING && pgr) {
            reasonToAdd = {type: ReasonTypes.ENTERED_PGR, name: reason.name};
        }
        if (reasonToAdd && reasonToAdd.type === ReasonTypes.EXCLUSION) {
            reasonToAdd = {type: reasonToAdd.type, name: reasonToAdd.name + ' : ' + exclusionReason.name};
        }
        onAdd(mote, reasonToAdd, notes, isSetPgr ? formatDate(selectedDate) : begin, isSetPgr ? formatDate(selectedDate) : end, pgr);
        setMote('');
        setReason('');
        setExclusionReason('');
        setNotes('');
        setPgr(null);
        setDateRange([]);
        setBegin('');
        setEnd('');
        setSelectedDateChange(DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd')));
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const handlePgrChange = (event) => {
        setPgr(event.target.value);
    };

    const reasons = [
        {name: 'Report Issue', type: ReasonTypes.EXCLUSION}
        , {name: 'Set New PGR', type: ReasonTypes.GRAZING}
        , {name: 'Reset to Previous PGR', type: ReasonTypes.PREVIOUS_GRAZING}
    ];

    const exclusionReasons = [
        {name: 'Mote or Spring is damaged', type: ReasonTypes.EXCLUSION}
        , {name: 'Worn areas around Mote', type: ReasonTypes.EXCLUSION}
        , {name: 'Uneven Pasture', type: ReasonTypes.EXCLUSION}
        , {name: 'Other', type: ReasonTypes.EXCLUSION}
    ];

    const isSetPgr = reason && (reason.type === ReasonTypes.GRAZING || reason.type === ReasonTypes.PREVIOUS_GRAZING);

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <form className={classes.margin} noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Autocomplete
                        id="paddock-select"
                        options={motes}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={option => option.mote_name}
                        renderOption={option => (
                            <React.Fragment>
                                {option.mote_name}
                            </React.Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Mote"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={mote}
                        onChange={(event, newValue) => {
                            handleMoteChange(newValue);
                        }}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        id="reason-select"
                        options={reasons}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={option => option.name}
                        renderOption={option => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Adjustment"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={reason}
                        onChange={(event, newValue) => {
                            handleReasonChange(newValue);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {!isSetPgr && <DateRangePicker
                        value={dateRange}
                        placeholder="Select a date range"
                        onChange={values => {
                            setBegin(formatDate(values.begin));
                            setEnd(formatDate(values.end));
                        }}
                        autoOk={true}
                        format="dd/MM/yyyy"
                        className={classes.datePicker}
                    />}
                    {isSetPgr && <KeyboardDatePicker
                        autoOk
                        variant={isDesktop ? "inline" : "dialog"}
                        inputVariant="outlined"
                        label="Select a date"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        disableFuture
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => handleSelectedDateChange(date)}
                        className={classes.datePicker}
                    />}
                </Grid>
                {isSetPgr && reason.type === ReasonTypes.GRAZING && <>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox name="isPgrAuto" checked={isPgrAutomatic} onChange={() => setIsPgrAutomatic(!isPgrAutomatic)}/>}
                            label="Automatic"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {!isPgrAutomatic && <TextField label="PGR" variant="outlined" fullWidth onChange={handlePgrChange}/>}
                    </Grid>
                </> }
                {reason && reason.type === ReasonTypes.PREVIOUS_GRAZING && latestGrazing && latestGrazing !== '' &&
                    <Grid item xs={6}>
                        <TextField label="Previous PGR Date" variant="outlined" fullWidth disabled value={DateTime.fromISO(latestGrazing).toLocaleString(DateTime.DATE_SHORT)}/>
                    </Grid>
                }
                {reason && reason.type === ReasonTypes.EXCLUSION &&
                <Grid item xs={6}>
                    <Autocomplete
                        id="exclusion-reason-select"
                        options={exclusionReasons}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={option => option.name}
                        renderOption={option => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Reason"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={exclusionReason}
                        onChange={(event, newValue) => {
                            setExclusionReason(newValue);
                        }}
                    />
                </Grid>
                }
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <TextField label="Notes" variant="outlined" multiline rows={2} fullWidth onChange={handleNotesChange}/>
                </Grid>
                <Grid item xs={2}>
                    <Fab color="primary" size="small" aria-label="add" onClick={handleAdd} disabled={!(mote && ( (begin && end) || selectedDate))} className={classes.margin}>
                        <AddIcon fontSize="small" elevation={0}/>
                    </Fab>
                </Grid>
            </Grid>
        </form>
    );
};

export default AdjustmentAddRow;
