import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FilterIcon from '@material-ui/icons/FilterList';
import LinearProgress from '@material-ui/core/LinearProgress';
import DownloadIcon from '@material-ui/icons/GetApp';
import TitleBar from '../components/common/TitleBar';
import EventsFilters from '../components/events/EventsFilters';
import EventsTable from '../components/events/EventsTable';
import FilterDrawer from '../components/nav/FilterDrawer';
import { getEvents, downloadEvents } from '../store/EventsStore';
import Toaster from '../components/common/Toaster';
import {AuthContext} from "../use-auth";

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    fullWidth: {
        width: '100%',
    },
    widthWithoutDrawer: {
        width: 'calc(100% - 300px)',
    },
    button: {
        fontSize: '0.7rem',
        fontWeight: 600,
        letterSpacing: 0,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function Events({getEvents, events, isLoading, isSuccessful, downloadEvents, isDownloading}) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { currentUser } = useContext(AuthContext);

    const [filters, setFilters] = React.useState({});
    const [showFilters, setShowFilters] = React.useState(true);
    const [showMobileFilters, setShowMobileFilters] = React.useState(false);

    async function handleFilterSearchClick(filters) {
        setShowMobileFilters(false);
        setFilters(filters);
        const token = await currentUser.getIdToken(true);
        getEvents(token, filters.moteId, filters.dateFrom, filters.dateTo, filters.timeZone);
    }

    function handleShowFiltersClick() {
        setShowFilters(!showFilters);
    }

    function handleShowMobileFiltersClick() {
        setShowMobileFilters(!showMobileFilters);
    }

    const handleDownloadClick = async () => {
        const token = await currentUser.getIdToken(true);
        downloadEvents(token, filters.moteId, filters.moteName, filters.dateFrom, filters.dateTo, filters.timeZone);
    };

    return (
        <div>
            <TitleBar title="Events">
                {events && events.length > 0 && <DownloadIcon style={{ cursor: 'pointer' }} onClick={handleDownloadClick} color={isDownloading ? 'disabled' : ''}/>}
                <Button color="secondary" className={classes.button} onClick={isDesktop ? handleShowFiltersClick : handleShowMobileFiltersClick}>
                    <FilterIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                    {isDesktop && <div>{!showFilters ? 'Show Filters' : 'Hide Filters'}</div>}
                </Button>
            </TitleBar>
            <div className={isDesktop ? classes.root : ''}>
                <div className={clsx({
                        [classes.fullWidth]: isDesktop && !showFilters,
                        [classes.widthWithoutDrawer]: isDesktop && showFilters
                    },
                )}>
                    {isLoading && <LinearProgress />}
                    {isSuccessful && events &&
                        <Toaster variant="success" message="Retrieved Events!"/>
                    }
                    <EventsTable events={events} timeZone={filters.timeZone} />
                </div>
                <div>
                    <FilterDrawer
                        drawerOpen={showFilters}
                        mobileDrawerOpen={showMobileFilters}
                        toggleMobileDrawerOpen={handleShowMobileFiltersClick}
                    >
                        <EventsFilters onSearchClick={handleFilterSearchClick} />
                    </FilterDrawer>
                </div>
            </div>
        </div>
    );
}

export default connect (state => ({
    events: state.eventsStore.events,
    isLoading: state.dashboardStore.isLoading,
    isSuccessful: state.dashboardStore.isSuccessful,
    isDownloading: state.dashboardStore.isDownloading,
    isDownloadSuccessful: state.dashboardStore.isDownloadSuccessful,
}), {
    getEvents,
    downloadEvents,
}) (Events);
