import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Red from '@material-ui/core/colors/red';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    excludedRow: {
        backgroundColor: Red[50],
    }
});

export default function RegressionInputTable({rows}) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Regression Input table">
                <TableHead>
                    <TableRow>
                        <TableCell>Farm</TableCell>
                        <TableCell>Mote</TableCell>
                        <TableCell>Scene ID</TableCell>
                        <TableCell>Processed Date</TableCell>
                        <TableCell>Mean Mote NDVI</TableCell>
                        <TableCell>Mean Mote NDRE</TableCell>
                        <TableCell>HAPGR</TableCell>
                        <TableCell>Exclusion Reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.mote_name} className={row.excluded && classes.excludedRow}>
                            <TableCell component="th" scope="row">
                                {row.farm_name}
                            </TableCell>
                            <TableCell>{row.mote_name}</TableCell>
                            <TableCell>{row.scene_id}</TableCell>
                            <TableCell>{row.datetime_processed}</TableCell>
                            <TableCell>{row.mean_mote_ndvi}</TableCell>
                            <TableCell>{row.mean_mote_ndre}</TableCell>
                            <TableCell>{row.hapgr}</TableCell>
                            <TableCell>{row.exclusion_reason}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
